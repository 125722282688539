import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import CustomTextPopup from "../../content/popups/CustomTextPopup";
import { useFormik } from "formik";
import * as yup from "yup";
import StatusTitlePopup from "../../content/popups/multi-tenancy/StatusTitlePopup";
import { KTSVG } from "../../../helpers";
import "../styles/AddTenantUsersModal.scss";
import PasswordLabelPopup from "../../content/popups/multi-tenancy/PasswordLabelPopup";
import EditIcon from "../../../assets/icons/multi-tenancy/edit-tenant.png";
import TrashIcon from "../../../assets/icons/multi-tenancy/trash-icon.png";
import { getRoleList } from "../../../../app/services/RoleService";
import { RoleModelDTO } from "../../../../app/services/models/RoleModel";
import {
  createTenantUser,
  deleteTenantUser,
  getTenantActivities,
  getTenantUserList,
} from "../../../../app/services/TenantService";
import {
  CreateUserDTO,
  DeleteTenantDTO,
  GetTenantActivityDTO,
  GetTenantUserDTO,
  GetTenantUsersDTO,
  TenantActivityDTO,
  TenantDTO,
  TenantUserDTO,
} from "../../../../app/services/models/TenantModel";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

interface Props {
  tenant: TenantDTO;
  isOpen: boolean;
  toggleModal: () => void;
}

const getCharacterValidationError = (str: string) => {
  return `Your password must have at least 1 ${str} character`;
};

const tenantUserSchema = yup.object().shape({
  name: yup.string().required().label("User name"),
  email: yup.string().email().required().label("User email"),
  userRole: yup.string().required().label("User role"),
  password: yup
    .string()
    .required("Please enter a password")
    .min(8, "Password must have at least 8 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    .matches(
      /^(?=.*[!@#\$%\^&\*])/,
      getCharacterValidationError("special case character")
    ),
});

const AddTenantUsersModal = ({ tenant, isOpen, toggleModal }: Props) => {
  const [tenantState] = useState<TenantDTO>(tenant);
  const refreshBtnRef = useRef<HTMLButtonElement>(null);
  const [userRoles, setUserRoles] = useState<RoleModelDTO[]>();
  const [usersList, setUsersList] = useState<GetTenantUsersDTO>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTenantUser, setSelectedTenantUser] = useState<TenantUserDTO>();
  const [deleteAlertVisibility, setDeleteAlertVisibility] =
    useState<boolean>(false);
  const [deletingTenant, setDeletingTenant] = useState<DeleteTenantDTO>();
  const [tenantActivity, setTenantActivity] = useState<TenantActivityDTO>();

  const [lazyParams, setLazyParams] = useState({
    page: 1,
    size: 5,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      userRole: "",
      password: "",
    },
    validationSchema: tenantUserSchema,
    onSubmit: (values) => {
      addUser(values);
    },
  });

  const notify = (msg: string, status: boolean) => {
    if (status) {
      toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    getRoles();
    getUsers();
    getTenantActivity(tenant.id);
  }, [tenant]);

  const addUser = async (e: any) => {
    const user: CreateUserDTO = {
      tenantId: tenantState.id,
      roleId: e.userRole,
      userName: e.name,
      email: e.email,
      password: e.password,
    };
    console.log(user);
    await createTenantUser(user)
      .then((result) => {
        if (result.success) {
          notify("User created successfully", true);
        }
        if (!result.success) {
          notify("User creation failed", false);
        }
        resetForm();
        getUsers();
      })
      .catch((err) => {
        console.log(err);
        notify(JSON.stringify(err.message), true);
      });
  };

  const getTenantActivity = async (tenant: string) => {
    const tenantActivity: GetTenantActivityDTO = {
      tenantId: tenant,
      last: true,
    };
    await getTenantActivities(tenantActivity)
      .then((result) => {
        if (result.length !== 0) {
          setTenantActivity(result[0]);
        } else {
          setTenantActivity({
            id: "",
            tenantId: "",
            date: "",
            type: "",
            typeId: 0,
            message: "",
            status: "",
            statusId: 0,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteUser = async (tenant: DeleteTenantDTO) => {
    await deleteTenantUser(tenant)
      .then((result) => {
        if (result.success) {
          notify("User deleted successfully", false);
        }
        if (!result.success) {
          notify("Failed to delete user", false);
        }
        getUsers();
      })
      .catch((err) => {
        console.log(err);
      });
    setDeleteAlertVisibility(false);
  };

  const hideDialog = () => {
    setDeleteAlertVisibility(false);
  };

  const getRoles = async () => {
    await getRoleList()
      .then((result: any) => {
        setUserRoles(result);
      })
      .catch((err: any) => console.log(err));
  };

  const getUsers = async () => {
    setLoading(true);
    const tenantData: GetTenantUserDTO = {
      page: 1,
      size: 5,
      tenantId: tenant.id,
    };
    await getTenantUserList(tenantData)
      .then((result) => {
        setUsersList(result);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const handleFetchLastStatus = () => {
    refreshBtnRef.current?.classList.add("rotate-clockwise");
    setTimeout(() => {
      refreshBtnRef.current?.classList.remove("rotate-clockwise");
      getTenantActivity(tenant.id);
    }, 3000);
  };

  const tenantUserSelection = (e: any) => {
    setSelectedTenantUser(e);
    setDeleteAlertVisibility(true);
    var deletedTenant: DeleteTenantDTO = {
      tenantId: tenant.id,
      userId: e.subject,
    };
    setDeletingTenant(deletedTenant);
  };

  const removeTenantUser = (e: any) => {
    deleteUser(deletingTenant!);
  };

  const setTenantUserActionButtons = (rowData: any) => {
    return (
      <td className="text-end">
        <CustomTextPopup
          text="Edit User"
          id="tenantAddUserButtonPopover"
          placement="left"
        >
          <button
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
            onClick={() =>
              window.open(
                "https://idserver-vipsys-demo.vendolink.se/",
                "_blank"
              )
            }
          >
            <img src={EditIcon} alt="add users" className="w18" />
          </button>
        </CustomTextPopup>
        <CustomTextPopup
          text="Remove User"
          id="tenantAddUserButtonPopover"
          placement="bottom"
        >
          <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <img
              src={TrashIcon}
              alt="EditTenant"
              className="w18"
              onClick={() => tenantUserSelection(rowData)}
            />
          </button>
        </CustomTextPopup>
      </td>
    );
  };

  const addedUsersSection = (
    <div className="card mb-5 mb-xl-8 companyTable">
      <div className="card-body py-3">
        <div className="table-responsive">
          <DataTable
            value={usersList?.records}
            paginator
            lazy
            totalRecords={usersList?.totalCount}
            onPage={onPage}
            first={lazyParams.page - 1}
            rows={lazyParams.size}
            rowsPerPageOptions={[5, 10, 15]}
            dataKey="id"
            rowHover
            loading={loading}
            responsiveLayout="scroll"
            emptyMessage="No companies found."
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          >
            {/* User Subject */}
            <Column
              field="subject"
              header="Subject"
              className="text-dark min-w-120px"
            />

            {/* User Email */}
            <Column
              field="email"
              header="Email"
              className="text-dark min-w-120px"
            />

            {/* action btn */}
            <Column
              header="Actions"
              headerStyle={{ width: "120px", textAlign: "center" }}
              className="text-dark min-w-120px"
              body={setTenantUserActionButtons}
            />
          </DataTable>
          <Dialog
            visible={deleteAlertVisibility}
            style={{ width: "750px" }}
            modal
            className="p-fluid"
            onHide={hideDialog}
          >
            {selectedTenantUser && selectedTenantUser === null ? (
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="4"
                animationDuration=".5s"
              />
            ) : (
              <div className="alert alert-dismissible d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">
                <span className="svg-icon svg-icon-5tx svg-icon-danger mb-5">
                  <KTSVG
                    path="/media/icons/duotune/general/gen044.svg"
                    className="svg-icon-1"
                  />
                </span>

                <div className="text-center">
                  <h5 className="fw-bolder fs-1 mb-5">
                    Do you want to remove the Teant User?
                  </h5>

                  <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                  <div className="mb-9">
                    Removing the user will result in revoke access to services.
                  </div>
                  <div className="d-flex flex-center flex-wrap">
                    <a
                      href="#"
                      className="btn btn-outline btn-outline-danger btn-active-danger m-2"
                      onClick={hideDialog}
                    >
                      Cancel
                    </a>
                    <a
                      href="#"
                      className="btn btn-danger m-2"
                      onClick={() => removeTenantUser(selectedTenantUser)}
                    >
                      Delete
                    </a>
                  </div>
                </div>
              </div>
            )}
          </Dialog>
        </div>
      </div>
    </div>
  );

  const findTenantStatus = (tenantStatus: string) => {
    if (tenantStatus === "1") {
      return (
        <span
          className="badge badge-light-info fs-7 fw-bold d-flex pending"
          style={{ color: "#00A3FF" }}
        >
          <KTSVG
            path="/media/icons/duotune/general/pending-circle.svg"
            className="svg-icon-3 badge-light-info me-1 d-flex"
          />
          Pending
        </span>
      );
    }
    if (tenantStatus === "2") {
      return (
        <span className="badge badge-light-success fs-7 fw-bold d-flex success">
          <KTSVG
            path="/media/icons/duotune/general/success-circle.svg"
            className="svg-icon-3 badge-light-success me-1 d-flex"
          />
          Success
        </span>
      );
    }
    if (tenantStatus === "3") {
      return (
        <span className="badge badge-light-danger fs-7 fw-bold d-flex failed">
          <KTSVG
            path="/media/icons/duotune/general/failed-circle.svg"
            className="svg-icon-3 badge-light-danger me-1 d-flex"
          />
          Failed
        </span>
      );
    } else {
      return (
        <span
          className="badge badge-light-primary fs-7 fw-bold"
          style={{ WebkitTextFillColor: "#d2d0db" }}
        >
          N/A
        </span>
      );
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={toggleModal}
      dialogClassName="tenant-add-users-modal"
      size="lg"
    >
      <Modal.Header closeButton className="py-3">
        <h2>Create Users</h2>
        <div className="d-flex status-block">
          <label>
            <StatusTitlePopup>
              <div>Last Status:</div>
            </StatusTitlePopup>
            {tenantActivity &&
              findTenantStatus(tenantActivity.statusId.toString())}
            <CustomTextPopup
              text="Press to refresh the database status"
              id="refreshStatusPopover"
              placement="bottom"
            >
              <button
                ref={refreshBtnRef}
                onClick={handleFetchLastStatus}
                className="btn refresh-btn ms-5"
              >
                <KTSVG
                  path="/media/icons/duotune/general/reload.svg"
                  className="svg-icon-3 svg-reload"
                />
              </button>
            </CustomTextPopup>
          </label>
        </div>
      </Modal.Header>
      <Modal.Body>
        {tenant ? (
          <div>
            <form autoComplete="off" id="addTeantUser">
              <div className="card-body d-flex justify-content-between">
                <div className="flex-side">
                  <div className="form-group mb-7">
                    <CustomTextPopup
                      text="This is the username that is used to verify your user identity via basic authentication."
                      id="tenantNameLabelPopover"
                      placement="right"
                    >
                      <label>User Name:*</label>
                    </CustomTextPopup>
                    <input
                      id="name"
                      type="text"
                      onChange={handleChange}
                      value={values.name}
                      onBlur={handleBlur}
                      className="form-control form-control-solid mt-1"
                      placeholder="Enter user name"
                      autoComplete="new-name"
                    />
                    {errors.name && touched.name && (
                      <p className="text-danger">{errors.name}</p>
                    )}
                  </div>
                  <div className="form-group mb-7">
                    <CustomTextPopup
                      text="This is the email address that is associated with the user."
                      id="tenantCodeLabelPopover"
                      placement="right"
                    >
                      <label>Email:*</label>
                    </CustomTextPopup>
                    <input
                      id="email"
                      type="text"
                      onChange={handleChange}
                      value={values.email}
                      className="form-control form-control-solid mt-1"
                      placeholder="Enter email address"
                      onBlur={handleBlur}
                      autoComplete="new-email"
                    />
                    {errors.email && touched.email && (
                      <p className="text-danger">{errors.email}</p>
                    )}
                  </div>
                </div>
                <div className="flex-side">
                  <div className="form-group mb-7">
                    <CustomTextPopup
                      text="This is the type of the user"
                      id="tenantNameLabelPopover"
                      placement="right"
                    >
                      <label>Role:*</label>
                    </CustomTextPopup>
                    <select
                      name="userRole"
                      id="userRole"
                      className="form-select form-select-solid"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.userRole}
                    >
                      <option value="" disabled selected>
                        Select Role
                      </option>
                      {userRoles?.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))}
                    </select>
                    {errors.userRole && touched.userRole && (
                      <p className="text-danger">{errors.userRole}</p>
                    )}
                  </div>
                  <div className="form-group mb-7">
                    <PasswordLabelPopup>
                      <label>Password:*</label>
                    </PasswordLabelPopup>
                    <input
                      id="password"
                      type="password"
                      onChange={handleChange}
                      value={values.password}
                      onBlur={handleBlur}
                      className="form-control form-control-solid mt-1"
                      placeholder="Enter user name"
                      autoComplete="new-password"
                    />
                    {errors.password && touched.password && (
                      <p className="text-danger">{errors.password}</p>
                    )}
                  </div>
                </div>
              </div>
            </form>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary me-3 py-3"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Add
              </button>
            </div>
          </div>
        ) : (
          <div className="justify-content-cente">
            <div className="spinner-overlay">
              <div className="spinner-border m-5 text-secondary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        )}
        {tenant && usersList?.records.length! > 0 ? addedUsersSection : <></>}
      </Modal.Body>
    </Modal>
  );
};

export default AddTenantUsersModal;
