/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import "../styles/MainPlatformTask.scss";
import { KTSVG } from "../../../helpers";
import { Formik, Form, FormikValues, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { StepperComponent } from "../../../assets/ts/components";
import { getAllSources } from "../../../../app/services/SourceService";
import { getAllDestionations } from "../../../../app/services/DestinationService";
import { getAllTasks } from "../../../../app/services/TaskService";
import { getAllEvents } from "../../../../app/services/EventService";
import { getAllIntegrationModules } from "../../../../app/services/IntegrationModuleService";
import Soruce from "../../../../app/pages/commonTypes/SourceType";
import IntegrationModule from "../../../../app/pages/commonTypes/IntegrationModuleType";
import Destination from "../../../../app/pages/commonTypes/DestinationType";
import Event from "../../../../app/pages/commonTypes/EventType";
import Task from "../../../../app/pages/commonTypes/TaskType";
import {
  getAllOrganizations,
  getOrganizationById,
  getOrganizationStores,
} from "../../../../app/services/CompanyService";
import { CompanyType, CompanyStore } from "../../../../app/pages/company/CompanyType";
import DatePickerComponent from "../date-picker/DatePickerComponent";
import { CreatePlatformTaskDTO } from "../../../../app/services/models/PlatformTaskModel";
import { PlatformTaskStoreTable } from "../../widgets";
import { createPlatformTask } from "../../../../app/services/PlatformTaskService";
import { SelectedItemContext } from "../../../layout/MasterLayout";
import TimePicker from "../time-picker/TimePicker";

interface ICreatePlatformTask {
  organizationId: string;
  defaultStartDate: any;
  taskStatus: boolean;
  isAllStore: boolean;
  integrationModuleId: string;
  sourceId: string;
  destinationId: string;
  eventId: string;
  taskTypeId: string;
  scheduleStatus: boolean;
  schedulePreDefined: string;
  scheduleTime: string;
  scheduleCron: string;
  stores: Store[];
  storeCode: string;
  storeStatus: boolean;
}

interface Store {
  storeCode: string;
  storeName: string;
  active: boolean;
}

const inits: ICreatePlatformTask = {
  organizationId: "",
  defaultStartDate: "",
  taskStatus: true,
  isAllStore: false,
  integrationModuleId: "",
  sourceId: "",
  destinationId: "",
  eventId: "",
  taskTypeId: "",
  scheduleStatus: false,
  schedulePreDefined: "",
  scheduleTime: "",
  scheduleCron: "",
  stores: [],
  storeCode: "",
  storeStatus: false,
};

const createAppSchema = [
  Yup.object({
    organizationId: Yup.string().required().label("Organization Name"),
    taskStatus: Yup.boolean().required(),
    isAllStore: Yup.boolean().required(),
    defaultStartDate: Yup.date().required().label("Default Start Date"),
  }),
  Yup.object({
    integrationModuleId: Yup.string().required().label("Integration module"),
  }),
  Yup.object({
    sourceId: Yup.string().required().label("Source"),
    destinationId: Yup.string().required().label("Destination"),
  }),
  Yup.object({
    taskTypeId: Yup.string().required().label("Task"),
    eventId: Yup.string().required().label("Event"),
  }),
  Yup.object({
    scheduleStatus: Yup.boolean().required(),
    schedulePreDefined: Yup.string().when("scheduleStatus", {
      is: (val: boolean) => val === true,
      then: Yup.string().required().label("Schedule"),
    }),
    scheduleCron: Yup.string().when("scheduleStatus", {
      is: (val: boolean) => val === true,
      then: Yup.string().required().label("Schedule Cron"),
    }),
  }),
  Yup.object({
    storeCode: Yup.string().test(
      "Store test",
      "Store code is a rquired field",
      function () {
        if (
          this.parent.isAllStore === false &&
          this.parent.stores.length <= 0
        ) {
          return false;
        } else return true;
      }
    ),
    stores: Yup.array().when("isAllStore", {
      is: (val: boolean) => val === false,
      then: Yup.array().min(1).required().label("Stores"),
    }),
  }),
];

const MainPlatformTask: FC = () => {
  const selectedItemContext = useContext(SelectedItemContext);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(createAppSchema[0]);
  const [initValues, setInitValues] = useState<ICreatePlatformTask>(inits);

  const [organizations, setOrganizations] = useState<CompanyType[]>([]);
  const [integrationModules, setIntegrationModules] = useState<
    IntegrationModule[]
  >([]);
  const [stores, setCompanyStores] = useState<CompanyStore[]>([]);
  const [sources, setSources] = useState<Soruce[]>([]);
  const [destinations, setDestinations] = useState<Destination[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [defaultStartDate, setdefaultStartDate] = useState<any>();
  const [platformTaskStores, setPlatformTaskStores] = useState<Store[]>([]);
  const [submitError, setSubmitError] = useState<boolean | null>(null);
  const [submitSuccess, setsubmitSuccess] = useState<boolean | null>(null);
  const [showScheduleStep, setShowScheduleStep] = useState<boolean>(true);
  const [scheduleTime, setScheduleTime] = useState<any>("");
  const [enableScheduleCron, setEnableScheduleCron] = useState<boolean>(false);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = (value: any) => {
    if (!stepper.current) {
      return;
    }
    if (stepper.current.currentStepIndex === 6 && !showScheduleStep) {
      stepper.current.goto(4);
    } else {
      stepper.current.goPrev();
    }
    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex - 1]);
  };

  const submitStep = (values: ICreatePlatformTask, actions: FormikValues) => {
    if (stepper.current?.currentStepIndex === 1) {
      getIntegrationModulesByCompany(values.organizationId);
      getStoresByCompany(values.organizationId);
    }
    if (!stepper.current) {
      return;
    }

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex]);

    if (stepper.current.currentStepIndex === 4 && values.taskTypeId === "2") {
      setShowScheduleStep(true);
      stepper.current.goNext();
    } else if (
      stepper.current.currentStepIndex === 4 &&
      values.taskTypeId === "1"
    ) {
      setShowScheduleStep(false);
      stepper.current.goto(6);
    } else if (
      stepper.current.currentStepIndex !== stepper.current.totatStepsNumber
    ) {
      stepper.current.goNext();
    } else {
      stepper.current.goto(1);
      actions.resetForm({
        values: {
          organizationId: "",
          taskStatus: true,
          defaultStartDate: "",
          integrationModuleId: "",
          sourceId: "",
          destinationId: "",
          taskTypeId: "",
          scheduleStatus: false,
          schedulePreDefined: "",
          scheduleTime: "",
          scheduleCron: "",
          eventId: "",
          schedule: "",
          stores: [],
          storeCode: "",
          storeStatus: false,
        },
      });
      setPlatformTaskStores([]);
      onSubmit(values);
    }
  };

  const onSubmit = async (values: ICreatePlatformTask) => {
    console.log(values);
    // let cron = "";
    // if (values.scheduleStatus || values.scheduleCron === "") {
    //   cron = setScheduleCron(values.schedulePreDefined, values.scheduleTime);
    //   console.log(cron);
    // } else {
    //   cron = values.scheduleCron;
    // }
    const PlatfromTask: CreatePlatformTaskDTO = {
      companyId: parseInt(values.organizationId),
      integrationModuleId: parseInt(values.integrationModuleId),
      sourceId: parseInt(values.sourceId),
      destinationId: parseInt(values.destinationId),
      taskTypeId: parseInt(values.taskTypeId),
      eventId: parseInt(values.eventId),
      scehdule: values.scheduleCron === "" ? String(null) : values.scheduleCron,
      scheduleStatusId: setScheduleStatusId(values)!,
      platformTaskStores: platformTaskStores,
      active: values.taskStatus,
      defaultStartDate: defaultStartDate,
      isAllStore: values.isAllStore,
    };
    console.log(PlatfromTask);
    addPlatformTask(PlatfromTask);
  };

  const setScheduleStatusId = (values: any) => {
    if (values.taskTypeId === "1") {
      return 3;
    } else if (values.taskTypeId === "2") {
      if (values.scheduleStatus) {
        return 1;
      } else if (!values.scheduleStatus) {
        return 2;
      }
    }
  };

  const getAllOraganizationList = async () => {
    await getAllOrganizations()
      .then((result) => {
        setOrganizations(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const getAllSourceList = async () => {
    await getAllSources()
      .then((result) => {
        setSources(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const getAllDestinationList = async () => {
    await getAllDestionations()
      .then((result) => {
        setDestinations(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const setScheduleCron = (definedCron: string, time: any): string => {
    let cronString = "";
    if (time === "" || time === null) {
      switch (definedCron) {
        case "1":
          cronString = "0 * * * *";
          setEnableScheduleCron(false);
          break;
        case "2":
          cronString = "0 0 * * *";
          setEnableScheduleCron(false);
          break;
        case "4":
          cronString = "0 0 * * 5";
          setEnableScheduleCron(false);
          break;
        case "6":
          cronString = "0 0 * * 0";
          setEnableScheduleCron(false);
          break;
        case "8":
          cronString = "";
          setEnableScheduleCron(true);
          break;
      }
    } else if (time !== null) {
      switch (definedCron) {
        case "3":
          cronString = time.getMinutes() + " " + time.getHours() + " * * *";
          setEnableScheduleCron(true);
          break;
        case "5":
          cronString = time.getMinutes() + " " + time.getHours() + " * * 5";
          setEnableScheduleCron(true);
          break;
        case "7":
          cronString = time.getMinutes() + " " + time.getHours() + " * * 0";
          setEnableScheduleCron(true);
          break;
      }
    }
    console.log(cronString);
    return cronString;
  };

  const scheduleTypes = [
    {
      id: 1,
      name: "Hourly",
    },
    {
      id: 2,
      name: "Daily",
    },
    // {
    //   id: 3,
    //   name: "Daily at",
    // },
    {
      id: 4,
      name: "Weekly (Week days)",
    },
    // {
    //   id: 5,
    //   name: "Weekly (Week days) at",
    // },
    {
      id: 6,
      name: "Week ends",
    },
    // {
    //   id: 7,
    //   name: "Week ends at",
    // },
    {
      id: 8,
      name: "Custom",
    },
  ];

  const getAllTaskList = async () => {
    await getAllTasks()
      .then((result) => {
        setTasks(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const getAllEventList = async () => {
    await getAllEvents()
      .then((result) => {
        setEvents(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const getIntegrationModulesByCompany = async (id: string) => {
    await getOrganizationById(id)
      .then((result) => {
        setIntegrationModules(
          integrationModules.filter((item) =>
            result.integrationModules.some(
              (b: any) => item.id === b.integrationModuleId
            )
          )
        );
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const getStoresByCompany = async (id: string) => {
    await getOrganizationStores(id)
      .then((result) => {
        setCompanyStores(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const getAllIntegrationModuleList = async () => {
    await getAllIntegrationModules()
      .then((result) => {
        setIntegrationModules(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const addStoreClickHandler = (resetForm: any, values: any) => {
    const store = stores.find((item) => item.id == values.storeCode);
    var newStore: Store = {
      storeCode: values.storeCode,
      storeName: store?.name ?? "",
      active: values.storeStatus,
    };
    setPlatformTaskStores((storeArr) => [...storeArr, newStore]);
    values.stores = platformTaskStores;
    console.log(values);
    resetForm({ values: { ...values, storeCode: "", storeStatus: false } });
    console.log(platformTaskStores);
    console.log(values);
  };

  const removeStoreClickHandler = (storeCode: string) => {
    setPlatformTaskStores(
      platformTaskStores.filter((item) => item.storeCode !== storeCode)
    );
  };

  const addPlatformTask = async (platformTask: CreatePlatformTaskDTO) => {
    const res = await createPlatformTask(platformTask);
    if (typeof res !== "number") {
      setSubmitError(true);
      setTimeout(() => {
        setSubmitError(false);
      }, 5000);
    } else {
      setsubmitSuccess(true);
      setTimeout(() => {
        setsubmitSuccess(false);
      }, 5000);
    }
  };

  const errorMessage = () => {
    return <div>Error adding the Platform Task!</div>;
  };

  const successMessage = () => {
    return <div>Successfully added the Platform Task!</div>;
  };

  const closeModal = () => {
    selectedItemContext.setSelectedItem?.("0");
  };

  const setDate = (values: ICreatePlatformTask) => {
    values.defaultStartDate = defaultStartDate;
  };

  useEffect(() => {}, [scheduleTime]);

  const setTime = (values: any) => {
    values.scheduleTime = scheduleTime;
  };

  const setCronExpression = (values: any, resetForm?: any) => {
    console.log(values);
    if (values.scheduleStatus) {
      let cron = setScheduleCron(
        values.schedulePreDefined,
        values.scheduleTime
      );
      console.log(cron);
      resetForm({
        values: {
          ...values,
          scheduleCron: cron,
        },
      });
    }
  };

  useEffect(() => {
    getAllOraganizationList();
    getAllIntegrationModuleList();
    getAllSourceList();
    getAllDestinationList();
    getAllTaskList();
    getAllEventList();
  }, [organizations.length, selectedItemContext.selectedItem]);

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }
    loadStepper();
  }, [stepperRef]);

  useEffect(() => {}, [platformTaskStores]);

  const setStores = (values: ICreatePlatformTask) => {
    values.stores = platformTaskStores;
  };

  const test = (e: any) => {
    console.log(e);
  };

  return (
    <div
      className="modal fade createPlatformTaskModal"
      id="kt_modal_create_platformTask"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered mw-900px">
        <div className="modal-content">
          <div className="modal-header">
            <span>
              <h2>Create Platform Task</h2>
              <div
                className="fw-bold fs-3 text-danger"
                hidden={!submitError ? true : false}
              >
                {submitError && errorMessage()}
              </div>
              <div
                className="fw-bold fs-3 text-success"
                hidden={!submitSuccess ? true : false}
              >
                {submitSuccess && successMessage()}
              </div>
            </span>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              onClick={() => closeModal()}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </div>
          </div>
          <div className="modal-body py-lg-10 px-lg-10">
            <div
              ref={stepperRef}
              className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
              id="kt_modal_create_platformTask_stepper"
            >
              <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
                <div className="stepper-nav ps-lg-10">
                  <div
                    className="stepper-item current"
                    data-kt-stepper-element="nav"
                  >
                    <div className="stepper-line w-40px"></div>

                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">1</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Details</h3>

                      <div className="stepper-desc">Enter Task Details</div>
                    </div>
                  </div>

                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <div className="stepper-line w-40px"></div>

                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">2</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Integration Module</h3>

                      <div className="stepper-desc">
                        Select Integration Module
                      </div>
                    </div>
                  </div>

                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <div className="stepper-line w-40px"></div>

                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">3</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Source and Destination</h3>

                      <div className="stepper-desc">
                        Select Source and Destination
                      </div>
                    </div>
                  </div>

                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <div className="stepper-line w-40px"></div>

                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">4</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Task and Event</h3>

                      <div className="stepper-desc">Select Task and Event</div>
                    </div>
                  </div>

                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <div className="stepper-line w-40px"></div>

                    <div className="stepper-icon w-40px h-40px">
                      {showScheduleStep ? (
                        <i className="stepper-check fas fa-check"></i>
                      ) : (
                        <i className="stepper-check fas fa-check disabled-grey"></i>
                      )}
                      {showScheduleStep ? (
                        <span className="stepper-number">5</span>
                      ) : (
                        <span className="stepper-number disabled-grey">5</span>
                      )}
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Schedule</h3>

                      <div className="stepper-desc">
                        Enter Platfrom Task Schedule
                      </div>
                    </div>
                  </div>

                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <div className="stepper-line w-40px"></div>

                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">6</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Store</h3>

                      <div className="stepper-desc">Store Configurations</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-row-fluid py-lg-5 px-lg-15">
                <Formik
                  validationSchema={currentSchema}
                  initialValues={initValues}
                  onSubmit={submitStep}
                >
                  {({ values, resetForm }) => (
                    <Form
                      className="form"
                      noValidate
                      id="kt_modal_create_platformTask_form"
                    >
                      <div
                        className="current"
                        data-kt-stepper-element="content"
                      >
                        <div className="w-100">
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">
                                Organization Name
                              </span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Select organization"
                              ></i>
                            </label>

                            <Field
                              as="select"
                              name="organizationId"
                              className="form-select form-select-solid"
                            >
                              <option value="" disabled selected>
                                Select Organization
                              </option>
                              {organizations.map((org) => (
                                <option key={org.id} value={org.id}>
                                  {org.name}
                                </option>
                              ))}
                            </Field>
                            <div className="text-danger">
                              <ErrorMessage name="organizationId" />
                            </div>
                          </div>
                          <div className="fv-row mb-10">
                            <label className="required fs-6 fw-bold form-label mb-2">
                              Default Start Date
                            </label>
                            <Field
                              component={DatePickerComponent}
                              name="defaultStartDate"
                              startDate={setdefaultStartDate}
                              values={setDate(values)}
                              create={true}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="defaultStartDate" />
                            </div>
                          </div>
                          <div className="fv-row mb-10">
                            <div className="d-flex flex-stack">
                              <div className="me-5">
                                <label className="fs-6 fw-bold form-label">
                                  Status
                                </label>
                              </div>
                              <label className="form-check form-switch form-check-custom form-check-solid">
                                <Field
                                  name="taskStatus"
                                  className="form-check-input"
                                  type="checkbox"
                                />
                                <span className="form-check-label fw-bold text-gray-400" />
                              </label>
                            </div>
                          </div>
                          <div className="d-flex flex-stack">
                            <div className="me-5">
                              <label className="fs-6 fw-bold form-label">
                                Is All Store
                              </label>
                            </div>
                            <label className="form-check form-switch form-check-custom form-check-solid">
                              <Field
                                name="isAllStore"
                                className="form-check-input"
                                type="checkbox"
                              />
                              <span className="form-check-label fw-bold text-gray-400" />
                            </label>
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element="content">
                        <div className="w-100">
                          <div className="fv-row">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-4">
                              <span className="required">
                                Select Integration Module
                              </span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Select Integration Module"
                              ></i>
                            </label>
                            <div className="d-flex flex-column mb-7 fv-row">
                              <Field
                                as="select"
                                name="integrationModuleId"
                                className="form-select form-select-solid"
                              >
                                <option value="" disabled selected>
                                  Select Integration Module
                                </option>
                                {integrationModules.map((module) => (
                                  <option key={module.id} value={module.id}>
                                    {module.name}
                                  </option>
                                ))}
                              </Field>
                              <div className="text-danger">
                                <ErrorMessage name="integrationModuleId" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element="content">
                        <div className="w-100">
                          <div className="fv-row">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-4">
                              <span className="required">Select Source</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Select Source"
                              ></i>
                            </label>
                            <div className="d-flex flex-column mb-7 fv-row">
                              <Field
                                as="select"
                                name="sourceId"
                                className="form-select form-select-solid"
                              >
                                <option value="" disabled selected>
                                  Select Source
                                </option>
                                {sources.map((source) => (
                                  <option key={source.id} value={source.id}>
                                    {source.sourceName}
                                  </option>
                                ))}
                              </Field>
                              <div className="text-danger">
                                <ErrorMessage name="sourceId" />
                              </div>
                            </div>
                          </div>
                          <div className="fv-row">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-4">
                              <span className="required">
                                Select Destination
                              </span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Select Destination"
                              ></i>
                            </label>
                            <div className="d-flex flex-column mb-7 fv-row">
                              <Field
                                as="select"
                                name="destinationId"
                                className="form-select form-select-solid"
                              >
                                <option value="" disabled selected>
                                  Select Destination
                                </option>
                                {destinations.map((destination) => (
                                  <option
                                    key={destination.id}
                                    value={destination.id}
                                  >
                                    {destination.destinationName}
                                  </option>
                                ))}
                              </Field>
                              <div className="text-danger">
                                <ErrorMessage name="destinationId" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element="content">
                        <div className="w-100">
                          <div className="fv-row">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-4">
                              <span className="required">Select Task</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Select Task"
                              ></i>
                            </label>
                            <div className="d-flex flex-column mb-7 fv-row">
                              <Field
                                as="select"
                                name="taskTypeId"
                                className="form-select form-select-solid"
                              >
                                <option value="" disabled selected>
                                  Select Task
                                </option>
                                {tasks.map((task) => (
                                  <option key={task.id} value={task.id}>
                                    {task.name}
                                  </option>
                                ))}
                              </Field>
                              <div className="text-danger">
                                <ErrorMessage name="taskTypeId" />
                              </div>
                            </div>
                          </div>
                          <div className="fv-row">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-4">
                              <span className="required">Select Event</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Select Event"
                              ></i>
                            </label>
                            <div className="d-flex flex-column mb-7 fv-row">
                              <Field
                                as="select"
                                name="eventId"
                                className="form-select form-select-solid"
                              >
                                <option value="" disabled selected>
                                  Select Event
                                </option>
                                {events.map((event) => (
                                  <option key={event.id} value={event.id}>
                                    {event.name}
                                  </option>
                                ))}
                              </Field>
                              <div className="text-danger">
                                <ErrorMessage name="eventId" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element="content">
                        <div className="w-100">
                          <div className="vp-field-row mb-10">
                            <div className="d-flex flex-stack">
                              <div className="me-5">
                                <label className="fs-5 fw-bold">
                                  <span>Schedule status</span>
                                </label>
                              </div>
                              <label className="form-check form-switch form-check-custom form-check-solid">
                                <Field
                                  name="scheduleStatus"
                                  className="form-check-input"
                                  type="checkbox"
                                />
                                <span className="form-check-label fw-bold text-gray-400" />
                              </label>
                            </div>
                          </div>
                          <div className="vp-field-schedule-time mb-10 d-flex">
                            <div className="me-6">
                              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                <span className="required">Schedule</span>
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Enter Schedule"
                                ></i>
                              </label>
                              <Field
                                as="select"
                                name="schedulePreDefined"
                                className="form-select form-select-solid"
                                disabled={values.scheduleStatus ? false : true}
                                onClick={() =>
                                  setCronExpression(values, resetForm)
                                }
                              >
                                <option value="" disabled selected>
                                  Select Schedule
                                </option>
                                {scheduleTypes.map((task) => (
                                  <option key={task.id} value={task.id}>
                                    {task.name}
                                  </option>
                                ))}
                              </Field>
                              <div className="text-danger">
                                <ErrorMessage name="schedulePreDefined" />
                              </div>
                            </div>
                            {/* <div>
                              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                <span className="required">Time</span>
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Select Time"
                                ></i>
                              </label>
                              <Field
                                component={TimePicker}
                                name="scheduleTime"
                                scheduleTime={setScheduleTime}
                                values={setTime(values)}
                                disable={
                                  values.scheduleStatus && enableScheduleCron
                                    ? false
                                    : true
                                }
                                placeholder="Enter time"
                              />
                              <div className="text-danger">
                                <ErrorMessage name="scheduleTime" />
                              </div>
                            </div> */}
                          </div>
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Cron</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Enter Cron"
                              ></i>
                            </label>
                            <Field
                              type="text"
                              className="form-control form-control-lg form-control-solid"
                              name="scheduleCron"
                              placeholder="Enter cron string"
                              disabled={
                                values.scheduleStatus && enableScheduleCron
                                  ? false
                                  : true
                              }
                            />
                            <div className="text-danger">
                              <ErrorMessage name="scheduleCron" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element="content">
                        <div className="w-100">
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Store Code</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Enter Stores"
                              ></i>
                            </label>

                            <Field as="select" 
                            name="storeCode"
                            className="form-select form-select-solid"
                            disabled={values.isAllStore ? true : false} 
                            >
                               <option value="" disabled selected>
                                  Select Store
                                </option>
                                {stores.map((store) => (
                                  <option key={store.id} value={store.id}>
                                    {store.name}
                                  </option>
                                ))}
                            </Field> 
                            <div className="text-danger">
                              <ErrorMessage name="storeCode" />
                            </div>
                          </div>
                          <div className="d-flex flex-stack">
                            <div className="me-5">
                              <label className="fs-6 fw-bold form-label">
                                Store Status
                              </label>
                            </div>
                            <label className="form-check form-switch form-check-custom form-check-solid">
                              <Field
                                name="storeStatus"
                                className="form-check-input"
                                type="checkbox"
                                disabled={values.isAllStore ? true : false}
                              />
                              <div className="text-danger">
                                <ErrorMessage name="storeStatus" />
                              </div>
                              <span className="form-check-label fw-bold text-gray-400" />
                            </label>
                          </div>
                          <div className="d-flex flex-stack align-items-center flex-row-reverse mt-sm-4">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary me-3"
                              onClick={() =>
                                addStoreClickHandler(resetForm, values)
                              }
                              disabled={
                                (values.isAllStore ? true : false) ||
                                (values.storeCode ? false : true)
                              }
                            >
                              Add
                            </button>
                            <div className="text-danger">
                              <ErrorMessage name="stores" />
                            </div>
                          </div>
                          <div className="fv-row mb-10 mt-sm-4">
                            <PlatformTaskStoreTable
                              className="mb-5 mb-xl-8"
                              platformTaskStoreList={platformTaskStores}
                              removeStore={removeStoreClickHandler}
                              values={values}
                              setStoreValue={setStores(values)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-stack pt-10">
                        <div className="me-2">
                          <button
                            onClick={() => prevStep(stepper.current)}
                            type="button"
                            className="btn btn-lg btn-light-primary me-3"
                            data-kt-stepper-action="previous"
                          >
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr063.svg"
                              className="svg-icon-4 me-1"
                            />
                            Back
                          </button>
                        </div>

                        <div>
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary me-3"
                          >
                            <span className="indicator-label">
                              {stepper.current?.currentStepIndex !==
                                stepper.current?.totatStepsNumber! - 1 &&
                                "Continue"}
                              {stepper.current?.currentStepIndex ===
                                stepper.current?.totatStepsNumber! - 1 &&
                                "Submit"}
                              <KTSVG
                                path="/media/icons/duotune/arrows/arr064.svg"
                                className="svg-icon-3 ms-2 me-0"
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { MainPlatformTask };
