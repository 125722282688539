/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTSVG } from "../../../helpers";
import { PlatformTaskStoresDTO } from "../../../../app/services/models/PlatformTaskModel";

type Props = {
  className: string;
  platformTaskStoreList: PlatformTaskStoresDTO[];
  removeStore: any;
  values: any;
  setStoreValue: any;
};

const PlatformTaskStoreTable: React.FC<Props> = ({
  className,
  platformTaskStoreList,
  removeStore,
  values,
  setStoreValue,
}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="mw-25px">Id</th>
                <th className="min-w-150px">Name</th>
                <th className="min-w-50px">Status</th>
                <th className="mw-50px text-end">Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {platformTaskStoreList &&
                platformTaskStoreList.map((store) => (
                  <tr key={store.storeCode}>
                    <td>
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                      >
                        {store.storeCode}
                      </a>
                    </td>
                    <td className="fw-bolder">
                    {store.storeName}
                    </td>
                    <td>
                      {store.active ? (
                        <span className="badge badge-light-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge badge-light-danger">
                          Inactive
                        </span>
                      )}
                    </td>
                    <td className="text-end">
                      <a
                        href="#"
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                        onClick={() => removeStore(store.storeCode)}
                        onChange={() => setStoreValue(values)}
                      >
                        <KTSVG
                          path="/media/icons/duotune/general/gen027.svg"
                          className="svg-icon-3"
                        />
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { PlatformTaskStoreTable };
