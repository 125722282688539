import { request } from "../util/Request";

export async function getAllSources() {
  const response = await request("/api/Source/GetAll");
  console.log(response);
  return await response.json();
}

export async function getAllSourcesNames() {
  const response = await request("/api/Source/GetAllNames");
  console.log(response);
  return await response.json();
}
