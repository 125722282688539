import React from "react";
import { TenancyTable } from "../../../_metronic/partials/widgets";

export default function Tenant() {
  return (
    <div>
      <TenancyTable className="mb-5 mb-xl-5" />
    </div>
  );
}
