import { request } from "../util/Request";
import {
  CreateTenantDTO,
  CreateUserDTO,
  DeleteTenantDTO,
  GetTenantActivityDTO,
  GetTenantDTO,
  GetTenantUserDTO,
  UpdateTenantDTO,
} from "./models/TenantModel";

export async function createTenant(data: CreateTenantDTO) {
  const response = await request(`/api/Tenant/Create`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ tenant: data }),
  });
  return await response.json();
}

export async function getTenantList(data: GetTenantDTO) {
  var filters: Array<string> = [];;

  if (data.code !== undefined && data.code !== "") {
    filters.push("code eq " + data.code);
  }

  if (data.name !== undefined && data.name !== "") {
    filters.push("name eq " + data.name);
  }

  if (data.status !== undefined && data.status !== "") {
    filters.push("status eq " + data.status);
  }

  var filter = filters.join(" and ");

  const response = await request(
    `/api/Tenant/GetAll?page=${data.page}&size=${data.size}&filter=${filter}`
  );
  return await response.json();
}

export async function getTenantById(tenantId: string) {
  const response = await request(
    `/api/Tenant/GetTenantById?tenantId=${tenantId}`
  );
  return await response.json();
}

export async function getUsers(tenantId: string) {
  const response = await request(`/api/Tenant/GetUsers?tenantId=${tenantId}`);
  return await response.json();
}

export async function updateTenant(data: UpdateTenantDTO) {
  const response = await request(`/api/Tenant/Update`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ tenant: data }),
  });
  return await response.json();
}

export async function getTenantUserList(data: GetTenantUserDTO) {
  const response = await request(
    `/api/Tenant/GetUsers?page=${data.page}&size=${data.size}&tenantId=${data.tenantId}`
  );
  return await response.json();
}

export async function createTenantUser(data: CreateUserDTO) {
  const response = await request(`/api/Tenant/CreateUser`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ tenant: data }),
  });
  return await response.json();
}

export async function deleteTenantUser(data: DeleteTenantDTO) {
  const response = await request(
    `/api/Tenant/DeleteUser?tenantId=${data.tenantId}&userId=${data.userId}`,
    {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }
  );
  return await response.json();
}

export async function getTenantActivities(data: GetTenantActivityDTO) {
  const response = await request(
    `/api/Tenant/GetTenantActivities?tenantId=${data.tenantId}&last=${data.last}`
  );
  return await response.json();
}

export async function getTenantConfigurations(data: string) {
  const response = await request(`/api/Tenant/configurations?data=${data}`);
  return await response.json();
}
