/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../helpers";
import { useFormik } from "formik";
import * as yup from "yup";
import "./styles/TenancyTable.scss";
import CustomTextPopup from "../../content/popups/CustomTextPopup";
import EditTenantModal from "../../modals/edit-tenant/EditTenantModal";
import AddTenantUsersModal from "../../modals/add-tenant-users/AddTenantUsersModal";
import ViewTenantModal from "../../modals/view-tenant/ViewTenantModal";
import AddUserIcon from "../../../assets/icons/multi-tenancy/add-user.png";
import EditTenantIcon from "../../../assets/icons/multi-tenancy/edit-tenant.png";
import ViewTenantIcon from "../../../assets/icons/multi-tenancy/eye.png";
import {
  CreateTenantDTO,
  GetTenantDTO,
  TenantDTO,
} from "../../../../app/services/models/TenantModel";
import {
  createTenant,
  getTenantList,
} from "../../../../app/services/TenantService";

//Prime react
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";

type Props = {
  className: string;
};

export const configurationSchema = yup.object().shape({
  dbName: yup.string().required().label("Database Name"),
  dbPassword: yup.string().required().label("Database Password"),
  dbUsername: yup.string().required().label("Database Username"),
  dbServer: yup.string().required().label("Database Server"),
});

export const tenantSchema = yup.object().shape({
  name: yup.string().required().label("Tenant Name"),
  code: yup
    .string()
    .required()
    .min(6, "Must be more than 6 characters")
    .max(8, "Must be less than 8 characters")
    .label("Tenant Code"),
  status: yup.boolean().required(),
  configurationsInitialized: yup.boolean(),
  dbName: yup.string().when("configurationsInitialized", {
    is: (val: boolean) => val === true,
    then: yup.string().required().label("Database Name"),
  }),
  dbPassword: yup.string().when("configurationsInitialized", {
    is: (val: boolean) => val === true,
    then: yup.string().required().label("Database Password"),
  }),
  dbUsername: yup.string().when("configurationsInitialized", {
    is: (val: boolean) => val === true,
    then: yup.string().required().label("Database Username"),
  }),
  dbServer: yup.string().when("configurationsInitialized", {
    is: (val: boolean) => val === true,
    then: yup.string().required().label("Database Server"),
  }),
});

const TenancyTable: React.FC<Props> = ({ className }) => {
  const [showModal, setShowModal] = useState(false);
  const [showEditTenantModal, setShowEditTenantModal] = useState(false);
  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [showViewTenantModal, setShowViewTenantModal] = useState(false);
  const refreshBtnRef = useRef<HTMLButtonElement>(null);

  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
  });
  const statuses = ["Active", "Inactive"];
  const [tenantList, setTenantList] = useState<TenantDTO[]>();
  const [selectedTenant, setSelectedTenant] = useState<TenantDTO>();
  const [filterParams, setFilterParams] = useState({
    code: {
      value: "",
      matchMode: FilterMatchMode.CONTAINS,
    },
    name: {
      value: "",
      matchMode: FilterMatchMode.CONTAINS,
    },
    status: {
      value: undefined,
      matchMode: FilterMatchMode.CONTAINS,
    },
  });

  //lazy loading
  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const [tenantStatus, setTenantStatus] = useState(false);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    resetForm,
    getFieldProps,
  } = useFormik({
    initialValues: {
      name: "",
      code: "",
      status: false,
      dbInitialized: false,
      configurationsInitialized: false,
      companyName: "",
      companyOrgNo: "",
      dbName: "",
      dbPassword: "",
      dbServer: "",
      dbUsername: "",
    },
    validationSchema: tenantSchema,
    onSubmit: (values) => {
      addTeant(values);
    },
  });

  //filtering
  const onFilter = (value: any) => {
    if (value !== undefined) {
      setFilterParams({
        code: {
          value: value.filters.code.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        name: {
          value: value.filters.name.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        status: {
          value: value.filters.status.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
      });
    } else {
      setFilterParams({
        code: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        name: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        status: {
          value: undefined,
          matchMode: FilterMatchMode.CONTAINS,
        },
      });
    }
  };

  const notify = (msg: string, status: boolean) => {
    if (status) {
      toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const addTeant = async (tenant: any) => {
    if (Object.keys(tenant).length !== 0) {
      const tenantDTO: CreateTenantDTO = {
        name: tenant.name,
        code: tenant.code,
        isActive: tenant.status,
        createDatabase: tenant.dbInitialized,
        createWOConfigurations: tenant.configurationsInitialized,
        woConfigurations: {
          companyName: tenant.name,
          companyOrgNo: tenant.code,
          dbName: tenant.dbName,
          dbUsername: tenant.dbUsername,
          dbPassword: tenant.dbPassword,
          dbServer: tenant.dbServer,
        },
      };
      await createTenant(tenantDTO)
        .then((result) => {
          setShowModal(false);
          notify("Tenant created successfully", true);
          getTenants();
          setConfigurationsVisibile(false);
          resetForm();
        })
        .catch((error) => {
          console.log(error.message);
          notify("Tenant creation failed", false);
          throw error;
        });
    }
  };

  const handleFetchLastStatus = () => {
    refreshBtnRef.current?.classList.add("rotate-clockwise");
    setTimeout(() => {
      refreshBtnRef.current?.classList.remove("rotate-clockwise");
    }, 3000);
  };

  const getTenants = async () => {
    setLoading(true);
    var data: GetTenantDTO = {
      code: filterParams.code.value,
      name: filterParams.name.value,
      status: filterParams.status.value,
      page: lazyParams.page + 1,
      size: lazyParams.rows,
    };
    try {
      const tenantResponse = await getTenantList(data);
      setTenantList(tenantResponse.records);
      setTotalRecords(tenantResponse.totalCount);
        }catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
  

  const tenantSelection = (rowData: TenantDTO) => {
    setSelectedTenant({
      ...rowData,
      woConfigurations: {...rowData.woConfigurations},
    });
  };

  const setTenantActionButtons = (rowData: any) => {
    return (
      <React.Fragment>
        <td className="text-end">
          <CustomTextPopup
            text="Manage Users"
            id="tenantAddUserButtonPopover"
            placement="left"
          >
            <button
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={toggleAddUsersModal}
            >
              <img
                src={AddUserIcon}
                alt="add users"
                className="w18"
                onClick={() => tenantSelection(rowData)}
              />
            </button>
          </CustomTextPopup>
          <CustomTextPopup
            text="View Tenant Details"
            id="tenantAddUserButtonPopover"
            placement="bottom"
          >
            <button
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={toggleViewTenantModal}
            >
              <img
                src={ViewTenantIcon}
                alt="EditTenant"
                className="w18"
                onClick={() => tenantSelection(rowData)}
              />
            </button>
          </CustomTextPopup>
          <CustomTextPopup
            text="Edit Tenant"
            id="tenantAddUserButtonPopover"
            placement="top"
          >
            <button
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
              onClick={toggleEditTenantModal}
            >
              <img
                src={EditTenantIcon}
                alt="EditTenant"
                className="w18"
                onClick={() => tenantSelection(rowData)}
              />
            </button>
          </CustomTextPopup>
        </td>
      </React.Fragment>
    );
  };

  const findStatus = (status: boolean) => {
    if (status === true) {
      return (
        <span className="badge badge-light-success fs-7 fw-bold">Active</span>
      );
    } else if (status === false) {
      return (
        <span className="badge badge-light-danger fs-7 fw-bold">Inactive</span>
      );
    } else {
      return (
        <span className="text-muted fw-bold text-muted d-block fs-7">
          Undefined
        </span>
      );
    }
  };

  //body setting functions
  const setOrgStatus = (rowData: any) => {
    return findStatus(rowData.isActive);
  };

  const findStatuFilter = (status: string) => {
    if (status === "Active") {
      return (
        <span className="badge badge-light-success fs-7 fw-bold">Active</span>
      );
    } else if (status === "Inactive") {
      return (
        <span className="badge badge-light-danger fs-7 fw-bold">Inactive</span>
      );
    } else {
      return (
        <span className="text-muted fw-bold text-muted d-block fs-7">
          Undefined
        </span>
      );
    }
  };

  //templates
  const setFilterParamsStatusDropDown = (e: any) => {
    let filterParamsCopy = { ...filterParams };
    filterParamsCopy.status = { value: e, matchMode: FilterMatchMode.EQUALS };
    setFilterParams(filterParamsCopy);
  };

  const statusFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => setFilterParamsStatusDropDown(e.value)}
        itemTemplate={findStatuFilter}
        className="p-column-filter"
        showClear
        style={{ "min-width": "11rem" }}
      />
    );
  };

  const findTenantStatus = (tenantStatus: string) => {
    if (tenantStatus === "1") {
      return (
        <span className="badge badge-light-success fs-7 fw-bold d-flex success">
          <KTSVG
            path="/media/icons/duotune/general/success-circle.svg"
            className="svg-icon-3 badge-light-success me-1 d-flex"
          />
          Success
        </span>
      );
    }
    if (tenantStatus === "2") {
      return (
        <span
          className="badge badge-light-info fs-7 fw-bold d-flex pending"
          style={{ color: "#00A3FF" }}
        >
          <KTSVG
            path="/media/icons/duotune/general/pending-circle.svg"
            className="svg-icon-3 badge-light-info me-1 d-flex"
          />
          Pending
        </span>
      );
    }
    if (tenantStatus === "3") {
      return (
        <span className="badge badge-light-danger fs-7 fw-bold d-flex failed">
          <KTSVG
            path="/media/icons/duotune/general/failed-circle.svg"
            className="svg-icon-3 badge-light-danger me-1 d-flex"
          />
          Failed
        </span>
      );
    }
  };

  const [configurationsVisible, setConfigurationsVisibile] =
    useState<boolean>(false);

  const toggleConfigurations = () => {
    if (!values.configurationsInitialized) {
      setConfigurationData({
        companyName: "",
        companyOrgNo: "",
        dbName: "",
        dbPassword: "",
        dbServer: "",
        dbUsername: "",
      });
      setFieldValue("companyName", "");
      setFieldValue("companyOrgNo", "");
      setFieldValue("dbName", "");
      setFieldValue("dbPassword", "");
      setFieldValue("dbServer", "");
      setFieldValue("dbUsername", "");
    }

    setConfigurationsVisibile(!configurationsVisible);
    setFieldValue(
      "configurationsInitialized",
      !values.configurationsInitialized
    );
  };

  const [configurationData, setConfigurationData] = useState({
    companyName: "",
    companyOrgNo: "",
    dbName: "",
    dbPassword: "",
    dbServer: "",
    dbUsername: "",
  });

  const renderInputField = (
    fieldName: keyof typeof values,
    label: string,
    placeholder: string
  ) => {
    return (
      <div className="form-group mb-7">
        <CustomTextPopup
          text={`Enter ${label.toLowerCase()} that is used for configurations.`}
          id={`${fieldName}LabelPopover`}
          placement="right"
        >
          <label className="mb-2">{label}</label>
        </CustomTextPopup>
        <input
          id={fieldName}
          {...getFieldProps(fieldName)}
          type="text"
          className="form-control form-control-solid mt-1"
          placeholder={`${placeholder}`}
          value={String(values[fieldName])}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched[fieldName] && errors[fieldName] && (
          <p className="text-danger">{errors[fieldName]}</p>
        )}
      </div>
    );
  };

  const renderCreateTenantModal = useMemo(() => {
    if (showModal) {
      return (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          dialogClassName="new-tenant-modal"
        >
          <Modal.Header closeButton className="py-3">
            <h2>New Tenant</h2>
          </Modal.Header>
          <Modal.Body>
            <div>
              <form autoComplete="off">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-7">
                        <CustomTextPopup
                          text="Tenant name that is used to store the tenant in the system."
                          id="tenantNameLabelPopover"
                          placement="right"
                        >
                          <label>Name:*</label>
                        </CustomTextPopup>
                        <input
                          id="name"
                          type="text"
                          onChange={handleChange}
                          value={values.name}
                          onBlur={handleBlur}
                          className="form-control form-control-solid mt-1"
                          placeholder="Enter name"
                        />
                        {errors.name && touched.name && (
                          <p className="text-danger">{errors.name}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-7">
                        <CustomTextPopup
                          text="Database name that is used to create the database."
                          id="tenantCodeLabelPopover"
                          placement="right"
                        >
                          <label>Code:*</label>
                        </CustomTextPopup>
                        <input
                          id="code"
                          type="text"
                          onChange={handleChange}
                          value={values.code}
                          className="form-control form-control-solid mt-1"
                          placeholder="Enter code"
                          onBlur={handleBlur}
                        />
                        {errors.code && touched.code && (
                          <p className="text-danger">{errors.code}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-7">
                        <label className="form-check form-switch form-check-custom form-check-solid">
                          Create Database
                          <input
                            id="dbInitialized"
                            type="checkbox"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-check-input ms-20"
                          />
                          <span className="form-check-label fw-bold text-gray-400" />
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-7">
                        <label className="form-check form-switch form-check-custom form-check-solid">
                          Status
                          <input
                            id="status"
                            type="checkbox"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-check-input ms-20"
                          />
                          <span className="form-check-label fw-bold text-gray-400" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <hr className="flex-grow-1 border-dark my-3 mb-6" />
                    <div className="section-header">
                      <h4>Web Office Configurations</h4>
                    </div>
                    <div className="form-group mb-4 mt-4">
                      <label className="form-check form-switch form-check-custom form-check-solid">
                        Add Configurations
                        <input
                          id="configurationsInitialized"
                          type="checkbox"
                          checked={values.configurationsInitialized}
                          onChange={toggleConfigurations}
                          onBlur={handleBlur}
                          className="form-check-input ms-20"
                        />
                      </label>
                    </div>
                    <div>
                      {configurationsVisible && (
                        <div>
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-lg-6">
                                {renderInputField(
                                  "dbName",
                                  "Database Name",
                                  "database name"
                                )}
                                {renderInputField(
                                  "dbPassword",
                                  "Database Password",
                                  "database password"
                                )}
                              </div>
                              <div className="col-lg-6">
                                {renderInputField(
                                  "dbServer",
                                  "Database Server",
                                  "database server"
                                )}
                                {renderInputField(
                                  "dbUsername",
                                  "Database Username",
                                  "database Username"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-top-0">
            <button
              type="submit"
              className="btn btn-primary me-3 py-3"
              onClick={() => {
                handleSubmit();
              }}
            >
              Create
            </button>
            <button
              type="button"
              className="btn btn-light py-3"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      );
    }
    return null;
  }, [
    showModal,
    values,
    handleChange,
    handleSubmit,
    errors,
    handleBlur,
    touched,
    configurationsVisible,
    configurationData,
  ]);

  const toggleEditTenantModal = () => {
    setShowEditTenantModal((prevState: boolean) => !prevState);
    getTenants();
  };

  const toggleAddUsersModal = () => {
    setShowAddUsersModal((prevState: boolean) => !prevState);
  };

  const toggleViewTenantModal = () => {
    setShowViewTenantModal((prevState: boolean) => !prevState);
  };

  useEffect(() => {
    getTenants();
  }, [lazyParams]);

  useEffect(() => {
    getTenants();
  }, [filterParams]);

  return (
    <div className="card mb-5 mb-xl-8 companyTable">
      <div className="card-header border-0 pt-5">
        <div className="col-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Tenants</span>
            <span className="text-muted mt-1 fw-bold fs-7">
              {totalRecords} Records
            </span>
          </h3>
        </div>
        <div className="col-8 d-flex flex-row-reverse">
          <h3 className="card-title align-items-start flex-column">
            <a
              className="btn btn-sm btn-primary"
              onClick={() => setShowModal(true)}
            >
              New Tenant
            </a>
          </h3>
        </div>
      </div>
      <div className="card-body py-3">
        {renderCreateTenantModal}
        {showEditTenantModal && (
          <EditTenantModal
            tenant={selectedTenant!}
            isOpen={showEditTenantModal}
            toggleModal={toggleEditTenantModal}
          />
        )}
        {showAddUsersModal && (
          <AddTenantUsersModal
            tenant={selectedTenant!}
            isOpen={showAddUsersModal}
            toggleModal={toggleAddUsersModal}
          />
        )}
        {showViewTenantModal && (
          <ViewTenantModal
            isOpen={showViewTenantModal}
            toggleModal={toggleViewTenantModal}
            rowData={selectedTenant!}
          />
        )}
        <div className="table-responsive">
          <DataTable
            value={tenantList}
            paginator
            lazy
            totalRecords={totalRecords}
            onPage={onPage}
            first={lazyParams.first}
            rows={lazyParams.rows}
            rowsPerPageOptions={[10, 25, 50]}
            dataKey="id"
            rowHover
            filterDisplay="row"
            filters={filterParams}
            loading={loading}
            responsiveLayout="scroll"
            onFilter={(e) => onFilter(e)}
            emptyMessage="No Tenants Found."
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          >
            {/* Tenant Code */}
            <Column
              field="code"
              header="Code"
              className="text-dark min-w-120px"
              filter={true}
              showFilterMenu={false}
              filterField="code"
            />

            {/* Tenant Name */}
            <Column
              field="name"
              header="Name"
              className="text-dark min-w-120px"
              filter={true}
              showFilterMenu={false}
              filterField="name"
            />

            {/* status */}
            <Column
              field="isActive"
              header="Status"
              className="text-dark min-w-120px"
              style={{ minWidth: "12rem" }}
              filter={true}
              showFilterMenu={false}
              filterField="status"
              body={setOrgStatus}
              filterElement={statusFilterTemplate}
            />

            {/* action btn */}
            <Column
              header="Actions"
              headerStyle={{ width: "150px", textAlign: "center" }}
              className="text-dark min-w-150px"
              body={setTenantActionButtons}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export { TenancyTable };
