import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import CustomTextPopup from "../../content/popups/CustomTextPopup";
import { useFormik } from "formik";
import { tenantSchema } from "../../widgets";
import "../styles/ViewTenantModal.scss";
import {
  getTenantById,
  getTenantUserList,
} from "../../../../app/services/TenantService";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
  GetTenantUserDTO,
  GetTenantUsersDTO,
} from "../../../../app/services/models/TenantModel";

interface Props {
  isOpen: boolean;
  toggleModal: () => void;
  rowData: any;
}

const ViewTenantModal = ({ isOpen, toggleModal, rowData }: Props) => {
  const [tenantInfo, setTenantInfo] = useState<any>(null);
  const [usersList, setUsersList] = useState<GetTenantUsersDTO>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [lazyParams, setLazyParams] = useState({
    page: 1,
    size: 5,
  });
  const { handleSubmit, handleChange, handleBlur } = useFormik({
    initialValues: {
      name: "",
      code: "",
      status: false,
    },
    validationSchema: tenantSchema,
    onSubmit: (values) => {},
  });

  const getTenantInfo = useCallback(async () => {
    await getTenantById(rowData.id)
      .then((response) => {
        setTenantInfo(response);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsError(true);
      });
  }, [rowData.id]);

  const getTenantUsers = useCallback(async () => {
    const tenantData: GetTenantUserDTO = {
      page: 1,
      size: 5,
      tenantId: rowData.id,
    };
    await getTenantUserList(tenantData)
      .then((response) => {
        setUsersList(response);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsError(true);
      });
  }, [rowData.id]);
  const onPage = (event: any) => {
    setLazyParams(event);
  };

  useEffect(() => {
    getTenantInfo();
    getTenantUsers();
  }, [rowData]);

  const addedUsersSection = (
    <React.Fragment>
      <h2 className="fw-bold">User Details</h2>
      <div className="card mb-5 mb-xl-8 companyTable">
        <div className="card-body py-3">
          <div className="table-responsive">
            <DataTable
              value={usersList?.records}
              paginator
              lazy
              totalRecords={usersList?.totalCount}
              onPage={onPage}
              first={lazyParams.page - 1}
              rows={lazyParams.size}
              rowsPerPageOptions={[5, 10, 15]}
              dataKey="id"
              rowHover
              loading={isLoading}
              responsiveLayout="scroll"
              emptyMessage="No users found."
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            >
              {/* User Subject */}
              <Column
                field="subject"
                header="Subject"
                className="text-dark min-w-120px"
              />

              {/* User Email */}
              <Column
                field="email"
                header="Email"
                className="text-dark min-w-120px"
              />
            </DataTable>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <Modal
      show={isOpen}
      onHide={toggleModal}
      dialogClassName="view-tenant-modal"
    >
      <Modal.Header closeButton className="py-3">
        <h2>Tenant Details</h2>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="spinner-overlay">
            <div className="spinner-border m-5 text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          !isError && (
            <React.Fragment>
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="card-body d-flex justify-content-between">
                  <div className="flex-side">
                    <div className="form-group mb-7">
                      <CustomTextPopup
                        text="Tenant name that is used to store the tenant in the system."
                        id="tenantNameLabelPopover"
                        placement="right"
                      >
                        <label>Name:*</label>
                      </CustomTextPopup>
                      <input
                        id="name"
                        type="text"
                        value={tenantInfo?.name ? tenantInfo.name : ""}
                        className="form-control form-control-solid mt-1"
                        placeholder="Enter name"
                        disabled={true}
                      />
                    </div>
                    <div className="form-group mb-7">
                      <CustomTextPopup
                        text="Database name that is used to create the database."
                        id="tenantCodeLabelPopover"
                        placement="right"
                      >
                        <label>Code:*</label>
                      </CustomTextPopup>
                      <input
                        id="code"
                        type="text"
                        value={tenantInfo?.code ? tenantInfo.code : ""}
                        className="form-control form-control-solid mt-1"
                        placeholder="Enter code"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="flex-side">
                    <div className="form-group mt-5">
                      <label className="form-check form-switch form-check-custom form-check-solid">
                        Status
                        <input
                          id="status"
                          type="checkbox"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-check-input ms-20"
                          checked={tenantInfo?.isActive}
                          disabled={true}
                        />
                        <span className="form-check-label fw-bold text-gray-400" />
                      </label>
                    </div>
                  </div>
                </div>
              </form>
              {addedUsersSection}
            </React.Fragment>
          )
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ViewTenantModal;
