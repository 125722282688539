import React, { useState, useEffect, useCallback } from "react";
import CryptoJS from "crypto-js";
import { Link, useLocation } from "react-router-dom";
import { CompanyIntegrationModuleTable } from "../../../_metronic/partials/widgets/tables/CompanyIntegraionModuleTable";
import IntegrationModuleType from "../commonTypes/IntegrationModuleType";
import { CompanyType, KeyConfiguration } from "./CompanyType";
import "./styles/CompanyOverview.scss";
import { useCookies } from "react-cookie";
import {
  eaAuthTokenBodyDTO,
  KeyConfigurationDTO,
} from "../../services/models/KeyConfigurationModel";
import {
  getEaAuthToken,
  getKeyConfigurationByCompanyId,
  updateKeyConfigurations,
} from "../../services/KeyConfigurationService";
import { getOrganizationById } from "../../services/CompanyService";
import { getAllIntegrationModules } from "../../services/IntegrationModuleService";

interface LocationState {
  organization: CompanyType;
  integrationModules: IntegrationModuleType[];
  companyId: number;
}

export default function CompanyOverview() {
  const [cookies, setCookie] = useCookies(["companyId"]);
  const location = useLocation();
  const initOrg = {
    id: 0,
    name: "",
    orgNo: "",
    address: "",
    active: true,
    integrationModules: [],
    keyConfigurations: [],
  };
  const query = new URLSearchParams(location.search);
  const authCodeFromParams: string | null = query.get("code");
  const locState = location.state as LocationState;
  const companyId = locState?.companyId;
  const secretPass = process.env.REACT_APP_SECRET_PASS;
  const [companyIdFromCookie, setcompanyIdFromCookie] = useState<number>(
    cookies.companyId
      ? JSON.parse(
          CryptoJS.AES.decrypt(cookies.companyId, secretPass!).toString(
            CryptoJS.enc.Utf8
          )
        )
      : companyId
  );
  const [keyConfigs, setKeyConfigs] = useState<KeyConfiguration[]>([]);
  const [vismaEAConfigs, setVismaEAConfigs] = useState<KeyConfiguration[]>([]);
  const [vismaConfigs, setVismaConfigs] = useState<KeyConfiguration[]>([]);
  const [shopifyConfigs, setShopifyConfigs] = useState<KeyConfiguration[]>([]);
  const [xledgerConfigs, setXledgerConfigs] = useState<KeyConfiguration[]>([]);
  const [matricsConfigs, setMatricsConfigs] = useState<KeyConfiguration[]>([]);
  const [vismaBConfigs, setVismaBConfigs] = useState<KeyConfiguration[]>([]);
  const [marketingCConfigs, setMarketingCConfigs] = useState<
    KeyConfiguration[]
  >([]);
  const [organizationState, setOrganizationState] =
    useState<CompanyType>(initOrg);
  const [integrationModulesState, setIntegrationModulesState] = useState<
    IntegrationModuleType[]
  >([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [showAddDimensionModal, setShowAddDimensionModal] = useState(false);

  useEffect(() => {
    getDetailsAndConfigurations();
  }, [companyIdFromCookie, showAddDimensionModal]);

  useEffect(() => {
    if (authCodeFromParams && keyConfigs.length > 0) updateRefreshToken();
  }, [authCodeFromParams, keyConfigs]);

  useEffect(() => {
    separateConfigsByCategory();
  }, [keyConfigs]);

  const getDetailsAndConfigurations = async () => {
    if (companyIdFromCookie) {
      setLoadingData(true);
      await getAllIntegrationModules()
        .then((result) => {
          setIntegrationModulesState(result);
        })
        .catch((error) => {
          throw error;
        });
      await getOrganizationById(companyIdFromCookie.toString())
        .then((result) => {
          if (result && result.id !== 0) {
            setOrganizationState(result);
          } else {
            // setCreateConfig(true);
          }
        })
        .catch((error) => {
          console.log(error.message);
          throw error;
        });
      await getKeyConfigurationByCompanyId(companyIdFromCookie)
        .then((result) => {
          if (result.length > 0) {
            setKeyConfigs(result);
          } else {
            // setCreateConfig(true);
          }
        })
        .catch((error) => {
          console.log(error.message);
          throw error;
        });
      setLoadingData(false);
    }
  };

  const updateRefreshToken = async () => {
    setLoadingData(true);
    const EAId = keyConfigs.find(
      (confign) => confign.key === "Visma-EA-Client-Id"
    )?.value;
    const EASecret = keyConfigs.find(
      (confign) => confign.key === "Visma-EA-Client-Secret"
    )?.value;

    const body: eaAuthTokenBodyDTO = {
      grant_type: "authorization_code",
      code: authCodeFromParams,
      redirect_uri: keyConfigs.find(
        (confign) => confign.key === "Visma-EA-Callback-Url"
      )?.value!,
      encodedClientSecret: btoa(`${EAId}:${EASecret}`),
      tokenUrl: process.env.REACT_APP_VISMA_EA_TOKEN_URL!,
    };

    const vismaTokenResponse = await getEaAuthToken(body);
    let keyConfigsCopy: KeyConfiguration[] = keyConfigs ? [...keyConfigs] : [];
    let resultArray: KeyConfiguration[];
    if (
      keyConfigsCopy.length > 0 &&
      vismaTokenResponse !== undefined &&
      vismaTokenResponse !== null &&
      vismaTokenResponse.refresh_token
    ) {
      resultArray = keyConfigsCopy.map((config) =>
        config.key === "Visma-EA-Refresh-Token"
          ? { ...config, value: vismaTokenResponse.refresh_token }
          : config
      );
      const dataDTO: KeyConfigurationDTO = {
        keyConfigurations: resultArray,
      };
      await updateKeyConfigurations(dataDTO);
      setKeyConfigs(resultArray);
    }
    setLoadingData(false);
  };

  const separateConfigsByCategory = useCallback(() => {
    let vismaEA: KeyConfiguration[] = [];
    let visma: KeyConfiguration[] = [];
    let shopify: KeyConfiguration[] = [];
    let xledger: KeyConfiguration[] = [];
    let vismaB: KeyConfiguration[] = [];
    let matrics: KeyConfiguration[] = [];
    let marketingC: KeyConfiguration[] = [];
    keyConfigs &&
      keyConfigs.map((item) => {
        if (item.key.includes("Visma-EA") && item.value.length > 0) {
          vismaEA.push(item);
        } else if (
          item.key.includes("Visma-Business") &&
          item.value.length > 0
        ) {
          vismaB.push(item);
        } else if (item.key.includes("Matrics") && item.value.length > 0) {
          matrics.push(item);
        } else if (item.key.includes("Visma") && item.value.length > 0) {
          visma.push(item);
        } else if (item.key.includes("Shopify") && item.value.length > 0) {
          shopify.push(item);
        } else if (item.key.includes("XLedger") && item.value.length > 0) {
          xledger.push(item);
        } else if (
          item.key.includes("Marketing-Cloud") &&
          item.value.length > 0
        ) {
          marketingC.push(item);
        }
        return null;
      });
    setVismaEAConfigs(vismaEA);
    setVismaConfigs(visma);
    setShopifyConfigs(shopify);
    setXledgerConfigs(xledger);
    setMatricsConfigs(matrics);
    setVismaBConfigs(vismaB);
    setMarketingCConfigs(marketingC);
  }, [keyConfigs]);

  const showConfigureVismaEAButton = () => {
    const res = vismaEAConfigs.filter(
      (vconfig) =>
        vconfig.key === "Visma-EA-Callback-Url" ||
        vconfig.key === "Visma-EA-Client-Id" ||
        vconfig.key === "Visma-EA-Client-Secret"
    );
    return res.length === 3 ? true : false;
  };

  const configureBtnText = () => {
    const res = vismaEAConfigs.find(
      (vconfig) => vconfig.key === "Visma-EA-Refresh-Token"
    );
    return res ? "Re-Configure" : "Configure";
  };

  function trimByChar(string: string, character: string) {
    const arr = Array.from(string);
    const first = arr.findIndex((char) => char !== character);
    const last = arr.reverse().findIndex((char) => char !== character);
    return first === -1 && last === -1
      ? ""
      : string.substring(first, string.length - last);
  }

  const setResult = (result: string) => {
    if (result !== undefined) {
      let pretty = result;
      if (result != null) {
        result = trimByChar(result, '"');
        try {
          const obj = JSON.parse(result.replace(/\\/g, ""));
          pretty = JSON.stringify(
            obj,
            (key, value) => (value === null ? undefined : value),
            2
          );
        } catch (e) {
          pretty = result;
        }
      } else {
        pretty = "";
      }
      return (
        <div>
          <textarea
            className="form-control h-300px w-500px pe-auto"
            aria-readonly
            placeholder=""
            id="floatingTextarea2"
            value={pretty}
          />
        </div>
      );
    } else {
      return "test";
    }
  };

  return (
    <div className="companyOverviewPage">
      <div
        className="overlay-layer rounded bg-light vp-loader-wrap"
        hidden={!loadingData}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      <div className="row gy-10 gx-xl-10 pageContent" hidden={loadingData}>
        <div className="col-xl-6">
          <div className="card mb-2" id="kt_profile_details_view">
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">Company Details</h3>
              </div>
            </div>

            <div className="card-body p-9">
              <div className="row eachDetail">
                <label className="col-lg-3 fw-bolder text-muted">Name</label>

                <div className="col-lg-7">
                  <span className="fw-bolder fs-6 text-dark">
                    {organizationState.name}
                  </span>
                </div>
              </div>

              <div className="row eachDetail">
                <label className="col-lg-3 fw-bolder text-muted">Number</label>

                <div className="col-lg-7 fv-row">
                  <span className="fw-bolder fs-6 text-dark">
                    {organizationState.orgNo}
                  </span>
                </div>
              </div>

              <div className="row eachDetail">
                <label className="col-lg-3 fw-bolder text-muted">Address</label>

                <div className="col-lg-7 fv-row">
                  <span className="fw-bolder fs-6 text-dark">
                    {organizationState.address}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="card mb-2 mb-xl-6" id="kt_profile_details_view">
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">Company Integration Modules</h3>
              </div>
            </div>

            <div className="card-body" p-9>
              <div>
                {organizationState.integrationModules.length > 0 &&
                integrationModulesState &&
                integrationModulesState.length > 0 ? (
                  <CompanyIntegrationModuleTable
                    className="mb-5 mb-xl-8"
                    inetgrationModuleList={organizationState.integrationModules}
                    integrationModules={integrationModulesState}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 pe-none">
          <div className="overlay overlay-block rounded px-3">
            <div className="card mb-5 mb-xl-10">
              <div className="card-header cursor-pointer pe-auto">
                <div className="card-title m-0">
                  <h3 className="fw-bolder m-0">Configurations</h3>
                </div>
                <Link
                  to="/company/overview/configdetails"
                  className="btn btn-primary align-self-center"
                  state={{
                    companyId: organizationState.id,
                    hasVismaEAConfigs: vismaEAConfigs.length > 0,
                    hasVismaEConfigs: vismaConfigs.length > 0,
                  }}
                >
                  {keyConfigs.length > 0 ? "Edit Config" : "Add Config"}
                </Link>
              </div>
              <div className="card-body p-9">
                {shopifyConfigs.length > 0 && (
                  <>
                    <h4 className="m-0 mt-3">Shopify</h4>
                    <div>
                      {shopifyConfigs.map(
                        (config) =>
                          config.value.length > 0 && (
                            <div
                              className="row ms-0 mb-0 eachConfig"
                              key={config.id}
                            >
                              <label className="col-lg-3 fw-bold text-muted">
                                {config.key}
                              </label>

                              <div className="col-lg-8">
                                <span className="fw-bolder fs-6 text-dark">
                                  {config.value}
                                </span>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </>
                )}
                {xledgerConfigs.length > 0 && (
                  <>
                    <h4 className="m-0 mt-4 mb-3">XLedger</h4>
                    <div>
                      {xledgerConfigs.map(
                        (config) =>
                          config.value.length > 0 && (
                            <div
                              className="row ms-0 mb-0 eachConfig"
                              key={config.id}
                            >
                              <label className="col-lg-3 fw-bold text-muted">
                                {config.key}
                              </label>

                              <div className="col-lg-8">
                                <span className="fw-bolder fs-6 text-dark">
                                  {config.value}
                                </span>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </>
                )}
                {vismaEAConfigs.length > 0 && (
                  <>
                    <div className="vismaEAHeaderAndConfigure">
                      <h4 className="m-0 mt-4 mb-3">Visma EAccounting</h4>
                      <div>
                        {showConfigureVismaEAButton() && (
                          <a
                            className="btn btn-primary pe-auto"
                            target="blank"
                            type="button"
                            // onClick={handleConfigureClick}
                            href={
                              process.env.REACT_APP_VISMA_EA_AUTHORIZE_URL +
                              `?response_type=code&client_id=${
                                vismaEAConfigs.find(
                                  (k) => k.key === "Visma-EA-Client-Id"
                                )?.value
                              }&state=state&scope=${
                                vismaEAConfigs.find(
                                  (k) => k.key === "Visma-EA-Client-Scopes"
                                )?.value
                              }&redirect_uri=${
                                vismaEAConfigs.find(
                                  (k) => k.key === "Visma-EA-Callback-Url"
                                )?.value
                              }`
                            }
                          >
                            {configureBtnText()}
                          </a>
                        )}
                      </div>
                    </div>
                    <div>
                      {vismaEAConfigs.map(
                        (config) =>
                          config.value.length > 0 && (
                            <div
                              className="row ms-0 mb-0 eachConfig"
                              key={config.id}
                            >
                              <label className="col-lg-3 fw-bold text-muted">
                                {config.key}
                              </label>

                              <div className="col-lg-8">
                                <span className="fw-bolder fs-6 text-dark">
                                  {config.value}
                                </span>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </>
                )}
                {vismaConfigs.length > 0 && (
                  <>
                    <div className="vismaEAHeaderAndConfigure">
                      <h4 className="m-0 mt-4 mb-3">Visma Enterprise</h4>
                    </div>
                    <div>
                      {vismaConfigs.map(
                        (config) =>
                          config.value.length > 0 && (
                            <div
                              className="row ms-0 mb-0 eachConfig"
                              key={config.id}
                            >
                              <label className="col-lg-3 fw-bold text-muted">
                                {config.key}
                              </label>

                              <div className="col-lg-8">
                                <span className="fw-bolder fs-6 text-dark">
                                  {config.key === "Visma-Dimensions"
                                    ? setResult(config.value)
                                    : config.value}
                                </span>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </>
                )}
                {vismaBConfigs.length > 0 && (
                  <>
                    <h4 className="m-0 mt-4 mb-3">Visma Business</h4>
                    <div>
                      {vismaBConfigs.map(
                        (config) =>
                          config.value.length > 0 && (
                            <div
                              className="row ms-0 mb-0 eachConfig"
                              key={config.id}
                            >
                              <label className="col-lg-3 fw-bold text-muted">
                                {config.key}
                              </label>

                              <div className="col-lg-8">
                                <span className="fw-bolder fs-6 text-dark">
                                  {config.value}
                                </span>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </>
                )}
                {matricsConfigs.length > 0 && (
                  <>
                    <h4 className="m-0 mt-4 mb-3">Matrics</h4>
                    <div>
                      {matricsConfigs.map(
                        (config) =>
                          config.value.length > 0 && (
                            <div
                              className="row ms-0 mb-0 eachConfig"
                              key={config.id}
                            >
                              <label className="col-lg-3 fw-bold text-muted">
                                {config.key}
                              </label>

                              <div className="col-lg-8">
                                <span className="fw-bolder fs-6 text-dark">
                                  {config.value}
                                </span>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </>
                )}
                {marketingCConfigs.length > 0 && (
                  <>
                    <h4 className="m-0 mt-4 mb-3">Marketing Cloud</h4>
                    <div>
                      {marketingCConfigs.map(
                        (config) =>
                          config.value.length > 0 && (
                            <div
                              className="row ms-0 mb-0 eachConfig"
                              key={config.id}
                            >
                              <label className="col-lg-3 fw-bold text-muted">
                                {config.key}
                              </label>

                              <div className="col-lg-8">
                                <span className="fw-bolder fs-6 text-dark">
                                  {config.value}
                                </span>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
