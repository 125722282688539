import { useContext, useEffect, useState } from "react";
import { SelectedItemContext } from "../../../layout/MasterLayout";
import { Link } from "react-router-dom";

//PrimeReact
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";

//Types
import { OrganizationType } from "../../../../app/pages/company/CompanyType";
import IntegrationModuleType from "../../../../app/pages/commonTypes/IntegrationModuleType";

//Services
import {
  deleteOrganization,
  getAllOrganizationsTableFilter,
} from "../../../../app/services/CompanyService";
import { getAllIntegrationModules } from "../../../../app/services/IntegrationModuleService";

//Styles
import "./styles/CompanyTable.scss";
import { KTSVG } from "../../../helpers";

//Cookies+Encryption
import CryptoJS from "crypto-js";
import { useCookies } from "react-cookie";
import { OrganizationParamTypes } from "../../../../app/services/types/OrganizationParamTypes";

type Props = {
  className: string;
};

const CompanyTable: React.FC<Props> = ({ className }) => {
  const secretPass = process.env.REACT_APP_SECRET_PASS;
  const editSelectedCompanyContext = useContext(SelectedItemContext);
  const [organizationsList, setOrganizationsList] = useState<
    OrganizationType[]
  >([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [modules, setModules] = useState<IntegrationModuleType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState({
    orgNo: {
      value: "",
      matchMode: FilterMatchMode.CONTAINS,
    },
    name: {
      value: "",
      matchMode: FilterMatchMode.CONTAINS,
    },
    address: {
      value: "",
      matchMode: FilterMatchMode.CONTAINS,
    },
    active: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
  });
  const statuses = ["Active", "Inactive"];
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [deleteAlertVisibility, setDeleteAlertVisibility] =
    useState<boolean>(false);
  const [cookies, setCookie] = useCookies(["companyId"]);

  const onFilter = (e: any) => {
    if (e !== undefined) {
      setFilterParams({
        orgNo: {
          value: e.filters.orgNo.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        name: {
          value: e.filters.name.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        address: {
          value: e.filters.address.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        active: {
          value: e.filters.active.value,
          matchMode: FilterMatchMode.EQUALS,
        },
      });
    } else {
      setFilterParams({
        orgNo: { value: "", matchMode: FilterMatchMode.CONTAINS },
        name: { value: "", matchMode: FilterMatchMode.CONTAINS },
        address: { value: "", matchMode: FilterMatchMode.CONTAINS },
        active: { value: null, matchMode: FilterMatchMode.EQUALS },
      });
    }
  };

  //lazy loading
  const onPage = (event: any) => {
    setLazyParams(event);
  };

  //API Calls
  const getIntegrationModules = () => {
    getAllIntegrationModules()
      .then((result) => {
        setModules(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const getOrganizations = () => {
    setLoading(true);
    var data: OrganizationParamTypes = {
      orgNo: filterParams.orgNo.value,
      dataLimit: lazyParams.rows,
      pageNumber: lazyParams.page + 1,
      name: filterParams.name.value,
      address: filterParams.address.value,
      active: filterParams.active.value,
    };
    getAllOrganizationsTableFilter(data)
      .then((result) => {
        setOrganizationsList(result.companies);
        setTotalRecords(result.totalRecords);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  useEffect(() => {
    getOrganizations();
  }, [lazyParams]);

  useEffect(() => {
    getOrganizations();
  }, [filterParams]);

  const findStatus = (status: boolean) => {
    if (status === true) {
      return (
        <span className="badge badge-light-success fs-7 fw-bold">Active</span>
      );
    } else if (status === false) {
      return (
        <span className="badge badge-light-danger fs-7 fw-bold">Inactive</span>
      );
    } else {
      return (
        <span className="text-muted fw-bold text-muted d-block fs-7">
          Undefined
        </span>
      );
    }
  };

  const handleEyeClick = (companyId: number) => {
    if (companyId) {
      const data = CryptoJS.AES.encrypt(
        JSON.stringify(companyId),
        secretPass!
      ).toString();
      setCookie("companyId", data, {
        path: "/",
      });
    }
  };

  const findStatuFilter = (status: string) => {
    if (status === "Active") {
      return (
        <span className="badge badge-light-success fs-7 fw-bold">Active</span>
      );
    } else if (status === "Inactive") {
      return (
        <span className="badge badge-light-danger fs-7 fw-bold">Inactive</span>
      );
    } else {
      return (
        <span className="text-muted fw-bold text-muted d-block fs-7">
          Undefined
        </span>
      );
    }
  };

  //body setting functions
  const setOrgStatus = (rowData: any) => {
    return findStatus(rowData.active);
  };

  const setOrgActionButtons = (rowData: any) => {
    return (
      <div>
        <div className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 pe-auto">
          <Link
            to="/company/overview"
            state={{
              companyId: rowData.id,
            }}
            onClick={() => handleEyeClick(rowData.id)}
          >
            <KTSVG
              path="/media/icons/duotune/general/view.svg"
              className="svg-icon-3"
            />
          </Link>
        </div>
        <a
          href="#"
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 pe-auto"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_update_organization"
          onClick={() => updateCompany(rowData.id.toString())}
        >
          <KTSVG
            path="/media/icons/duotune/art/art005.svg"
            className="svg-icon-3"
          />
        </a>
        <a
          href="#"
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm pe-auto"
          onClick={() => removeOrganizationAlert(rowData.id.toString())}
        >
          <KTSVG
            path="/media/icons/duotune/general/gen027.svg"
            className="svg-icon-3"
          />
        </a>
      </div>
    );
  };

  //templates
  const setFilterParamsStatusDropDown = (e: any) => {
    let filterParamsCopy = { ...filterParams };
    filterParamsCopy.active = { value: e, matchMode: FilterMatchMode.EQUALS };
    setFilterParams(filterParamsCopy);
  };

  const statusFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => setFilterParamsStatusDropDown(e.value)}
        itemTemplate={findStatuFilter}
        className="p-column-filter"
        showClear
        style={{ "min-width": "11rem" }}
      />
    );
  };

  const removeOrganizationAlert = (organizationId: string) => {
    setSelectedCompany(organizationId);
    setDeleteAlertVisibility(true);
  };

  // table operations
  const removeOrganization = async (organizationId: string) => {
    await deleteOrganization(organizationId)
      .then((result) => {
        if (result === organizationId) {
          setOrganizationsList(
            organizationsList.filter((item) =>
              item.companies.find((com) => com.id !== parseInt(organizationId))
            )
          );
        }
        getOrganizations();
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
    setDeleteAlertVisibility(false);
  };

  const hideDialog = () => {
    setDeleteAlertVisibility(false);
  };

  const updateCompany = (id: string) => {
    editSelectedCompanyContext.setSelectedItem?.(id);
  };

  const createCompany = (id: string) => {
    editSelectedCompanyContext.setSelectedItem?.(id);
  };

  useEffect(() => {
    getIntegrationModules();
    getOrganizations();
  }, [organizationsList?.length, editSelectedCompanyContext.selectedItem]);

  return (
    <div className="card mb-5 mb-xl-8 companyTable">
      <div className="card-header border-0 pt-5">
        <div className="col-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">
              Organizations
            </span>
            <span className="text-muted mt-1 fw-bold fs-7">
              {totalRecords} Records
            </span>
          </h3>
        </div>
        <div className="col-8 d-flex flex-row-reverse">
          <h3 className="card-title align-items-start flex-column">
            <a
              href="#"
              className="btn btn-sm btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_create_organization"
              id="kt_toolbar_primary_button"
              onClick={() =>
                createCompany(organizationsList?.length.toString())
              }
            >
              Create
            </a>
          </h3>
        </div>
      </div>
      <div className="card-body py-3">
        <div className="table-responsive">
          <DataTable
            value={organizationsList}
            paginator
            lazy
            totalRecords={totalRecords}
            onPage={onPage}
            first={lazyParams.first}
            rows={lazyParams.rows}
            rowsPerPageOptions={[10, 25, 50]}
            dataKey="id"
            rowHover
            filterDisplay="row"
            loading={loading}
            responsiveLayout="scroll"
            emptyMessage="No companies found."
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            filters={filterParams}
            onFilter={(e) => onFilter(e)}
          >
            {/* Organization Number */}
            <Column
              field="orgNo"
              header="Org Number"
              className="text-dark min-w-120px"
              filter={true}
              showFilterMenu={false}
              showClearButton={false}
            />

            {/* Organization Name */}
            <Column
              field="name"
              header="Name"
              className="text-dark min-w-120px"
              filter={true}
              showFilterMenu={false}
              showClearButton={false}
            />

            {/* Organization Address */}
            <Column
              field="address"
              header="Address"
              className="text-dark min-w-120px"
              filter={true}
              showFilterMenu={false}
              showClearButton={false}
            />

            {/* status */}
            <Column
              field="active"
              header="Status"
              className="text-dark min-w-120px"
              style={{ minWidth: "12rem" }}
              body={setOrgStatus}
              filter={true}
              filterElement={statusFilterTemplate}
              showFilterMenu={false}
              showClearButton={false}
            />

            {/* action btn */}
            <Column
              header="Actions"
              headerStyle={{ width: "150px", textAlign: "right" }}
              bodyStyle={{
                textAlign: "center",
                overflow: "visible",
                width: "150px",
              }}
              body={setOrgActionButtons}
            />
          </DataTable>
          <Dialog
            visible={deleteAlertVisibility}
            style={{ width: "750px" }}
            modal
            className="p-fluid"
            onHide={hideDialog}
          >
            {selectedCompany && selectedCompany === null ? (
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="4"
                animationDuration=".5s"
              />
            ) : (
              <div className="alert alert-dismissible d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">
                <span className="svg-icon svg-icon-5tx svg-icon-danger mb-5">
                  <KTSVG
                    path="/media/icons/duotune/general/gen044.svg"
                    className="svg-icon-1"
                  />
                </span>

                <div className="text-center">
                  <h5 className="fw-bolder fs-1 mb-5">
                    Do you want to remove the Company?
                  </h5>

                  <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                  <div className="mb-9">
                    Removing the company will result in deletion of realted
                    platform tasks.
                  </div>
                  <div className="d-flex flex-center flex-wrap">
                    <a
                      href="#"
                      className="btn btn-outline btn-outline-danger btn-active-danger m-2"
                      onClick={hideDialog}
                    >
                      Cancel
                    </a>
                    <a
                      href="#"
                      className="btn btn-danger m-2"
                      onClick={() => removeOrganization(selectedCompany)}
                    >
                      Delete
                    </a>
                  </div>
                </div>
              </div>
            )}
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export { CompanyTable };
