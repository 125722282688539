import { FC, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../../helpers";
import '../styles/TaskSubscriptions.scss'
import { Field, FieldProps, Form, Formik } from "formik";
import Tags from "@yaireo/tagify/dist/react.tagify" // React-wrapper file
import "@yaireo/tagify/dist/tagify.css" // Tagify CSS
import { TagData, TagifySettings } from "@yaireo/tagify";
import { getPlatformTaskSubscriptions, updatePlatformTaskSubscriptions } from "../../../../app/services/PlatformTaskService";
import { GetTaskSubsciptionType, SubsciptionType, UpdateTaskSubscriptionsDto } from "../../../../app/services/models/PlatformTaskModel";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface TaskSubscriptionModalProps {
  id: string;
  isOpen: boolean;
  toggleModal: (value: boolean) => void;
}

const TaskSubscriptionModal: FC<TaskSubscriptionModalProps> = ({ id, isOpen, toggleModal }) => {

  const tagifySuccessRef = useRef<Tagify>();
  const tagifyFaiureRef = useRef<Tagify>();
  const tagifyEmptyRef = useRef<Tagify>();
  const btnRef = useRef<HTMLButtonElement | null>(null);

  const init: GetTaskSubsciptionType = {
    taskId: 0,
    subscriptions: []
  }

  const [data, setData] = useState<GetTaskSubsciptionType>(init);
  const [loading, setLoading] = useState<boolean>(false);

  const settings: TagifySettings<TagData> = {
    pattern: new RegExp('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}')
  };

  const waitForData = async (wait: boolean) => {
    if (wait) {
      setLoading(true);
      btnRef.current?.setAttribute('data-kt-indicator', 'on');
    } else {
      setLoading(false);
      btnRef.current?.removeAttribute("data-kt-indicator");
    }

  }

  const fetchData = async (id: string) => {
    if (id == "") {
      return;
    }

    console.log("id:", id);
    waitForData(true);
    await getPlatformTaskSubscriptions(id)
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        toast.error("Couldn't load the data for the task. Please try again or contact an administrator", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        waitForData(false);
      });
  };

  const handleCloseModal = () => {
    toggleModal(false);
  };

  const updateEmailList = () => {
    const completedEmails = data.subscriptions.find(sub => sub.status.name === 'Completed')?.emails || [];
    const failedEmails = data.subscriptions.find(sub => sub.status.name === 'Failed')?.emails || [];
    const emptyEmails = data.subscriptions.find(sub => sub.status.name === 'Empty')?.emails || [];

    // Set the extracted emails as initial values for the Tags component
    tagifySuccessRef.current?.loadOriginalValues(completedEmails);
    tagifyFaiureRef.current?.loadOriginalValues(failedEmails);
    tagifyEmptyRef.current?.loadOriginalValues(emptyEmails);
  };

  useEffect(() => {
    updateEmailList();
  }, [data, isOpen]);

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const updateData = (subscriptions: SubsciptionType[]) => {
    var updatedData = { ...data };
    updatedData.subscriptions = subscriptions;
    setData(updatedData);
  }

  const handlesubmit = async (values: any) => {
    console.log("submit:");  
    let completedEmails = tagifySuccessRef?.current?.getCleanValue().map(t => t.value) || [];
    let failedEmails = tagifyFaiureRef?.current?.getCleanValue().map(t => t.value) || [];
    let emptyEmails = tagifyEmptyRef?.current?.getCleanValue().map(t => t.value) || [];

    let updatedSubs: UpdateTaskSubscriptionsDto = {
      subscriptions: [{
        status: {
          id: 2,
          name: "Completed"
        },
        emails: completedEmails
      },
      {
        status: {
          id: 3,
          name: "Failed"
        },
        emails: failedEmails
      },
      {
        status: {
          id: 4,
          name: "Empty"
        },
        emails: emptyEmails
      }
      ]
    };

      //wait for result
      waitForData(true);

    await updatePlatformTaskSubscriptions(id, updatedSubs)
      .then((result) => {
        updateData(updatedSubs.subscriptions);
        handleCloseModal();
        toast.success("Subscriptions Saved Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        handleCloseModal();
        toast.error("Failed to Save Subscriptions. Please try again or contact an administrator", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Error fetching data:', error);
      })
      .finally(()=>{
        waitForData(false);
      });

  }



  return (
    <>
      <Formik initialValues={{ onSuccess: '' }} onSubmit={handlesubmit}>
        <Form>
          <Modal
            show={isOpen}
            onHide={handleCloseModal}
            dialogClassName="modal-xl"
          >
            <ModalHeader closeButton={true}>
              <span>
                <h2>Platform Task Notifictions</h2>
              </span>
            </ModalHeader>
            <ModalBody>
              {/* <div className="modal-loader-container">
                <div className="overlay-layer bg-dark bg-opacity-5" hidden={!loading}>
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div> */}
              <div className="flex-row-fluid py-lg-5 px-lg-15">

                <h4 className="sectionHeader">Task Execution</h4>
                <div className="row mb-3">
                  <label className="col-lg-3 col-form-label fw-bold fs-6">
                    <span className="">On Success</span>
                  </label>
                  <div className="col-lg-9 fv-row">
                    <Field name="onSuccess">
                      {({ field }: FieldProps) => (
                        <Tags
                          tagifyRef={tagifySuccessRef}
                          settings={settings}
                          autoFocus={true}
                          placeholder="user@example.com"
                          loading={true}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-lg-3 col-form-label fw-bold fs-6">
                    <span className="">On Failure</span>
                  </label>
                  <div className="col-lg-9 fv-row">
                    <Tags
                      tagifyRef={tagifyFaiureRef}
                      settings={settings}
                      placeholder="user@example.com"
                      autoFocus={true}
                      loading={true}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-lg-3 col-form-label fw-bold fs-6">
                    <span className="">On Empty Data</span>
                  </label>
                  <div className="col-lg-9 fv-row">
                    <Tags
                      tagifyRef={tagifyEmptyRef}
                      settings={settings}
                      placeholder="user@example.com"
                      autoFocus={true}
                    />
                  </div>
                </div>
              </div>

            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-light" onClick={handleCloseModal}>Close</button>
              <button type="submit" className="btn btn-primary" onClick={handlesubmit} ref={btnRef} disabled={loading}>
                <span className="indicator-label">Save changes</span>
                <span className="indicator-progress">Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
              </button>
            </ModalFooter>

          </Modal>
        </Form>
      </Formik>
    </>
  );
}

export default TaskSubscriptionModal;
