import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { AsideDefault } from "./components/aside/AsideDefault";
import { Footer } from "./components/Footer";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { Toolbar } from "./components/toolbar/Toolbar";
//import {RightToolbar} from '../partials/layout/RightToolbar'
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { PageDataProvider } from "./core";
import { useLocation } from "react-router-dom";
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  MainOrganization,
  MainPlatformTask,
  UpdatePlatformTask,
  TriggerPlatformTask,
  UpdateOrganization,
} from "../partials";
import { MenuComponent } from "../assets/ts/components";

interface selectedItemProps {
  selectedItem?: string | number;
  setSelectedItem?(itemId: string): void;
}

export const SelectedItemContext = createContext<selectedItemProps>({
  selectedItem: 0,
});

const SelectedItemProvider = ({ children }: PropsWithChildren<any>) => {
  const [selectedItem, setSelectedItem] =
    useState<selectedItemProps["selectedItem"]>();

  return (
    <SelectedItemContext.Provider value={{ selectedItem, setSelectedItem }}>
      {children}
    </SelectedItemContext.Provider>
  );
};

const MasterLayout = () => {
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <PageDataProvider>
      <SelectedItemProvider>
        <div className="page d-flex flex-row flex-column-fluid">
          <AsideDefault />
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
            style={{ paddingTop: "75px" }}
          >
            <HeaderWrapper />

            <div
              id="kt_content"
              className="content d-flex flex-column flex-column-fluid"
            >
              {/* <Toolbar /> */}
              <div className="post d-flex flex-column-fluid" id="kt_post">
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        {/* <RightToolbar /> */}
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        {/* <Main /> */}
        <MainOrganization />
        <MainPlatformTask />
        <UpdatePlatformTask />
        <TriggerPlatformTask />
        <UpdateOrganization />
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </SelectedItemProvider>
    </PageDataProvider>
  );
};

export { MasterLayout };
