import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import CustomTextPopup from "../../content/popups/CustomTextPopup";
import { useFormik } from "formik";
import * as yup from "yup";
import { KTSVG } from "../../../helpers";
import "../styles/AddTenantUsersModal.scss";
import EditIcon from "../../../assets/icons/multi-tenancy/edit-tenant.png";
import TrashIcon from "../../../assets/icons/multi-tenancy/trash-icon.png";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  Dimensions,
  KeyConfiguration,
  ViewKeyConfiguration,
} from "../../../../app/pages/company/CompanyType";
import {
  Dimension,
  KeyConfigValueDTO,
} from "../../../../app/services/models/KeyConfigurationModel";
import {
  createKeyConfiguration,
  getKeyConfigurationByCompanyId,
} from "../../../../app/services/KeyConfigurationService";

interface Props {
  organization: any;
  isOpen: boolean;
  toggleModal: () => void;
}

const tenantUserSchema = yup.object().shape({
  AccountNumber: yup.string().required().label("AccountNumber"),
  Dimension: yup.string().required().label("Dimension"),
  Dimension2: yup.string().label("Dimension 2").default("0"),
  Dimension3: yup.string().label("Dimension 3").default("0"),
});

export const emptyViewKeyConfiguration: ViewKeyConfiguration = {
  id: 0,
  companyId: 0,
  key: "",
  value: {
    dimensions: [],
  },
};

export const emptyDimensions: Dimensions = {
  dimensions: [],
};

export const emptyDimension: Dimension = {
  AccountNumber: "",
  Dimension: "",
  Dimension2: "",
  Dimension3: "",
};

const AddVismaDimensionsModal = ({
  organization: tenant,
  isOpen,
  toggleModal,
}: Props) => {
  const [OrganizationState] = useState<string>(tenant);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteAlertVisibility, setDeleteAlertVisibility] =
    useState<boolean>(false);
  const [deletingDimension, setDeletingDimension] = useState<Dimension>();
  const [keyConfigList, setKeyConfigList] = useState<KeyConfiguration[]>([]);
  const [viewKeyConfig, setViewKeyConfig] = useState<ViewKeyConfiguration>(
    emptyViewKeyConfiguration
  );

  const [lazyParams, setLazyParams] = useState({
    page: 1,
    size: 5,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    setValues,
    validateForm,
    setTouched,
  } = useFormik({
    initialValues: {
      AccountNumber: "",
      Dimension: "",
      Dimension2: "",
      Dimension3: "",
    },
    validationSchema: tenantUserSchema,
    onSubmit: (values, { setSubmitting }) => {
      addDimensions(values);
    },
  });

  const notify = (msg: string, status: boolean) => {
    if (status) {
      toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  function addDimensionToViewKeyConfig(
    existingViewKeyConfig: ViewKeyConfiguration,
    newDimension: Dimension
  ): ViewKeyConfiguration {
    const existingDimensions = existingViewKeyConfig.value.dimensions;
    const isDuplicate = existingDimensions.some(
      (dimension) => dimension.AccountNumber === newDimension.AccountNumber
    );

    if (!isDuplicate) {
      return {
        ...existingViewKeyConfig,
        value: {
          ...existingViewKeyConfig.value,
          dimensions: [...existingDimensions, newDimension],
        },
      };
    }

    return existingViewKeyConfig;
  }

  const validateDimensionClickHandler = (resetForm: any, values: any) => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        addDimensionClickHandler(resetForm, values);
      } else {
        setTouched({
          ...touched,
          AccountNumber: true,
          Dimension: true,
          Dimension2: true,
          Dimension3: true,
        });
      }
    });
  };

  const addDimensionClickHandler = (resetForm: any, values: any) => {
    const newDimension: Dimension = {
      AccountNumber: values.AccountNumber,
      Dimension: values.Dimension,
      Dimension2: values.Dimension2,
      Dimension3: values.Dimension3,
    };
    setViewKeyConfig((prevViewKeyConfig) => {
      const updatedViewKeyConfig = addDimensionToViewKeyConfig(
        prevViewKeyConfig,
        newDimension
      );
      return updatedViewKeyConfig;
    });
    resetForm();
  };

  const removeDimensionClickHandler = () => {
    setViewKeyConfig((prevViewKeyConfig) => {
      const updatedViewKeyConfig = removeDimensionFromViewKeyConfig(
        prevViewKeyConfig!,
        deletingDimension?.AccountNumber!
      );
      return updatedViewKeyConfig;
    });
    setDeleteAlertVisibility(false);
  };

  const editDimensionClickHandler = (dimensionValue: Dimension) => {
    setValues({
      AccountNumber: dimensionValue.AccountNumber,
      Dimension: dimensionValue.Dimension,
      Dimension2: dimensionValue.Dimension2,
      Dimension3: dimensionValue.Dimension3,
    });
    setViewKeyConfig((prevViewKeyConfig) => {
      const updatedViewKeyConfig = removeDimensionFromViewKeyConfig(
        prevViewKeyConfig!,
        dimensionValue.AccountNumber
      );
      return updatedViewKeyConfig;
    });
  };

  const removeDimensionFromViewKeyConfig = (
    existingViewKeyConfig: ViewKeyConfiguration,
    accountNumberToRemove: string
  ): ViewKeyConfiguration => {
    const existingDimensions = existingViewKeyConfig.value.dimensions;
    const updatedDimensions = existingDimensions.filter(
      (dimension) => dimension.AccountNumber !== accountNumberToRemove
    );

    return {
      ...existingViewKeyConfig,
      value: {
        ...existingViewKeyConfig.value,
        dimensions: updatedDimensions,
      },
    };
  };

  const mapViewKeyConfigToKeyConfigList = useCallback(() => {
    const mappedConfigs: KeyConfiguration[] = [];
    viewKeyConfig?.value.dimensions.forEach((dimension) => {
      try {
        const dimensionsJSON = JSON.stringify(dimension);
        const keyConfig: KeyConfiguration = {
          id: viewKeyConfig.id,
          companyId: viewKeyConfig.companyId,
          key: viewKeyConfig.key,
          value: dimensionsJSON,
        };
        mappedConfigs.push(keyConfig);
      } catch (error) {
        console.error(
          `Error mapping ViewKeyConfiguration with ID ${dimension.toString()}`
        );
      }
    });
    setKeyConfigList(mappedConfigs);
  }, [viewKeyConfig?.value.dimensions]);

  const addDimensions = async (e: any) => {
    const dimensionValues: KeyConfigValueDTO = { Dimensions: [] };
    keyConfigList.map((keyConfig: KeyConfiguration, index: number) => {
      let dim: Dimension = {
        AccountNumber: JSON.parse(keyConfig.value).AccountNumber.toString(),
        Dimension: JSON.parse(keyConfig.value).Dimension.toString(),
        Dimension2:
          JSON.parse(keyConfig.value).Dimension2.toString() === ""
            ? "0"
            : JSON.parse(keyConfig.value).Dimension2.toString(),
        Dimension3:
          JSON.parse(keyConfig.value).Dimension3.toString() === ""
            ? "0"
            : JSON.parse(keyConfig.value).Dimension3.toString(),
      };
      dimensionValues.Dimensions.push(dim);
    });
    const config: KeyConfiguration = {
      id: 0,
      companyId: tenant,
      key: "Visma-Dimensions",
      value: JSON.stringify(dimensionValues),
    };
    await createKeyConfiguration(config)
      .then((result) => {
        if (result.success) {
          notify(result.message, true);
          toggleModal();
        }
        if (!result.success) {
          notify("User creation failed", false);
        }
      })
      .catch((err) => {
        console.log(err);
        notify(JSON.stringify(err.message), true);
      });
  };

  const hideDialog = () => {
    setDeleteAlertVisibility(false);
  };

  const mapToSingleViewKeyConfiguration = (
    configs: KeyConfiguration[]
  ): ViewKeyConfiguration => {
    const dimensions: Dimensions = { dimensions: [] };
    for (const config of configs) {
      try {
        const valueObject: Dimension = JSON.parse(config.value);
        dimensions.dimensions.push(valueObject);
      } catch (error) {
        console.error(
          `Error parsing JSON in KeyConfiguration with ID ${config.id}`
        );
      }
    }
    const viewConfig: ViewKeyConfiguration = {
      id: configs[0]?.id || 0,
      companyId: configs[0]?.companyId || 0,
      key: configs[0]?.key || "",
      value: dimensions,
    };
    return viewConfig;
  };

  const getKeyConfig = async () => {
    setLoading(true);
    let keyconfigList: KeyConfiguration[] = [];
    await getKeyConfigurationByCompanyId(tenant)
      .then((result) => {
        result.map((e: KeyConfiguration) => {
          if (e.key === "Visma-Dimensions") {
            let dimensionAry = JSON.parse(e.value);
            dimensionAry.Dimensions.forEach((e: Dimension) => {
              let config: KeyConfiguration = {
                companyId: tenant,
                id: 0,
                key: "Visma-Dimensions",
                value: JSON.stringify(e),
              };
              keyconfigList.push(config);
            });
          }
        });
        setKeyConfigList(keyconfigList);
        let x = mapToSingleViewKeyConfiguration(keyconfigList);
        setViewKeyConfig(x);
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const removeDimension = (e: any) => {
    setDeletingDimension(e);
    setDeleteAlertVisibility(true);
  };

  const setDimensionActionButtons = (rowData: any) => {
    return (
      <td className="text-end">
        <CustomTextPopup
          text="Edit Dimension"
          id="tenantAddUserButtonPopover"
          placement="left"
        >
          <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2">
            <img
              src={EditIcon}
              alt="add users"
              className="w18"
              onClick={() => editDimensionClickHandler(rowData)}
            />
          </button>
        </CustomTextPopup>
        <CustomTextPopup
          text="Remove Dimension"
          id="tenantAddUserButtonPopover"
          placement="bottom"
        >
          <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <img
              src={TrashIcon}
              alt="EditTenant"
              className="w18"
              onClick={() => removeDimension(rowData)}
            />
          </button>
        </CustomTextPopup>
      </td>
    );
  };

  useEffect(() => {
    mapViewKeyConfigToKeyConfigList();
  }, [mapViewKeyConfigToKeyConfigList]);

  const setAccountNumber = (rowData: Dimension) => {
    return rowData.AccountNumber ? rowData.AccountNumber : "N/A";
  };

  const setDimension = (rowData: Dimension) => {
    return rowData.Dimension ? rowData.Dimension : "N/A";
  };

  const setDimension2 = (rowData: Dimension) => {
    return rowData.Dimension2 ? rowData.Dimension2 : "N/A";
  };

  const setDimension3 = (rowData: Dimension) => {
    return rowData.Dimension3 ? rowData.Dimension3 : "N/A";
  };

  const addedDimensionsSection = (
    <div className="card mb-5 mb-xl-8 companyTable">
      <div className="card-body py-3">
        <div className="table-responsive">
          <DataTable
            value={viewKeyConfig?.value.dimensions}
            paginator
            lazy
            totalRecords={viewKeyConfig?.value.dimensions?.length}
            onPage={onPage}
            first={lazyParams.page - 1}
            rows={lazyParams.size}
            rowsPerPageOptions={[5, 10, 15]}
            dataKey=""
            rowHover
            loading={loading}
            responsiveLayout="scroll"
            emptyMessage="No companies found."
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          >
            {/* AccountNumber */}
            <Column
              field="AccountNumber"
              body={setAccountNumber}
              header="Account Number"
              className="text-dark min-w-120px"
            />
            {/* Dimension */}
            <Column
              field="Dimension"
              body={setDimension}
              header="Dimension"
              className="text-dark min-w-120px"
            />

            {/* Dimension 2 */}
            <Column
              field="Dimension2"
              body={setDimension2}
              header="Dimension 2"
              className="text-dark min-w-120px"
            />

            {/* Dimension 2 */}
            <Column
              field="Dimension3"
              body={setDimension3}
              header="Dimension 3"
              className="text-dark min-w-120px"
            />

            {/* action btn */}
            <Column
              header="Actions"
              headerStyle={{ width: "120px", textAlign: "center" }}
              className="text-dark min-w-120px"
              body={setDimensionActionButtons}
            />
          </DataTable>
          <Dialog
            visible={deleteAlertVisibility}
            style={{ width: "750px" }}
            modal
            className="p-fluid"
            onHide={hideDialog}
          >
            {OrganizationState && OrganizationState === null ? (
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="4"
                animationDuration=".5s"
              />
            ) : (
              <div className="alert alert-dismissible d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">
                <span className="svg-icon svg-icon-5tx svg-icon-danger mb-5">
                  <KTSVG
                    path="/media/icons/duotune/general/gen044.svg"
                    className="svg-icon-1"
                  />
                </span>

                <div className="text-center">
                  <h5 className="fw-bolder fs-1 mb-5">
                    Do you want to remove the Dimension?
                  </h5>

                  <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                  <div className="mb-9">
                    Removing the Dimension will result in Visma Enterprise sync
                    fail.
                  </div>
                  <div className="d-flex flex-center flex-wrap">
                    <a
                      href="#"
                      className="btn btn-outline btn-outline-danger btn-active-danger m-2"
                      onClick={hideDialog}
                    >
                      Cancel
                    </a>
                    <a
                      href="#"
                      className="btn btn-danger m-2"
                      onClick={() => removeDimensionClickHandler()}
                    >
                      Delete
                    </a>
                  </div>
                </div>
              </div>
            )}
          </Dialog>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    getKeyConfig();
  }, []);

  return (
    <Modal
      show={isOpen}
      onHide={toggleModal}
      dialogClassName="tenant-add-users-modal"
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton className="py-3">
        <h2>Add Dimensions</h2>
      </Modal.Header>
      <Modal.Body>
        {true ? (
          <div>
            <form autoComplete="off" id="addTeantUser" onSubmit={handleSubmit}>
              <div className="card-body d-flex justify-content-between">
                <div className="flex-side">
                  <div className="form-group mb-7">
                    <CustomTextPopup
                      text="Account number used in Vendolink."
                      id="dimensionLablePopover"
                      placement="right"
                    >
                      <label>Account Number:*</label>
                    </CustomTextPopup>
                    <input
                      id="AccountNumber"
                      type="text"
                      onChange={handleChange}
                      value={values.AccountNumber}
                      onBlur={handleBlur}
                      className="form-control form-control-solid mt-1"
                      placeholder="Enter account number"
                      autoComplete="new-name"
                    />
                    {errors.AccountNumber && touched.AccountNumber && (
                      <p className="text-danger">{errors.AccountNumber}</p>
                    )}
                  </div>
                  <div className="form-group mb-7">
                    <CustomTextPopup
                      text="Account Number used in VISMA Enterprise"
                      id="dimensionLablePopover"
                      placement="right"
                    >
                      <label>Dimension:*</label>
                    </CustomTextPopup>
                    <input
                      id="Dimension"
                      type="text"
                      onChange={handleChange}
                      value={values.Dimension}
                      onBlur={handleBlur}
                      className="form-control form-control-solid mt-1"
                      placeholder="Enter dimension"
                      autoComplete="new-name"
                    />
                    {errors.Dimension && touched.Dimension && (
                      <p className="text-danger">{errors.Dimension}</p>
                    )}
                  </div>
                </div>
                <div className="flex-side">
                  <div className="form-group mb-7">
                    <CustomTextPopup
                      text="Dimension 2 value associated with the Visma Enterprise ERP"
                      id="dimension2LablePopover"
                      placement="right"
                    >
                      <label>Dimension 2:*</label>
                    </CustomTextPopup>
                    <input
                      id="Dimension2"
                      type="text"
                      onChange={handleChange}
                      value={values.Dimension2}
                      className="form-control form-control-solid mt-1"
                      placeholder="Enter dimension 2"
                      onBlur={handleBlur}
                      autoComplete="new-email"
                    />
                    {errors.Dimension2 && touched.Dimension2 && (
                      <p className="text-danger">{errors.Dimension2}</p>
                    )}
                  </div>
                  <div className="form-group mb-7">
                    <CustomTextPopup
                      text="Dimension 3 value associated with the Visma Enterprise ERP"
                      id="dimension3LablePopover"
                      placement="right"
                    >
                      <label>Dimesnsion 3:*</label>
                    </CustomTextPopup>
                    <input
                      id="Dimension3"
                      type="text"
                      onChange={handleChange}
                      value={values.Dimension3}
                      onBlur={handleBlur}
                      className="form-control form-control-solid mt-1"
                      placeholder="Enter dimension 3"
                      autoComplete="new-password"
                    />
                    {errors.Dimension3 && touched.Dimension3 && (
                      <p className="text-danger">{errors.Dimension3}</p>
                    )}
                  </div>
                  <div style={{ marginTop: "37px" }}>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-primary me-3 py-3 ml-4 mt-4"
                        onClick={() => {
                          validateDimensionClickHandler(resetForm, values);
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="justify-content-cente">
            <div className="spinner-overlay">
              <div className="spinner-border m-5 text-secondary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        )}
        {keyConfigList && keyConfigList?.length! > 0 ? (
          addedDimensionsSection
        ) : (
          <></>
        )}
        <div className="d-flex justify-content-end">
          <button
            hidden={
              viewKeyConfig && viewKeyConfig?.value.dimensions.length! > 0
                ? false
                : true
            }
            type="button"
            className="btn btn-primary me-3 py-3 mt-4"
            onClick={() => {
              addDimensions(values);
            }}
          >
            Save
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddVismaDimensionsModal;
