import { PlatformTaskTable } from "../../../_metronic/partials/widgets";

function PlatformTask() {
  return (
    <div>
      <PlatformTaskTable className="mb-5 mb-xl-5" />
    </div>
  );
}

export default PlatformTask;
