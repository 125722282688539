/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { PlatformTaskStoresDTO } from "../../../../app/services/models/PlatformTaskModel";

type Props = {
  className: string;
  platformTaskStoreList: PlatformTaskStoresDTO[];
};

const PlatformTaskTriggerTable: React.FC<Props> = ({
  className,
  platformTaskStoreList,
}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-100px">Store Code</th>
                <th className="min-w-100px">Status</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {platformTaskStoreList &&
                platformTaskStoreList.map((store) => (
                  <tr key={store.storeCode}>
                    <td>
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                      >
                        {store.storeCode}
                      </a>
                    </td>
                    <td>
                      {store.active ? (
                        <span className="badge badge-light-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge badge-light-danger">
                          Inactive
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { PlatformTaskTriggerTable };
