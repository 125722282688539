import React from 'react'

const Mypage: React.FC = () => {
  return (
    <div>
      <h1>Lahiru</h1>
    </div>
  )
}

export {Mypage}
