import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from "primereact/api";

//types
import {
  CompanyNamesType,
  PlatformTaskHistoryType,
  PlatformTaskModalType,
  PlatformTaskNamesType,
} from "../../../../app/pages/platformTaskHistory/PlatformTaskHistoryType";

//services
import { getAllPlatformTaskHistoryFilters } from "../../../../app/services/PlatformTaskHistoryService";
import { getAllPlatformTaskNames } from "../../../../app/services/PlatformTaskService";

//styles
import "./styles/TaskExecutionHistoryTable.scss";
import { getAllOrganizationNames } from "../../../../app/services/CompanyService";
import { getTaskStatus } from "../../../../app/services/PlatformTaskStatusService";
import { TaskStatusModel } from "../../../../app/services/models/TaskStatusModel";
import { SourceNames } from "../../../../app/services/models/SourceModel";
import { DestinationNamesList } from "../../../../app/services/models/DestinationModel";
import { getAllSourcesNames } from "../../../../app/services/SourceService";
import { getAllDestionationNames } from "../../../../app/services/DestinationService";
import { getAllTasks } from "../../../../app/services/TaskService";
import { TaskTypeModel } from "../../../../app/services/models/TaskTypeModel";
import { KTSVG } from "../../../helpers";
import DateTimeRangePickerComponent from "../../modals/date-picker/DateTimeRangePickerComponent";
import { PlatformTaskHistoryParamTypesFilters } from "../../../../app/services/types/PlatformTaskHistoryParamTypes";
import ExecutionHistoryModal from "../../modals/execution-history/ExecutionHistoryModal";

const TaskExecutionHistoryTable = () => {
  let navigate = useNavigate();
  const [taskExecutionHistoryList, setPlatformTaskHistoryList] = useState<
    PlatformTaskHistoryType[]
  >([]);
  const [selectedTaskExecutionHistory, setSelectedTaskExecutionHistory] =
    useState<PlatformTaskModalType>({
      taskName: "",
      sourceName: "",
      destinationName: "",
      destinationData: "",
      taskStatus: undefined,
      storeCode: "",
      retryCount: 0,
      id: 0,
      platformTaskId: 0,
      executionDate: "",
      syncedDate: "",
      orgName: "",
      event: "",
      integrationModule: "",
      parameters: "",
      isManual: false,
    });
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [platformTask, setPlatformTask] = useState<PlatformTaskNamesType[]>([]);
  const [organizations, setOrganizations] = useState<CompanyNamesType[]>([]);
  const [taskStatusList, setTaskStatusList] = useState<TaskStatusModel[]>([]);
  const [sourcesList, setSourcesList] = useState<SourceNames[]>([]);
  const [taskTypesList, setTaskTypesList] = useState<TaskTypeModel[]>([]);
  const [destinationsList, setDestinationsList] = useState<
    DestinationNamesList[]
  >([]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [filterParams, setFilterParams] = useState({
    orgNo: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    executionDate: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    store:{
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    syncedDate: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    status: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    source: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    destination: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    }
  });
  const [loading, setLoading] = useState(true);
  const [transactionDate, setTransactionDate] = useState<any>(null);
  const [syncedDate, setSyncDate] = useState<any>(null);

  //API calls
  const getPlatformTaskHistory = () => {
    setLoading(true);
    let data: any = {};
    if (typeof filterParams.syncedDate.value === "string") {
      data = {
        pageNumber: lazyParams.page + 1,
        dataLimit: lazyParams.rows,
        orgNo: filterParams.orgNo.value,
        status: filterParams.status.value,
        store: filterParams.store.value,
        source: filterParams.source.value,
        destination: filterParams.destination.value,
        fromDate:
          (filterParams.syncedDate.value as string) !== ""
            ? (filterParams.syncedDate.value as string).split(",", 1)[0]
            : null,
        toDate:
          (filterParams.syncedDate.value as string) !== ""
            ? (filterParams.syncedDate.value as string).split(",", 2)[1]
            : null,
      };
      console.log(data);
    } else {
      data = {
        pageNumber: lazyParams.page + 1,
        dataLimit: lazyParams.rows,
        orgNo: filterParams.orgNo.value,
        status: filterParams.status.value,
        store: filterParams.store.value,
        source: filterParams.source.value,
        destination: filterParams.destination.value,
        fromDate: null,
        toDate: null,
      };
    }
    getAllPlatformTaskHistoryFilters(data)
      .then((result) => {
        setPlatformTaskHistoryList(result.platformTaskExecutionHistory);
        setTotalRecords(result.totalRecords);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const getPlatformTask = () => {
    getAllPlatformTaskNames()
      .then((result) => {
        setPlatformTask(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const getOrganizations = () => {
    getAllOrganizationNames()
      .then((result) => {
        setOrganizations(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const getTaskStatusList = () => {
    getTaskStatus()
      .then((result) => {
        setTaskStatusList(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const getSources = () => {
    getAllSourcesNames()
      .then((result) => {
        setSourcesList(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const getDestinations = () => {
    getAllDestionationNames()
      .then((result) => {
        setDestinationsList(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const getTasks = () => {
    getAllTasks()
      .then((result) => {
        setTaskTypesList(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const getAllData = () => {
    getPlatformTask();
    getOrganizations();
    getTaskStatusList();
    getSources();
    getDestinations();
    getTasks();
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    getPlatformTaskHistory();
  }, [lazyParams]);

  useEffect(() => {
    getPlatformTaskHistory();
  }, [filterParams]);

  const handleClick = (rowData: any) => {
    var selectedOrg: PlatformTaskModalType = {
      sourceName: setSourceName(rowData),
      destinationName: setDestinationName(rowData),
      destinationData: rowData.destinationData,
      taskName: setTaskName(rowData),
      taskStatus: rowData.statusId,
      storeCode: rowData.storeCode,
      retryCount: rowData.retryCount,
      id: rowData.id,
      platformTaskId: rowData.platformTaskId,
      executionDate: setTransDate(rowData),
      syncedDate: setSyncedDate(rowData),
      orgName: setOrgName(rowData),
      event: rowData.event,
      integrationModule: rowData.integrationModule,
      parameters: rowData.parameters,
      isManual: rowData.isManual,
    };
    setSelectedTaskExecutionHistory(selectedOrg);
  };

  //filtering
  const onFilter = (value: any) => {
    if (value !== undefined) {
      setFilterParams({
        orgNo: {
          value: value.filters.orgNo.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        executionDate: {
          value: value.filters.executionDate.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        store:{
          value: value.filters.store.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        syncedDate: {
          value: value.filters.executionDate.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        status: {
          value: value.filters.status.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        source: {
          value: value.filters.source.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        destination: {
          value: value.filters.destination.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
      });
    } else {
      setFilterParams({
        orgNo: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        executionDate: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        store:{
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        syncedDate: {
          value: value.filters.executionDate,
          matchMode: FilterMatchMode.CONTAINS,
        },
        status: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        source: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        destination: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      });
    }
  };

  //lazy loading
  const onPage = (event: any) => {
    console.log(event);
    var x: any = {
      first: event.first,
      page: event.page,
      rows: event.rows + event.rows,
    };
    setLazyParams(event);
  };

  //data formatting/ finding functions
  const findOrgName = (organisationNo: string) => {
    var organisation = organizations.find(
      ({ orgNo }) => organisationNo === orgNo
    );
    return organisation?.name;
  };

  const findTaskName = (platformTaskId: number) => {
    var task = platformTask.find(({ id }) => id === platformTaskId);
    var taskType = taskTypesList.find(({ id }) => id === task?.taskTypeId);
    var subStrCheck = taskType?.name.indexOf("event trigerred");
    if (subStrCheck !== -1) {
      return "Event Trigger";
    } else {
      return taskType?.name;
    }
  };

  const findStatus = (statusId: number) => {
    var status = taskStatusList.find(({ id }) => id === statusId);
    if (status?.id === 1) {
      return (
        <span className="badge badge-light-primary fs-7 fw-bold">Pending</span>
      );
    } else if (status?.id === 2) {
      return (
        <span className="badge badge-light-success fs-7 fw-bold">Success</span>
      );
    } else if (status?.id === 3) {
      return (
        <span className="badge badge-light-danger fs-7 fw-bold">Failed</span>
      );
    } else if (status?.id === 4) {
      return (
        <span className="badge badge-light-warning fs-7 fw-bold">Empty</span>
      );
    } else {
      return (
        <span className="text-muted fw-bold text-muted d-block fs-7">
          Undefined
        </span>
      );
    }
  };

  const findSourceName = (sourceId: number) => {
    if(sourceId == 0){
      return "N/A"
    }
    var source = sourcesList.find(({ id }) => id === sourceId);
    return source?.sourceName;
  };

  const findDestinationName = (destinationId: number) => {
    var destination = destinationsList.find( ({ id }) => id === destinationId);
    return destination?.destinationName;
  };

  const formatTransactionDate = (executionDate: string) => {
    return new Date(executionDate).toLocaleString("sv-SE");
  };

  const formatSyncedDate = (startedAt: string) => {
    return new Date(startedAt).toLocaleString("sv-SE");
  };

  const formatStoreName = (storeCode: string) => {
    let lastIndex = storeCode.lastIndexOf(':');
    if(lastIndex > 0){
        // Extract substring from start to last index of ':'
        return storeCode.substring(0, lastIndex );
    }else{
      return storeCode;
    }
   
  };

  //body setting functions
  const setOrgName = (rowData: any) => {
    return findOrgName(rowData.orgNo);
  };

  const setTaskName = (rowData: any) => {
    return findTaskName(rowData.platformTaskId);
  };

  const setStoreName = (rowData: any) => {
    return formatStoreName(rowData.storeCode);
  };

  //execution date === transaction date
  const setTransDate = (rowData: any) => {
    return formatTransactionDate(rowData.executionDate);
  };

  const setSyncedDate = (rowData: any) => {
    return formatSyncedDate(rowData.startedAt);
  };

  const setStatus = (rowData: any) => {
    return findStatus(rowData.statusId);
  };

  const setSourceName = (rowData: any) => {
    return findSourceName(rowData.sourceId);
  };

  const setDestinationName = (rowData: any) => {
    return findDestinationName(rowData.destinationId);
  };

  const setActionButton = (rowData: any) => {
    return (
      <a
        href="#"
        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 pe-auto"
        data-bs-toggle="modal"
        data-bs-target="#kt_modal_history_details"
        onClick={() => handleClick(rowData)}
      >
        <KTSVG
          path="/media/icons/duotune/general/gen004.svg"
          className="svg-icon-3"
        />
      </a>
    );
  };

  const taskStatuses = ["Pending", "Success", "Failed", "Empty"];

  const setFilterParamsStatusDropDown = async (e: any) => {
    let filterParamsCopy = { ...filterParams };
    if (e !== undefined) {
      filterParamsCopy["status"].value = e;
    } else {
      filterParamsCopy["status"].value = null;
    }
    await setFilterParams(filterParamsCopy);
  };

  const taskStatus = (e: any) => {
    if (e === "Pending") {
      return (
        <span className="badge badge-light-primary fs-7 fw-bold">Pending</span>
      );
    } else if (e === "Success") {
      return (
        <span className="badge badge-light-success fs-7 fw-bold">Success</span>
      );
    } else if (e === "Failed") {
      return (
        <span className="badge badge-light-danger fs-7 fw-bold">Failed</span>
      );
    } else if (e === "Empty") {
      return (
        <span className="badge badge-light-warning fs-7 fw-bold">Empty</span>
      );
    } else {
      return (
        <span className="text-muted fw-bold text-muted d-block fs-7">
          Undefined
        </span>
      );
    }
  };

  const taskStatusFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        options={taskStatuses}
        onChange={(e) => setFilterParamsStatusDropDown(e.value)}
        itemTemplate={taskStatus}
        className="p-column-filter"
        showClear
      />
    );
  };

  const syncedDateFilterTemplate = (options: any) => {
    return (
      <DateTimeRangePickerComponent
        onSelect={(e: any) => options.filterCallback(e.value, options.index)}
        value={options.value}
        execution={false}
        syncDate={setSyncDate}
        showClear
      />
    );
  };

  const setOrgFilter = async (e: any) => {
    let filterParamsCopy = { ...filterParams };
    if (e !== undefined) {
      filterParamsCopy["orgNo"].value = e;
    } else {
      filterParamsCopy["orgNo"].value = null;
    }
    await setFilterParams(filterParamsCopy);
  };

  const setSourceFilter = async (e: any) => {
    let filterParamsCopy = { ...filterParams };
    if (e !== undefined) {
      filterParamsCopy["source"].value = e;
    } else {
      filterParamsCopy["source"].value = null;
    }
    await setFilterParams(filterParamsCopy);
  };

  const setDestinationFilter = async (e: any) => {
    let filterParamsCopy = { ...filterParams };
    if (e !== undefined) {
      filterParamsCopy["destination"].value = e;
    } else {
      filterParamsCopy["destination"].value = null;
    }
    await setFilterParams(filterParamsCopy);
  };

  //templates
  const organizationItemTemplate = (option: any) => {
    return (
      <div className="p-multiselect-representative-option">
        <span className="image-text">{option.name}</span>
      </div>
    );
  };

  const sourceItemTemplate = (option: any) => {
    return (
      <div className="p-multiselect-representative-option">
        <span className="image-text">{option.sourceName}</span>
      </div>
    );
  };

  const destinationItemTemplate = (option: any) => {
    return (
      <div className="p-multiselect-representative-option">
        <span className="image-text">{option.destinationName}</span>
      </div>
    );
  };

  const orgNameFilterTemplate = (options: any) => {
    return (
      <Dropdown
        showClear
        value={
          typeof filterParams.orgNo.value === "object"
            ? Object(filterParams.orgNo.value)["value"]
            : filterParams.orgNo.value
        }
        options={organizations}
        onChange={(e) => setOrgFilter(e.value)}
        optionValue="orgNo"
        optionLabel="name"
        itemTemplate={organizationItemTemplate}
        placeholder="Select an organization"
        filter
      />
    );
  };

  const sourceFilterTemplate = (options: any) => {
    return (
      <Dropdown
        showClear
        value={
          typeof filterParams.source.value === "object"
            ? Object(filterParams.source.value)["value"]
            : filterParams.source.value
        }
        options={sourcesList}
        onChange={(e) => setSourceFilter(e.value)}
        optionValue="id"
        optionLabel="sourceName"
        itemTemplate={sourceItemTemplate}
        placeholder="Select a source"
        filter
      />
    );
  };

  const destinationFilterTemplate = (options: any) => {
    return (
      <Dropdown
        showClear
        value={
          typeof filterParams.destination.value === "object"
            ? Object(filterParams.destination.value)["value"]
            : filterParams.destination.value
        }
        options={destinationsList}
        onChange={(e) => setDestinationFilter(e.value)}
        optionValue="id"
        optionLabel="destinationName"
        itemTemplate={destinationItemTemplate}
        placeholder="Select a Destination"
        filter
      />
    );
  };

  useEffect(() => {
    let filterParamsCopy = { ...filterParams };
    filterParamsCopy.executionDate.value = transactionDate;
    filterParamsCopy.syncedDate.value = syncedDate;
    setFilterParams(filterParamsCopy);
    getPlatformTaskHistory();
  }, [syncedDate, transactionDate]);

  return (
    <div>
      <div className="card mb-5 mb-xl-8 taskExecutionHistoryTable">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">
              Task execution status
            </span>
            <span className="text-muted mt-1 fw-bold fs-7">
              {totalRecords} Records
            </span>
          </h3>
        </div>
        <div className="card-body py-3">
          <div className="table-responsive">
            <DataTable
              value={taskExecutionHistoryList}
              paginator
              lazy
              totalRecords={totalRecords}
              onPage={onPage}
              first={lazyParams.first}
              rows={lazyParams.rows}
              rowsPerPageOptions={[10, 25, 50]}
              dataKey="id"
              rowHover
              filterDisplay="row"
              loading={loading}
              responsiveLayout="scroll"
              emptyMessage="No history found."
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              filters={filterParams}
              onFilter={(e) => onFilter(e)}
            >
              {/* Organization */}
              <Column
                field="companyName"
                header="Organization"
                body={setOrgName}
                className="text-dark min-w-100px"
                filter
                filterElement={orgNameFilterTemplate}
                showFilterMenu={false}
                showClearButton={false}
              />

              {/* Task name */}
              <Column
                field="taskName"
                header="Task Type"
                body={setTaskName}
                className="text-dark min-w-125px"
              />

              {/* source */}
              <Column
                field="sourceName"
                header="Source"
                body={setSourceName}
                className="text-dark min-w-120px"
                filter
                showFilterMenu={false}
                filterElement={sourceFilterTemplate}
                showClearButton={false}
              />

              {/* destination */}
              <Column
                field="destinationName"
                header="Destination"
                body={setDestinationName}
                className="text-dark min-w-120px"
                filter={true}
                showFilterMenu={false}
                showClearButton={false}
                filterElement={destinationFilterTemplate}
              />

              {/* Store name */}
            <Column
              field="storeCode"
              header="Store"
              body={setStoreName}
              filter={true}
              className="text-dark min-w-125px"
              filterField = "store"
              showFilterMenu={false}
              showClearButton={false}
            />

            {/* synced date */}
            <Column
              field="startedAt"
              header="Executed At"
              dataType="date"
              className="text-dark min-w-200px"
              body={setSyncedDate}
              filter={true}
              showFilterMenu={false}
              filterMatchMode="custom"
              filterElement={syncedDateFilterTemplate}
            />

              {/* status */}
              <Column
                field="status"
                header="Status"
                className="text-dark min-w-120px"
                body={setStatus}
                filter={true}
                filterElement={taskStatusFilterTemplate}
                showFilterMenu={false}
                showClearButton={false}
              />

              {/* action btn */}
              <Column
                header="Action"
                headerStyle={{ width: "4rem", textAlign: "center" }}
                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                body={setActionButton}
              />
            </DataTable>
          </div>
        </div>
      </div>
      <ExecutionHistoryModal
        executionHistoryData={selectedTaskExecutionHistory}
      />
    </div>
  );
};

export default TaskExecutionHistoryTable;
