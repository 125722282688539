import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Navigate, Routes, useNavigate} from 'react-router-dom'
import * as auth from './redux/AuthRedux'

export function Logout() {
  const dispatch = useDispatch();
 const navigate = useNavigate();
  useEffect(() => {
    dispatch(auth.actions.logout());
    navigate('/auth');
  }, [dispatch])

  return (
    <div>
      Redirecting to Login
    </div>
  )
}
