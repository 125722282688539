import {
  CreatePlatformTaskDTO,
  TriggerPlatformTaskDTO,
  UpdatePlatformTaskDTO,
  UpdateTaskSubscriptionsDto,
} from "./models/PlatformTaskModel";
import { PlatformTaskParamTypes, PlatformTaskTableParamTypes } from "./types/PlatformTaskParamTypes";
import { request } from "../util/Request";

export async function getAllPlatformTasks() {
  const response = await request("/api/PlatformTask/GetAll");
  return await response.json();
}

export async function getAllPlatformTasksTable(data: PlatformTaskParamTypes) {
  var response = null;
  if (data.orgNo !== null) {
    response = await request(
      `/api/PlatformTask/GetAllPlatformTasks?pageNumber=${data.pageNumber}&dataLimit=${data.dataLimit}&orgNo=${data.orgNo}`
    );
  } else {
    response = await request(
      `/api/PlatformTask/GetAllPlatformTasks?pageNumber=${data.pageNumber}&dataLimit=${data.dataLimit}`
    );
  }
  return await response.json();
}

export async function getAllPlatformTasksTableFilter(
  data: PlatformTaskTableParamTypes
) {
  var response = null;
  if (
    data.taskName !== null ||
    data.companyName !== null ||
    data.integrationModuleName !== null ||
    data.sourceName !== null ||
    data.destinationName !== null ||
    data.eventName !== null ||
    data.isAllStore !== null ||
    data.executionDate !== null ||
    data.active !== null ||
    data.scheduleStatus !== null
  ) {
    response = await request(
      `/api/PlatformTask/GetAllPlatformTasksFilters?pageNumber=${data.pageNumber}&dataLimit=${data.dataLimit}&global=${null}&taskName=${data.taskName}&companyName=${data.companyName}&integrationModuleName=${data.integrationModuleName}&sourceName=${data.sourceName}&destinationName=${data.destinationName}&isAllStore=${data.isAllStore}&executionDate=${data.executionDate}&active=${data.active}&scheduleStatus=${data.scheduleStatus}`
    );
  } else {
    response = await request(
      `/api/PlatformTask/GetAllPlatformTasksFilters?pageNumber=${data.pageNumber}&dataLimit=${data.dataLimit}`
    );
  }
  return await response.json();
}

export async function getAllPlatformTaskNames() {
  const response = await request("/api/PlatformTask/GetAllNames");
  return await response.json();
}

export async function getPlatformTaskById(id: string) {
  const response = await request(`/api/PlatformTask/GetById?id=${id}`);
  return await response.json();
}

export async function createPlatformTask(data: CreatePlatformTaskDTO) {
  const response = await request(`/api/PlatformTask/Create`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ platformTask: data }),
  });
  return await response.json();
}

export async function updatePlatformTask(data: UpdatePlatformTaskDTO) {
  const response = await request(`/api/PlatformTask/Update`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ platformTask: data }),
  });
  return await response.json();
}

export async function deletePlatformTask(platformtaskId: string) {
  const response = await request(
    `/api/PlatformTask/Delete?platformtaskId=${platformtaskId}`,
    {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }
  );
  return await response.json();
}

export async function triggerPlatformEvent(
  triggerData: TriggerPlatformTaskDTO
) {
  const response = await request(`/api/PlatformEvent/Trigger`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ triggerPlatformTask: triggerData }),
  });
  return await response.json();
}

export async function getPlatformTaskSubscriptions(taskId: string) {
  const response = await request(`/api/Subscriptions/Task/${taskId}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return await response.json();
}

export async function updatePlatformTaskSubscriptions(taskId: string, data: UpdateTaskSubscriptionsDto) {
  const response = await request(`/api/Subscriptions/Task/${taskId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return await response.json();
}


