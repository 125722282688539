import { request } from "../util/Request";
import {
  CreateOrganizationDTO,
  UpdateOrganizationDTO,
} from "./models/CompanyModel";
import { OrganizationParamTypes } from "./types/OrganizationParamTypes";

export async function getAllOrganizations() {
  const response = await request("/api/Company/GetAll");
  console.log(response);
  return await response.json();
}

export async function getAllOrganizationsTable(data: OrganizationParamTypes) {
  var response = null;
  if (data.orgNo !== null) {
    response = await request(
      `/api/Company/GetAllCompanies?pageNumber=${data.pageNumber}&dataLimit=${data.dataLimit}&orgNo=${data.orgNo}`
    );
  } else {
    response = await request(
      `/api/Company/GetAllCompanies?pageNumber=${data.pageNumber}&dataLimit=${data.dataLimit}`
    );
  }
  console.log(response);
  return await response.json();
}

export async function getAllOrganizationsTableFilter(
  data: OrganizationParamTypes
) {
  var response = null;
  if (
    data.orgNo !== null ||
    data.name !== null ||
    data.address !== null ||
    data.active !== null
  ) {
    response = await request(
      `/api/Company/GetAllCompaniesFilter?pageNumber=${data.pageNumber}&dataLimit=${data.dataLimit}&orgNo=${data.orgNo}&name=${data.name}&address=${data.address}&active=${data.active}`
    );
    console.log(response);
  } else {
    response = await request(
      `/api/Company/GetAllCompaniesFilter?pageNumber=${data.pageNumber}&dataLimit=${data.dataLimit}`
    );
  }
  console.log(response);
  return await response.json();
}

export async function getOrganizationById(id: string) {
  const response = await request(`/api/Company/GetById?id=${id}`);
  console.log(response);
  return await response.json();
}

export async function getAllOrganizationNames() {
  const response = await request("/api/Company/GetAllNames");
  console.log(response);
  return await response.json();
}

export async function createOrganization(data: CreateOrganizationDTO) {
  const response = await request(`/api/Company/Create`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ company: data }),
  });
  return await response.json();
}

export async function deleteOrganization(companyId: string) {
  const response = await request(`/api/Company/Delete?companyId=${companyId}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  });
  return await response.json();
}

export async function updateOrganization(data: UpdateOrganizationDTO) {
  const response = await request(`/api/Company/Update`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ company: data }),
  });
  return await response.json();
}

export async function getOrganizationStores(companyId: string) {
  const response = await request(`/api/Company/${companyId}/Stores`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return await response.json();
}

