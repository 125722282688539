import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

interface Props {
  children: JSX.Element;
}

const PasswordLabelPopup = ({ children }: Props) => {
  return (
    <OverlayTrigger
      trigger="hover"
      key="passwordLabelPopup"
      placement="right"
      overlay={
        <Popover id={`popover-positioned-right`} style={{ maxWidth: "360px" }}>
          <Popover.Header as="h3">
            <strong>Password</strong>
          </Popover.Header>
          <Popover.Body>
            <ul>
              <li>MUST contain at least 8 characters (12+ recommended).</li>
              <li>MUST contain at least one uppercase letter.</li>
              <li>MUST contain at least one lowercase letter.</li>
              <li>MUST contain at least one number.</li>
              <li>
                MUST contain at least one special character{" "}
                {"(!”#$%&'()*+,-./:;<=>?@[]^_`{|}~ )"}
              </li>
            </ul>
          </Popover.Body>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default PasswordLabelPopup;
