import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import {
  createKeyConfigurations,
  getKeyConfigurationByCompanyId,
  updateKeyConfigurations,
} from "../../services/KeyConfigurationService";
import { KeyConfigurationDTO } from "../../services/models/KeyConfigurationModel";
import {
  IConfigDetails,
  configDetailsInitValues as initialValues,
  KeyConfiguration,
} from "./CompanyType";
import "./styles/CompanyConfigDetails.scss";
import Ajv from "ajv";
import AddVismaDimensionsModal from "../../../_metronic/partials/modals/add-visma-enterprise-dimensions/VismaEnterpriseDimensionModal";
import { useCookies } from "react-cookie";
import CryptoJS from "crypto-js";

var dimensionInnerSchema = {
  type: "object",
  properties: {
    AccountNumber: {
      type: "string",
    },
    Dimension: {
      type: "string",
    },
    Dimension2: {
      type: "string",
    },
    Dimension3: {
      type: "string",
    },
  },
  required: ["AccountNumber", "Dimension", "Dimension2", "Dimension3"],
};

var dimensionsArraySchema = {
  type: "array",
  items: dimensionInnerSchema,
  required: ["dimensionInnerSchema"],
};

var dimensionsSchema = {
  type: "object",
  properties: {
    Dimensions: dimensionsArraySchema,
  },
  required: ["Dimensions"],
};

const validationDimensionJson = (data: any) => {
  var schemaValidator = new Ajv();
  var testSchemaValidator = schemaValidator.compile(dimensionsSchema);
  try {
    var myobj = JSON.parse(data);
    var valid = testSchemaValidator(myobj);
    if (!valid) {
      return testSchemaValidator.errors;
    }
    return testSchemaValidator.errors;
  } catch (error) {
    var validerr = testSchemaValidator(myobj);
    if (!validerr) {
      return testSchemaValidator.errors;
    }
    return testSchemaValidator.errors;
  }
};

const configDetailsSchema = Yup.object().shape({
  shopifyToken: Yup.string()
    .notRequired()
    .test(
      "Shopify token test",
      "Shopify Access Token is required",
      function () {
        if (this.parent.shopifyGraphQLEndpoint && !this.parent.shopifyToken) {
          return false;
        } else return true;
      }
    ),
  shopifyGraphQLEndpoint: Yup.string()
    .notRequired()
    .test(
      "Shopify token test",
      "Shopify GraphQL Endpoint is required",
      function () {
        if (this.parent.shopifyToken && !this.parent.shopifyGraphQLEndpoint) {
          return false;
        } else return true;
      }
    ),
  xledgerToken: Yup.string(),
  vismaEACallbackURL: Yup.string(),
  vismaEAClientID: Yup.string(),
  vismaEAClientSecret: Yup.string(),
  vismaEARefreshToken: Yup.string(),
  vismaEAClientScopes: Yup.string(),
  vismaEEndpoint: Yup.string()
    .notRequired()
    .test(
      "Visma enterprise test",
      "Visma enterprise endpoint is required",
      function () {
        if (this.parent.vismaEUsername && !this.parent.vismaEEndpoint) {
          return false;
        } else return true;
      }
    ),
  vismaEUsername: Yup.string()
    .notRequired()
    .test(
      "Visma enterprise test",
      "Visma enterprise username is required",
      function () {
        if (
          (this.parent.vismaEPassword || this.parent.vismaEEndpoint) &&
          !this.parent.vismaEUsername
        ) {
          return false;
        } else return true;
      }
    ),
  vismaEPassword: Yup.string()
    .notRequired()
    .test(
      "Visma enterprise test",
      "Visma enterprise password is required",
      function () {
        if (
          (this.parent.vismaEUsername || this.parent.vismaEEndpoint) &&
          !this.parent.vismaEPassword
        ) {
          return false;
        } else return true;
      }
    ),
  vismaECompany: Yup.string()
    .notRequired()
    .test(
      "Visma Enterprise test",
      "Visma enterprise company is required",
      function () {
        if (
          (this.parent.vismaEEndpoint ||
            this.parent.vismaEUsername ||
            this.parent.vismaEPassword ||
            this.parent.vismaEVoucherType) &&
          !this.parent.vismaECompany
        ) {
          return false;
        } else return true;
      }
    ),
  vismaEVoucherType: Yup.string()
    .notRequired()
    .test(
      "Shopify token test",
      "Shopify enterprise voucher type is required",
      function () {
        if (
          (this.parent.vismaEEndpoint ||
            this.parent.vismaEUsername ||
            this.parent.vismaEPassword ||
            this.parent.vismaECompany) &&
          !this.parent.vismaEVoucherType
        ) {
          return false;
        } else return true;
      }
    ),
  vismaEUpdateVoucher: Yup.string(),
  vismaEDimensions: Yup.string()
    .notRequired()
    .test("Dimension Test", function () {
      let msg = validationDimensionJson(this.parent.vismaEDimensions);
      if (!msg || !this.parent.vismaEDimensions) {
        return true;
      } else {
        return this.createError({
          message: `${
            msg[0]?.message![0].toUpperCase() + msg[0]?.message?.slice(1)
          }`,
        });
      }
    }),
  vismaBusinessEndpoint: Yup.string()
    .notRequired()
    .test(
      "Visma Business test",
      "Visma Business endpoint is required",
      function () {
        if (
          this.parent.vismaBusinessUsername &&
          !this.parent.vismaBusinessEndpoint
        ) {
          return false;
        } else return true;
      }
    ),
  vismaBusinessUsername: Yup.string()
    .notRequired()
    .test(
      "Visma Business test",
      "Visma Business username is required",
      function () {
        if (
          (this.parent.vismaBusinessPassword ||
            this.parent.vismaBusinessEndpoint) &&
          !this.parent.vismaBusinessUsername
        ) {
          return false;
        } else return true;
      }
    ),
  vismaBusinessPassword: Yup.string()
    .notRequired()
    .test(
      "Visma Business test",
      "Visma Business password is required",
      function () {
        if (
          (this.parent.vismaBusinessUsername ||
            this.parent.vismaBusinessEndpoint) &&
          !this.parent.vismaBusinessPassword
        ) {
          return false;
        } else return true;
      }
    ),
  marketingCloudEndpoint: Yup.string()
    .notRequired()
    .test(
      "Marketing cloud test",
      "Marketing cloud endpoint is required",
      function () {
        if (
          this.parent.marketingCloudUsername &&
          !this.parent.marketingCloudEndpoint
        ) {
          return false;
        } else return true;
      }
    ),
  marketingCloudUsername: Yup.string()
    .notRequired()
    .test(
      "Marketing cloud test",
      "Marketing cloud username is required",
      function () {
        if (
          (this.parent.marketingCloudPassword ||
            this.parent.marketingCloudEndpoint) &&
          !this.parent.marketingCloudUsername
        ) {
          return false;
        } else return true;
      }
    ),
  marketingCloudPassword: Yup.string()
    .notRequired()
    .test(
      "Marketing cloud test",
      "Marketing cloud password is required",
      function () {
        if (
          (this.parent.marketingCloudUsername ||
            this.parent.marketingCloudEndpoint) &&
          !this.parent.marketingCloudPassword
        ) {
          return false;
        } else return true;
      }
    ),
  matricsEndpoint: Yup.string()
    .notRequired()
    .test("Matrics test", "Matrics endpoint is required", function () {
      if (this.parent.matricsApiUser && !this.parent.matricsEndpoint) {
        return false;
      } else return true;
    }),
  matricsApiUser: Yup.string()
    .notRequired()
    .test("Matrics test", "Matrics API User is required", function () {
      if (
        (this.parent.matricsApiKey || this.parent.matricsEndpoint) &&
        !this.parent.matricsApiUser
      ) {
        return false;
      } else return true;
    }),
  matricsApiKey: Yup.string()
    .notRequired()
    .test("Matrics test", "Matric API Key is required", function () {
      if (
        (this.parent.matricsApiUser || this.parent.matricsEndpoint) &&
        !this.parent.matricsApiKey
      ) {
        return false;
      } else return true;
    }),
  matricsBatchSize: Yup.string()
    .notRequired()
    .test("Matrics test", "Matric Batch Size is required", function () {
      if (
        (this.parent.matricsApiUser ||
          this.parent.matricsEndpoint ||
          this.parent.matricsApiKey) &&
        !this.parent.matricsBatchSize
      ) {
        return false;
      } else return true;
    }),
  matricsBrandTextFieldNo: Yup.string()
    .notRequired()
    .test(
      "Matrics test",
      "Matric Brand Text Field No is required",
      function () {
        if (
          (this.parent.matricsApiUser ||
            this.parent.matricsEndpoint ||
            this.parent.matricsApiKey ||
            this.parent.matricsRegionId ||
            this.parent.matricsBatchSize) &&
          !this.parent.matricsBrandTextFieldNo
        ) {
          return false;
        } else return true;
      }
    ),
  matricsTypeTextFieldNo: Yup.string()
    .notRequired()
    .test("Matrics test", "Matric Type Text Field No is required", function () {
      if (
        (this.parent.matricsApiUser ||
          this.parent.matricsEndpoint ||
          this.parent.matricsApiKey ||
          this.parent.matricsBatchSize ||
          this.parent.matricsBrandTextFieldNo) &&
        !this.parent.matricsTypeTextFieldNo
      ) {
        return false;
      } else return true;
    }),
});

interface LocationState {
  companyId: number;
  hasVismaEAConfigs: boolean;
  hasVismaEConfigs: boolean;
}

function CompanyConfigDetails() {
  const [cookies, setCookie] = useCookies(["companyId"]);
  const secretPass = process.env.REACT_APP_SECRET_PASS;
  const location = useLocation();
  const locState = location.state as LocationState;
  const companyId = locState?.companyId;
  const [companyIdFromCookie, setcompanyIdFromCookie] = useState<number>(
    cookies.companyId
      ? JSON.parse(
          CryptoJS.AES.decrypt(cookies.companyId, secretPass!).toString(
            CryptoJS.enc.Utf8
          )
        )
      : companyId
  );

  const [organization, setOrganization] = useState<number>(companyIdFromCookie);
  const [data, setData] = useState<IConfigDetails>(initialValues);
  const [keyConfig, setKeyConfig] = useState<KeyConfiguration[]>([]);
  const [loadingData, setloadingData] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState<boolean | null>(null);
  const [createConfig, setCreateConfig] = useState<boolean>(false);
  const [showAddDimensionModal, setShowAddDimensionModal] = useState(false);

  const formik = useFormik<IConfigDetails>({
    initialValues,
    validationSchema: configDetailsSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      debugger;
      console.log(values);
      setLoading(true);
      await updateConfigValuesDTO(values);
      setTimeout(() => {
        values.shopifyToken = data.shopifyToken;
        values.shopifyGraphQLEndpoint = data.shopifyGraphQLEndpoint;
        values.xledgerToken = data.xledgerToken;
        values.vismaEACallbackURL = data.vismaEACallbackURL;
        values.vismaEAClientID = data.vismaEAClientID;
        values.vismaEAClientSecret = data.vismaEAClientSecret;
        values.vismaEARefreshToken = data.vismaEARefreshToken;
        values.vismaEAClientScopes = data.vismaEAClientScopes;
        values.vismaEEndpoint = data.vismaEEndpoint;
        values.vismaEUsername = data.vismaEUsername;
        values.vismaEPassword = data.vismaEPassword;
        values.vismaECompany = data.vismaECompany;
        values.vismaEVoucherType = data.vismaEVoucherType;
        values.vismaEDimensions = data.vismaEDimensions;
        values.vismaBusinessEndpoint = data.vismaBusinessEndpoint;
        values.vismaBusinessUsername = data.vismaBusinessUsername;
        values.vismaBusinessPassword = data.vismaBusinessPassword;
        values.marketingCloudEndpoint = data.marketingCloudEndpoint;
        values.marketingCloudUsername = data.marketingCloudUsername;
        values.marketingCloudPassword = data.marketingCloudPassword;
        values.marketingCloudIsTestModeEnabled =
          data.marketingCloudIsTestModeEnabled;
        values.matricsEndpoint = data.matricsEndpoint;
        values.matricsApiUser = data.matricsApiUser;
        values.matricsApiKey = data.matricsApiKey;
        values.matricsRegionId = data.matricsRegionId;
        values.matricsBatchSize = data.matricsBatchSize;
        values.matricsBrandTextFieldNo = data.matricsBrandTextFieldNo;
        values.matricsTypeTextFieldNo = data.matricsTypeTextFieldNo;
        const updatedData = Object.assign(data, values);
        setData(updatedData);
        getConfiguration();
        setInitValues();
        setLoading(false);
      }, 3000);
    },
  });

  const getConfiguration = useCallback(() => {
    getKeyConfigurationByCompanyId(organization)
      .then((result) => {
        if (result.length > 0) {
          setKeyConfig(result);
        } else {
          setCreateConfig(true);
        }
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  }, [organization]);

  const setInitValues = useCallback(() => {
    setloadingData(false);
    let initValues: IConfigDetails = {
      shopifyToken: "",
      shopifyGraphQLEndpoint: "",
      xledgerToken: "",
      vismaEACallbackURL: "",
      vismaEAClientID: "",
      vismaEAClientSecret: "",
      vismaEARefreshToken: "",
      vismaEAClientScopes: "",
      vismaEEndpoint: "",
      vismaEUsername: "",
      vismaEPassword: "",
      vismaECompany: "",
      vismaEVoucherType: "",
      vismaEUpdateVoucher: "",
      vismaEDimensions: "",
      vismaBusinessEndpoint: "",
      vismaBusinessUsername: "",
      vismaBusinessPassword: "",
      marketingCloudEndpoint: "",
      marketingCloudUsername: "",
      marketingCloudPassword: "",
      marketingCloudIsTestModeEnabled: "",
      matricsEndpoint: "",
      matricsApiUser: "",
      matricsApiKey: "",
      matricsRegionId: "",
      matricsBatchSize: "",
      matricsBrandTextFieldNo: "",
      matricsTypeTextFieldNo: "",
    };
    let keyConfigStateCopy: KeyConfiguration[] = [...keyConfig!];
    if (keyConfigStateCopy.length > 0) {
      keyConfigStateCopy?.map((config, key) => {
        if (config.key === "X-Shopify-Access-Token") {
          initValues.shopifyToken = config.value;
        } else if (config.key === "Shopify-GraphQL-Endpoint") {
          initValues.shopifyGraphQLEndpoint = config.value;
        } else if (config.key === "XLedger-Access-Token") {
          initValues.xledgerToken = config.value;
        } else if (config.key === "Visma-EA-Callback-Url") {
          initValues.vismaEACallbackURL = config.value;
        } else if (config.key === "Visma-EA-Client-Id") {
          initValues.vismaEAClientID = config.value;
        } else if (config.key === "Visma-EA-Client-Secret") {
          initValues.vismaEAClientSecret = config.value;
        } else if (config.key === "Visma-EA-Refresh-Token") {
          initValues.vismaEARefreshToken = config.value;
        } else if (config.key === "Visma-EA-Client-Scopes") {
          initValues.vismaEAClientScopes = config.value;
        } else if (config.key === "Visma-Endpoint") {
          initValues.vismaEEndpoint = config.value;
        } else if (config.key === "Visma-Username") {
          initValues.vismaEUsername = config.value;
        } else if (config.key === "Visma-Password") {
          initValues.vismaEPassword = config.value;
        } else if (config.key === "Visma-Company") {
          initValues.vismaECompany = config.value;
        } else if (config.key === "Visma-VoucherType") {
          initValues.vismaEVoucherType = config.value;
        } else if (config.key === "Visma-UpdateVoucher") {
          initValues.vismaEUpdateVoucher = config.value;
        } else if (config.key === "Visma-Dimensions") {
          initValues.vismaEDimensions = config.value;
        } else if (config.key === "Visma-Business-Url") {
          initValues.vismaBusinessEndpoint = config.value;
        } else if (config.key === "Visma-Business-UserName") {
          initValues.vismaBusinessUsername = config.value;
        } else if (config.key === "Visma-Business-Password") {
          initValues.vismaBusinessPassword = config.value;
        } else if (config.key === "Marketing-Cloud-Url") {
          initValues.marketingCloudEndpoint = config.value;
        } else if (config.key === "Marketing-Cloud-UserName") {
          initValues.marketingCloudUsername = config.value;
        } else if (config.key === "Marketing-Cloud-Password") {
          initValues.marketingCloudPassword = config.value;
        } else if (config.key === "Marketing-Cloud-Is-Test-Mode-Enabled") {
          initValues.marketingCloudIsTestModeEnabled = config.value;
        } else if (config.key === "Matrics-Endpoint") {
          initValues.matricsEndpoint = config.value;
        } else if (config.key === "Matrics-Api-User") {
          initValues.matricsApiUser = config.value;
        } else if (config.key === "Matrics-Api-Key") {
          initValues.matricsApiKey = config.value;
        } else if (config.key === "Matrics-Region-Id") {
          initValues.matricsRegionId = config.value;
        } else if (config.key === "Matrics-Batch-Size") {
          initValues.matricsBatchSize = config.value;
        } else if (config.key === "Matrics-Brand-Field-No") {
          initValues.matricsBrandTextFieldNo = config.value;
        } else if (config.key === "Matrics-Type-Field-No") {
          initValues.matricsTypeTextFieldNo = config.value;
        }
        return undefined;
      });
      formik.setValues(initValues);
    } else {
      formik.setValues({
        shopifyToken: "",
        shopifyGraphQLEndpoint: "",
        xledgerToken: "",
        vismaEACallbackURL: "",
        vismaEAClientID: "",
        vismaEAClientSecret: "",
        vismaEARefreshToken: "",
        vismaEAClientScopes: "",
        vismaEEndpoint: "",
        vismaEUsername: "",
        vismaEPassword: "",
        vismaECompany: "",
        vismaEVoucherType: "",
        vismaEUpdateVoucher: "",
        vismaEDimensions: "",
        vismaBusinessEndpoint: "",
        vismaBusinessUsername: "",
        vismaBusinessPassword: "",
        marketingCloudEndpoint: "",
        marketingCloudUsername: "",
        marketingCloudPassword: "",
        marketingCloudIsTestModeEnabled: "",
        matricsEndpoint: "",
        matricsApiUser: "",
        matricsApiKey: "",
        matricsRegionId: "",
        matricsBatchSize: "",
        matricsBrandTextFieldNo: "",
        matricsTypeTextFieldNo: "",
      });
    }
    setTimeout(() => {
      setloadingData(true);
    }, 3000);
  }, [keyConfig]);

  const updateConfigValuesDTO = async (updatedConfig: IConfigDetails) => {
    setloadingData(true);
    const updateConfigDTO: KeyConfiguration[] = [];
    Object.entries(updatedConfig).forEach((entry) => {
      const [key, value] = entry;
      if (key === "shopifyToken") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "X-Shopify-Access-Token",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "shopifyGraphQLEndpoint") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Shopify-GraphQL-Endpoint",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "xledgerToken") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "XLedger-Access-Token",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaEACallbackURL") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-EA-Callback-Url",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaEAClientID") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-EA-Client-Id",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaEAClientSecret") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-EA-Client-Secret",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaEARefreshToken") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-EA-Refresh-Token",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaEAVoucherEndpoint") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-EA-Voucher-Endpoint",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaEATokenURL") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-EA-Token-Url",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaEAClientScopes") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-EA-Client-Scopes",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaEEndpoint") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-Endpoint",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaEUsername") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-Username",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaEPassword") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-Password",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaECompany") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-Company",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaEVoucherType") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-VoucherType",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaEUpdateVoucher") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-UpdateVoucher",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaEDimensions") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-Dimensions",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaBusinessEndpoint") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-Business-Url",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaBusinessUsername") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-Business-UserName",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "vismaBusinessPassword") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Visma-Business-Password",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "marketingCloudEndpoint") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Marketing-Cloud-Url",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "marketingCloudUsername") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Marketing-Cloud-UserName",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "marketingCloudPassword") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Marketing-Cloud-Password",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "marketingCloudIsTestModeEnabled") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Marketing-Cloud-Is-Test-Mode-Enabled",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "matricsEndpoint") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Matrics-Endpoint",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "matricsApiUser") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Matrics-Api-User",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "matricsApiKey") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Matrics-Api-Key",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "matricsRegionId") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Matrics-Region-Id",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "matricsBatchSize") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Matrics-Batch-Size",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "matricsBrandTextFieldNo") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Matrics-Brand-Field-No",
          value: value,
        };
        updateConfigDTO.push(config);
      } else if (key === "matricsTypeTextFieldNo") {
        const config: KeyConfiguration = {
          id: 0,
          companyId: organization,
          key: "Matrics-Type-Field-No",
          value: value,
        };
        updateConfigDTO.push(config);
      }
    });
    const dataDTO: KeyConfigurationDTO = {
      keyConfigurations: updateConfigDTO,
    };
    if (createConfig) {
      await createKeyConfig(dataDTO);
    } else {
      await updateConfigValues(dataDTO);
    }
    getConfiguration();
    setloadingData(false);
  };

  const updateConfigValues = async (configDTO: KeyConfigurationDTO) => {
    var result = await updateKeyConfigurations(configDTO);
    if (result === 1) {
      setSubmitError(true);
    }
  };

  const createKeyConfig = async (configDTO: KeyConfigurationDTO) => {
    var result = await createKeyConfigurations(configDTO);
    if (result === 1) {
      setSubmitError(true);
    }
  };

  const dimensionButtonName = () => {
    let length: number = 0;
    keyConfig.map((config, key) => {
      if (config.key === "Visma-Dimensions" && config.value) {
        let x = JSON.parse(config.value);
        length = x.Dimensions.length;
      }
    });
    return length;
  };

  const errorMessage = () => {
    return <div>Error adding Configurations!</div>;
  };

  const toggleAddDimensionModal = () => {
    setShowAddDimensionModal((prevState: boolean) => !prevState);
  };

  useEffect(() => {
    getConfiguration();
  }, [getConfiguration, organization, showAddDimensionModal]);

  useEffect(() => {
    setInitValues();
  }, [setInitValues, organization]);

  return (
    <div className="card mb-5 mb-xl-10 companyConfigDetailsPage">
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Configuration Details</h3>
          <div
            className="fw-bold fs-3 text-danger"
            hidden={!submitError ? true : false}
          >
            {submitError && errorMessage()}
          </div>
        </div>
      </div>
      <div id="kt_account_profile_details" className="collapse show">
        <form
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          noValidate
          className="form"
        >
          <div className="card-body border-top p-9 pe-none">
            <div className="overlay overlay-block rounded px-3">
              <div className="vwp-config-group">
                <h4 className="sectionHeader">Shopify</h4>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    Shopify Token
                  </label>

                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Shopify Token"
                      {...formik.getFieldProps("shopifyToken")}
                    />
                    {formik.touched.shopifyToken && formik.errors.shopifyToken && (
                      <div className="fv-plugins-message-container pe-auto">
                        <div className="fv-help-block text-danger">
                          {formik.errors.shopifyToken}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Shopify GraphQL Endpoint</span>
                  </label>

                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Shopify GraphQL Endpoint"
                      {...formik.getFieldProps("shopifyGraphQLEndpoint")}
                    />
                    {formik.touched.shopifyGraphQLEndpoint &&
                      formik.errors.shopifyGraphQLEndpoint && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.shopifyGraphQLEndpoint}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="vwp-config-group">
                <h4 className="sectionHeader">XLedger</h4>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto pe-auto">
                    <span className="">XLedger Token</span>
                  </label>

                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="XLedger Token"
                      {...formik.getFieldProps("xledgerToken")}
                    />
                    {formik.touched.xledgerToken && formik.errors.xledgerToken && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block text-danger">
                          {formik.errors.xledgerToken}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="vwp-config-group">
                <h4 className="sectionHeader">Visma EAccounting</h4>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto pe-auto">
                    <span className="">Visma EA callback URL</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Visma EA callback URL"
                      {...formik.getFieldProps("vismaEACallbackURL")}
                    />
                    {formik.touched.vismaEACallbackURL &&
                      formik.errors.vismaEACallbackURL && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.vismaEACallbackURL}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Visma EA Client ID</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Visma EA Client ID"
                      {...formik.getFieldProps("vismaEAClientID")}
                    />
                    {formik.touched.vismaEAClientID &&
                      formik.errors.vismaEAClientID && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.vismaEAClientID}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Visma EA Client Secret</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Visma EA Client Secret"
                      {...formik.getFieldProps("vismaEAClientSecret")}
                    />
                    {formik.touched.vismaEAClientSecret &&
                      formik.errors.vismaEAClientSecret && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.vismaEAClientSecret}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Visma EA Refresh Token</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Visma EA Refresh Token"
                      {...formik.getFieldProps("vismaEARefreshToken")}
                    />
                    {formik.touched.vismaEARefreshToken &&
                      formik.errors.vismaEARefreshToken && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.vismaEARefreshToken}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Visma EA Client Scopes</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Visma EA Client Scopes"
                      {...formik.getFieldProps("vismaEAClientScopes")}
                    />
                    {formik.touched.vismaEAClientScopes &&
                      formik.errors.vismaEAClientScopes && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.vismaEAClientScopes}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div className="vwp-config-group">
                <h4 className="sectionHeader">Visma Enterprise</h4>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto pe-auto">
                    <span className="">Voucher Endpoint</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Visma Endpoint"
                      {...formik.getFieldProps("vismaEEndpoint")}
                    />
                    {formik.touched.vismaEEndpoint &&
                      formik.errors.vismaEEndpoint && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.vismaEEndpoint}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Username</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Visma Username"
                      {...formik.getFieldProps("vismaEUsername")}
                    />
                    {formik.touched.vismaEUsername &&
                      formik.errors.vismaEUsername && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.vismaEUsername}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Password</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Visma Password"
                      {...formik.getFieldProps("vismaEPassword")}
                    />
                    {formik.touched.vismaEPassword &&
                      formik.errors.vismaEPassword && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.vismaEPassword}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Company</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Visma Company"
                      {...formik.getFieldProps("vismaECompany")}
                    />
                    {formik.touched.vismaECompany &&
                      formik.errors.vismaECompany && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.vismaECompany}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Voucher Type</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Visma Voucher Type"
                      {...formik.getFieldProps("vismaEVoucherType")}
                    />
                    {formik.touched.vismaEVoucherType &&
                      formik.errors.vismaEVoucherType && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.vismaEVoucherType}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Update Voucher</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="false"
                      {...formik.getFieldProps("vismaEUpdateVoucher")}
                    />
                    {formik.touched.vismaEUpdateVoucher &&
                      formik.errors.vismaEUpdateVoucher && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.vismaEUpdateVoucher}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Dimensions</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder={`{"Dimensions":[{"Dimension":"2100076341","Dimension2":"350032","Dimension3":"46132"},...]}`.toString()}
                      {...formik.getFieldProps("vismaEDimensions")}
                    />
                    {formik.touched.vismaEDimensions &&
                      formik.errors.vismaEDimensions && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.vismaEDimensions}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="d-flex justify-content-end col-lg-3 fv-row">
                    <a
                      className="btn btn-primary pe-auto"
                      type="button"
                      onClick={() => {
                        toggleAddDimensionModal();
                        formik.submitForm();
                      }}
                    >
                      {dimensionButtonName() === 0 ? "Add" : "Edit"}
                    </a>
                  </div>
                  {organization && (
                    <AddVismaDimensionsModal
                      organization={organization.toString()}
                      isOpen={showAddDimensionModal}
                      toggleModal={toggleAddDimensionModal}
                    />
                  )}
                </div>
              </div>
              <div className="vwp-config-group">
                <h4 className="sectionHeader">Visma Business</h4>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto pe-auto">
                    <span className="">Base URL</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="API URL"
                      {...formik.getFieldProps("vismaBusinessEndpoint")}
                    />
                    {formik.touched.vismaBusinessEndpoint &&
                      formik.errors.vismaBusinessEndpoint && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.vismaBusinessEndpoint}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Username</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Visma Business Username"
                      {...formik.getFieldProps("vismaBusinessUsername")}
                    />
                    {formik.touched.vismaBusinessUsername &&
                      formik.errors.vismaBusinessUsername && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.vismaBusinessUsername}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Password</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Visma Password"
                      {...formik.getFieldProps("vismaBusinessPassword")}
                    />
                    {formik.touched.vismaBusinessPassword &&
                      formik.errors.vismaBusinessPassword && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.vismaBusinessPassword}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="vwp-config-group">
                <h4 className="sectionHeader">Matrics</h4>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto pe-auto">
                    <span className="">Matrics Endpoint</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Matrics Endpoint"
                      {...formik.getFieldProps("matricsEndpoint")}
                    />
                    {formik.touched.matricsEndpoint &&
                      formik.errors.matricsEndpoint && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.matricsEndpoint}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Matrics API User</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Matrics API User"
                      {...formik.getFieldProps("matricsApiUser")}
                    />
                    {formik.touched.matricsApiUser &&
                      formik.errors.matricsApiUser && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.matricsApiUser}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Matrics API Key</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Matrics API Key"
                      {...formik.getFieldProps("matricsApiKey")}
                    />
                    {formik.touched.matricsApiKey &&
                      formik.errors.matricsApiKey && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.matricsApiKey}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Matrics Region Id</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Matrics Region Id"
                      {...formik.getFieldProps("matricsRegionId")}
                    />
                    {formik.touched.matricsRegionId &&
                      formik.errors.matricsRegionId && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.matricsRegionId}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Matrics Batch Size</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Matrics Batch Size"
                      {...formik.getFieldProps("matricsBatchSize")}
                    />
                    {formik.touched.matricsBatchSize &&
                      formik.errors.matricsBatchSize && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.matricsBatchSize}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Brand Text Field No</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Brand Text Field No ex: 1,2"
                      {...formik.getFieldProps("matricsBrandTextFieldNo")}
                    />
                    {formik.touched.matricsBrandTextFieldNo &&
                      formik.errors.matricsBrandTextFieldNo && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.matricsBrandTextFieldNo}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Type Text Field No</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Type Text Field No ex:1,2"
                      {...formik.getFieldProps("matricsTypeTextFieldNo")}
                    />
                    {formik.touched.matricsTypeTextFieldNo &&
                      formik.errors.matricsTypeTextFieldNo && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.matricsTypeTextFieldNo}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="vwp-config-group">
                <h4 className="sectionHeader">Marketing Cloud</h4>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto pe-auto">
                    <span className="">Base URL</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="API URL"
                      {...formik.getFieldProps("marketingCloudEndpoint")}
                    />
                    {formik.touched.marketingCloudEndpoint &&
                      formik.errors.marketingCloudEndpoint && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.marketingCloudEndpoint}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Username</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Marketing cloud Username"
                      {...formik.getFieldProps("marketingCloudUsername")}
                    />
                    {formik.touched.marketingCloudUsername &&
                      formik.errors.marketingCloudUsername && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.marketingCloudUsername}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Password</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="Marketing cloud Password"
                      {...formik.getFieldProps("marketingCloudPassword")}
                    />
                    {formik.touched.marketingCloudPassword &&
                      formik.errors.marketingCloudPassword && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.marketingCloudPassword}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mb-3 pe-none vwp-each-config">
                  <label className="col-lg-3 col-form-label fw-bold fs-6 pe-auto">
                    <span className="">Is Test Mode Enabled</span>
                  </label>
                  <div className="col-lg-6 fv-row pe-none">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid pe-auto"
                      placeholder="true/false"
                      {...formik.getFieldProps(
                        "marketingCloudIsTestModeEnabled"
                      )}
                    />
                    {formik.touched.marketingCloudIsTestModeEnabled &&
                      formik.errors.marketingCloudIsTestModeEnabled && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                            {formik.errors.marketingCloudIsTestModeEnabled}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div
                className="overlay-layer rounded bg-dark bg-opacity-5"
                hidden={loadingData}
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {!loading && "Save Changes"}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CompanyConfigDetails;
