/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../../helpers";
import { Formik, Form, FormikValues, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { StepperComponent } from "../../../assets/ts/components";
import IntegrationModule from "../../../../app/pages/commonTypes/IntegrationModuleType";
import { getAllIntegrationModules } from "../../../../app/services/IntegrationModuleService";
import { createOrganization } from "../../../../app/services/CompanyService";
import { CreateOrganizationDTO } from "../../../../app/services/models/CompanyModel";
import { SelectedItemContext } from "../../../layout/MasterLayout";
import { CompanyIntegrationModulesTable } from "../../widgets/tables/CompanyIntegrationModulesTable";
import { IntegrationModuleDTO } from "../../../../app/services/models/IntegrationModuleModel";

interface ICreateOrganizationData {
  orgName: string;
  orgNumber: string;
  orgAddress: string;
  orgStatus: boolean;
  integrationModuleId: string;
  integratonModuleStatus: boolean;
  integrationModules: IntegrationModules[];
}

interface IntegrationModules {
  integrationModuleId: number;
  active: boolean;
}

interface DisplayIntegrationModules {
  name: string;
  status: boolean;
}

const inits: ICreateOrganizationData = {
  orgName: "",
  orgNumber: "",
  orgAddress: "",
  orgStatus: true,
  integrationModuleId: "",
  integratonModuleStatus: false,
  integrationModules: [],
};

const createAppSchema = [
  Yup.object({
    orgName: Yup.string().required().label("Organization name"),
    orgNumber: Yup.string().required().label("Organization number"),
    orgAddress: Yup.string().required().label("Organization address"),
  }),
  Yup.object({
    integrationModuleId: Yup.string().test(
      "Integration Module",
      "Integration Module is a rquired field",
      function () {
        if (this.parent.integrationModules.length <= 0) {
          if (!this.parent.integrationModuleId) {
            return false;
          } else {
            return true;
          }
        } else return true;
      }
    ),
    integrationModules: Yup.array()
      .min(1)
      .required("")
      .label("Organization requires at least one Integration Module"),
  }),
];

const MainOrganization: FC = () => {
  const selectedItemContext = useContext(SelectedItemContext);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(createAppSchema[0]);
  const [initValues] = useState<ICreateOrganizationData>(inits);
  const ref = useRef<any>(null);
  const [integrationModules, setIntegrationModules] = useState<
    IntegrationModule[]
  >([]);

  const [selectedIntegrationModules, setSelectedIntegrationModules] = useState<
    IntegrationModuleDTO[]
  >([]);

  const [submitError, setSubmitError] = useState<boolean | null>(null);
  const [submitSuccess, setsubmitSuccess] = useState<boolean | null>(null);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex - 1]);
  };

  const submitStep = (
    values: ICreateOrganizationData,
    actions: FormikValues
  ) => {
    if (!stepper.current) {
      return;
    }

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex]);

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext();
      //displayStepData();
    } else {
      stepper.current.goto(1);
      actions.resetForm({
        values: {
          orgName: "",
          orgNumber: "",
          orgAddress: "",
          orgStatus: true,
          integrationModuleId: "",
          integratonModuleStatus: true,
        },
      });
      onSumbit(values, actions);
    }
  };

  const onSumbit = async (
    values: ICreateOrganizationData,
    actions: FormikValues
  ) => {
    let selectedModules: IntegrationModules[] = [];
    selectedIntegrationModules.forEach((module) => {
      let integrationModule: IntegrationModules = {
        integrationModuleId: module.integrationModuleId,
        active: module.active,
      };
      selectedModules.push(integrationModule);
    });
    const data: CreateOrganizationDTO = {
      name: values.orgName,
      orgNo: values.orgNumber,
      address: values.orgAddress,
      active: values.orgStatus,
      integrationModules: selectedModules,
    };
    addOrganization(data);
  };

  const integrationModuleName = (modules: IntegrationModules[]) => {
    let activeModuleNameList: string[] = [];
    modules.forEach((e) => {
      integrationModules.map((module) => {
        if (module.id === e.integrationModuleId) {
          let m: DisplayIntegrationModules = {
            name: module.name,
            status: e.active,
          };
          if (m.status) {
            activeModuleNameList.push(m.name);
          }
        }
      });
    });
    return activeModuleNameList;
  };

  const inactiveIntegrationModuleName = (modules: IntegrationModules[]) => {
    let inactiveModuleNameList: string[] = [];
    modules.forEach((e) => {
      integrationModules.map((module) => {
        if (module.id === e.integrationModuleId) {
          let m: DisplayIntegrationModules = {
            name: module.name,
            status: e.active,
          };
          if (!m.status) {
            inactiveModuleNameList.push(m.name);
          }
        }
      });
    });
    return inactiveModuleNameList;
  };

  const displaySummary = () => {
    if (
      stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber
    ) {
      return (
        <div>
          <ul>Name: {ref.current && ref.current.values.orgName}</ul>
          <ul>Number: {ref.current && ref.current.values.orgNumber}</ul>
          <ul>Address: {ref.current && ref.current.values.orgAddress}</ul>
          <ul>
            Status:{" "}
            {ref.current && ref.current.values.orgStatus
              ? "Active"
              : "Inactive"}
          </ul>
          <ul>
            Active Modules:{" "}
            {ref.current &&
            integrationModuleName(
              ref.current.values.integrationModules
            ).toString()
              ? integrationModuleName(
                  ref.current.values.integrationModules
                ).toString()
              : "N/A"}
          </ul>
          <ul>
            Inactive Modules:{" "}
            {ref.current &&
            inactiveIntegrationModuleName(
              ref.current.values.integrationModules
            ).toString()
              ? inactiveIntegrationModuleName(
                  ref.current.values.integrationModules
                ).toString()
              : "N/A"}
          </ul>
        </div>
      );
    }
  };

  const getIntegrationModules = () => {
    getAllIntegrationModules()
      .then((result) => {
        setIntegrationModules(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const addOrganization = async (organization: CreateOrganizationDTO) => {
    const res = await createOrganization(organization);
    if (typeof res !== "number") {
      setSubmitError(true);
      setTimeout(() => {
        setSubmitError(false);
      }, 5000);
    } else {
      setsubmitSuccess(true);
      setTimeout(() => {
        setsubmitSuccess(false);
      }, 5000);
    }
  };

  const errorMessage = () => {
    return <div>Error adding the organization!</div>;
  };

  const successMessage = () => {
    return <div>Successfully added the organization!</div>;
  };

  const closeModal = () => {
    selectedItemContext.setSelectedItem?.("0");
  };

  const removeIntegrationModuleClickHandler = (integrationModuleId: number) => {
    setSelectedIntegrationModules(
      selectedIntegrationModules.filter(
        (module) => module.integrationModuleId !== integrationModuleId
      )
    );
  };

  useEffect(() => {
    getIntegrationModules();
    if (!stepperRef.current) {
      return;
    }
    loadStepper();
  }, [stepperRef]);

  const setStores = (values: any, setFieldValue: any) => {
    values.integrationModules = selectedIntegrationModules;
  };

  const addStoreClickHandler = (resetForm: any, values: any) => {
    let imStateCopy: IntegrationModule[] = [...integrationModules];
    imStateCopy.forEach((module) => {
      if (module.id === parseInt(values.integrationModuleId)) {
        let newIntegrationModule: IntegrationModuleDTO = {
          name: module.name,
          active: values.integratonModuleStatus,
          integrationModuleId: parseInt(values.integrationModuleId),
        };
        setSelectedIntegrationModules((modules) => {
          if (
            !modules.some(
              (module) =>
                module.integrationModuleId ===
                newIntegrationModule.integrationModuleId
            )
          ) {
            return [...modules, newIntegrationModule];
          }
          return modules;
        });
        values.integrationModules = selectedIntegrationModules;
        resetForm({
          values: {
            ...values,
            integrationModuleId: "",
            integratonModuleStatus: false,
          },
        });
      }
    });
  };

  function trimByChar(string: string, character: string) {
    const arr = Array.from(string);
    const first = arr.findIndex((char) => char !== character);
    const last = arr.reverse().findIndex((char) => char !== character);
    return first === -1 && last === -1
      ? ""
      : string.substring(first, string.length - last);
  }

  const setResult = (result: string) => {
    if (result !== undefined) {
      let pretty = result;
      if (result != null) {
        result = trimByChar(result, '"');
        try {
          const obj = JSON.parse(result.replace(/\\/g, ""));
          if (obj === null) {
            pretty = "";
          } else {
            pretty = JSON.stringify(
              obj,
              (key, value) => (value === null ? undefined : value),
              2
            );
          }
        } catch (e) {
          pretty = result;
        }
      } else {
        pretty = "";
      }
      return (
        <div>
          <textarea
            className="text-muted fw-bold fs-3 form-control h-250px w-400px pe-auto"
            readOnly
            placeholder=""
            id="floatingTextarea2"
            value={pretty}
          />
        </div>
      );
    } else {
      return "test";
    }
  };

  return (
    <div
      className="modal fade"
      id="kt_modal_create_organization"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered mw-900px">
        <div className="modal-content">
          <div className="modal-header">
            <span>
              <h2>Create Organization</h2>
              <div
                className="fw-bold fs-3 text-danger"
                hidden={!submitError ? true : false}
              >
                {submitError && errorMessage()}
              </div>
              <div
                className="fw-bold fs-3 text-success"
                hidden={!submitSuccess ? true : false}
              >
                {submitSuccess && successMessage()}
              </div>
            </span>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              onClick={() => closeModal()}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </div>
          </div>

          <div className="modal-body py-lg-10 px-lg-10">
            <div
              ref={stepperRef}
              className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
              id="kt_modal_create_organization_stepper"
            >
              <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
                <div className="stepper-nav ps-lg-10">
                  <div
                    className="stepper-item current"
                    data-kt-stepper-element="nav"
                  >
                    <div className="stepper-line w-40px"></div>

                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">1</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Details</h3>

                      <div className="stepper-desc">
                        Enter Organization Details
                      </div>
                    </div>
                  </div>

                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <div className="stepper-line w-40px"></div>

                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">2</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Module</h3>

                      <div className="stepper-desc">
                        Select Integration Modules
                      </div>
                    </div>
                  </div>

                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <div className="stepper-line w-40px"></div>

                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">3</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Summary</h3>

                      <div className="stepper-desc">Review and Submit</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-row-fluid py-lg-5 px-lg-15">
                <Formik
                  innerRef={ref}
                  validationSchema={currentSchema}
                  initialValues={initValues}
                  onSubmit={submitStep}
                >
                  {({ resetForm, values, setFieldValue }) => (
                    <Form
                      className="form"
                      noValidate
                      id="kt_modal_create_organization_form"
                    >
                      <div
                        className="current"
                        data-kt-stepper-element="content"
                      >
                        <div className="w-100">
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Name</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Enter organization name"
                              ></i>
                            </label>

                            <Field
                              type="text"
                              className="form-control form-control-lg form-control-solid"
                              name="orgName"
                              placeholder="Enter organization name"
                            />
                            <div className="text-danger">
                              <ErrorMessage name="orgName" />
                            </div>
                          </div>
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Number</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Enter organization number"
                              ></i>
                            </label>

                            <Field
                              type="text"
                              className="form-control form-control-lg form-control-solid"
                              name="orgNumber"
                              placeholder="Enter organization number"
                            />
                            <div className="text-danger">
                              <ErrorMessage name="orgNumber" />
                            </div>
                          </div>
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Address</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Enter organization address"
                              ></i>
                            </label>

                            <Field
                              type="text"
                              className="form-control form-control-lg form-control-solid"
                              name="orgAddress"
                              placeholder="Enter organization address"
                            />
                            <div className="text-danger">
                              <ErrorMessage name="orgAddress" />
                            </div>
                          </div>
                          <div className="d-flex flex-stack">
                            <div className="me-5">
                              <label className="fs-6 fw-bold form-label">
                                Status
                              </label>
                            </div>
                            <label className="form-check form-switch form-check-custom form-check-solid">
                              <Field
                                name="orgStatus"
                                className="form-check-input"
                                type="checkbox"
                              />
                              <span className="form-check-label fw-bold text-gray-400" />
                            </label>
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element="content">
                        <div className="w-100">
                          <div className="fv-row">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-4">
                              <span className="required">
                                Select Integration Module
                              </span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Specify Integration Modules"
                              ></i>
                            </label>

                            <div className="d-flex flex-column mb-7 fv-row">
                              <div className="mb-9">
                                <Field
                                  as="select"
                                  name="integrationModuleId"
                                  className="form-select form-select-solid"
                                >
                                  <option value="" disabled selected>
                                    Select Integration Module
                                  </option>
                                  {integrationModules.map((module) => (
                                    <option key={module.id} value={module.id}>
                                      {module.name}
                                    </option>
                                  ))}
                                </Field>
                                <div className="text-danger">
                                  <ErrorMessage name="integrationModuleId" />
                                </div>
                              </div>
                              <div className="d-flex flex-stack mb-6">
                                <div className="me-5">
                                  <label className="fs-6 fw-bold form-label">
                                    Status
                                  </label>
                                </div>
                                <label className="form-check form-switch form-check-custom form-check-solid">
                                  <Field
                                    name="integratonModuleStatus"
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                  <span className="form-check-label fw-bold text-gray-400" />
                                </label>
                              </div>
                              <div className="d-flex flex-stack align-items-center flex-row-reverse mt-sm-4 mb-8">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary me-3"
                                  onClick={() =>
                                    addStoreClickHandler(resetForm, values)
                                  }
                                >
                                  Add
                                </button>
                              </div>
                              <div className="text-danger">
                                <ErrorMessage name="integrationModules" />
                              </div>
                              <div className="fv-row h-250px w-500px pe-auto overflow-auto">
                                <CompanyIntegrationModulesTable
                                  className="mb-5 mb-xl-8"
                                  integrationModuleList={
                                    selectedIntegrationModules
                                  }
                                  removeIntegrationModule={
                                    removeIntegrationModuleClickHandler
                                  }
                                  values={values}
                                  setIntegrationModuleValue={setStores(
                                    values,
                                    setFieldValue
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-danger">
                            <ErrorMessage name="framework" />
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element="content">
                        <div className="w-100 text-left">
                          <h1 className="fw-bolder text-dark mb-3">
                            Organization Summary
                          </h1>
                          <div className="text-muted text-left fw-bold fs-3">
                            {displaySummary()}
                          </div>
                        </div>
                      </div>

                      <div className="d-flex flex-stack pt-10">
                        <div className="me-2">
                          <button
                            onClick={prevStep}
                            type="button"
                            className="btn btn-lg btn-light-primary me-3"
                            data-kt-stepper-action="previous"
                          >
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr063.svg"
                              className="svg-icon-4 me-1"
                            />
                            Back
                          </button>
                        </div>

                        <div>
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary me-3"
                          >
                            <span className="indicator-label">
                              {stepper.current?.currentStepIndex !==
                                stepper.current?.totatStepsNumber! - 1 &&
                                "Continue"}
                              {stepper.current?.currentStepIndex ===
                                stepper.current?.totatStepsNumber! - 1 &&
                                "Submit"}
                              <KTSVG
                                path="/media/icons/duotune/arrows/arr064.svg"
                                className="svg-icon-3 ms-2 me-0"
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { MainOrganization };
