/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import IntegrationModuleType from "../../../../app/pages/commonTypes/IntegrationModuleType";
import { IntegrationModule } from "../../../../app/services/models/IntegrationModuleModel";

type Props = {
  className: string;
  inetgrationModuleList: IntegrationModule[];
  integrationModules: IntegrationModuleType[];
};

const CompanyIntegrationModuleTable: React.FC<Props> = ({
  className,
  inetgrationModuleList,
  integrationModules,
}) => {
  const setIntegrationModules = (integrationModuleId: number) => {
    var module = integrationModules.find(
      ({ id }) => id === integrationModuleId
    );
    return module?.name;
  };

  return (
    <div className={`${className} companyIntegrationModuleTable`}>
      {/* begin::Body */}
      <div className="py-3">
        {/* begin::Table container */}
        <div className="table-responsive pe-none">
          {/* begin::Table */}
          <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-100px">Module Name</th>
                <th className="min-w-100px text-end">Status</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {inetgrationModuleList &&
                inetgrationModuleList.map((module) => (
                  <tr key={module.id}>
                    <td>
                      <a href="#" className="text-dark fw-bolder fs-6">
                        {setIntegrationModules(module.integrationModuleId)}
                      </a>
                    </td>
                    <td className="text-end">
                      {module.active ? (
                        <span className="badge badge-light-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge badge-light-danger">
                          Inactive
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { CompanyIntegrationModuleTable };
