
export async function request(input: RequestInfo | URL, init?: RequestInit) : Promise<Response>{
    const response = await fetch(input, init);

    if (response.status === 401) {
        //redirect logout page which will change the state and redirect back to login (not a good solution)
        document.location.href = '/logout';
        return response;
    }else{
        return response;
    }
}


export function getUrl<T>(baseUrl:string, filters:Filters<T>): string{
    const searchParams = new URLSearchParams();

  for (const key in filters) {
    if (filters.hasOwnProperty(key)) {
      const value = filters[key];
      if(value !== null){
        searchParams.append(key, String(value));
      }     
    }
  }
  const urlWithSearchParams = `${baseUrl}?${searchParams.toString()}`;
  return urlWithSearchParams;
}

export type Filters<T = any> = {
    [key in keyof T]: string | number | boolean | null;
  };
  
