import React, { useCallback, useEffect, useRef, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

export default function DatePickerComponent(props: any) {
  const dateRef = useRef<any>();
  const [isCreate] = useState<boolean>(props.create);

  const handleApply = (event: any, picker: any) => {
    picker.element.val(
      new Date(picker.startDate).toLocaleDateString("sv-SE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    );
    props.startDate(
      new Date(picker.startDate).toLocaleDateString("sv-SE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    );
  };

  const getStartDate = useCallback(() => {
    let currentStateCopy: any = { ...props };
    if (typeof currentStateCopy.date === "string") {
      var formattedDate = new Date(currentStateCopy.date).toLocaleDateString(
        "sv-SE",
        {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }
      );
      dateRef.current?.setStartDate(formattedDate);
    }
  }, [props]);

  useEffect(() => {
    getStartDate();
  }, [getStartDate]);

  return (
    <>
      {isCreate ? (
        <DateRangePicker
          ref={dateRef}
          onApply={handleApply}
          initialSettings={{
            autoUpdateInput: false,
            singleDatePicker: true,
            showDropdowns: true,
            minYear: 1901,
            maxYear: parseInt(moment().format("YYYY"), 10),
            locale: {
              cancelLabel: "Clear",
            },
          }}
        >
          <input
            type="text"
            className="form-select form-select-solid"
            name="defaultStartDate"
            defaultValue=""
            placeholder="Enter default start date"
            autoComplete="off"
          />
        </DateRangePicker>
      ) : (
        <DateRangePicker
          ref={dateRef}
          onApply={handleApply}
          initialSettings={{
            autoUpdateInput: true,
            singleDatePicker: true,
            showDropdowns: true,
            minYear: 1901,
            maxYear: parseInt(moment().format("YYYY"), 10),
            locale: {
              format: "YYYY-MM-DD",
            },
          }}
        >
          <input
            className="form-select form-select-solid"
            name="defaultStartDate"
            autoComplete="off"
          />
        </DateRangePicker>
      )}
    </>
  );
}
