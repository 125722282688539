import React from 'react'
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Placement } from "@popperjs/core";

interface Props {
    text: string,
    placement: Placement | undefined,
    id: string,
    children: JSX.Element
}

const CustomTextPopup = ({ text, placement, id, children }: Props) => {
  return (
    <OverlayTrigger
      trigger="hover"
      key={id}
      placement={placement}
      overlay={
        <Popover id={id} style={{ maxWidth: '360px' }}>
          <Popover.Body>
            {text}
          </Popover.Body>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

export default CustomTextPopup