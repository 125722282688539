import axios from "axios";
import { VipUserModel } from "../models/UserModel";

const API_URL = process.env.REACT_APP_API_URL;
const AUTH_URL = process.env.REACT_APP_AUTH_BASE;

export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;

export const LOGIN_URL = `${AUTH_URL}/oauth2`;
export const GET_USER_BY_ACCESSTOKEN_URL = `${AUTH_URL}/verifyToken`;

//Server should return AuthModel
export async function login(username: string, password: string) {
  return axios.post(LOGIN_URL, {
    username,
    password,
  });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function getUserByToken(token: string) {
  return axios.post<VipUserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    data: { token: token },
  });
}
