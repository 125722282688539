import { useContext, useEffect, useState } from "react";
import { SelectedItemContext } from "../../../layout/MasterLayout";

//Bootstrap
import { OverlayTrigger, Popover } from "react-bootstrap";

//PrimeReact
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

//Types
import {
  PlatformTaskType,
  PlatformTaskTableType,
} from "../../../../app/pages/platformTask/PlatformTaskType";

//Services
import {
  deletePlatformTask,
  getAllPlatformTasksTableFilter,
} from "../../../../app/services/PlatformTaskService";

//Styles
import "./styles/PlatformTaskTable.scss";
import { KTSVG } from "../../../helpers";
import { getAllOrganizationNames } from "../../../../app/services/CompanyService";
import { CompanyNamesType } from "../../../../app/pages/platformTaskHistory/PlatformTaskHistoryType";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { PlatformTaskTableParamTypes } from "../../../../app/services/types/PlatformTaskParamTypes";
import TaskSubscriptionModal from "../../modals/task-subscriptions/TaskSubscriptionsModal";

type Props = {
  className: string;
};

const PlatformTaskTable: React.FC<Props> = ({ className }) => {
  const editSelectedTaskContext = useContext(SelectedItemContext);
  const [platformTask, setPlatformTask] = useState<PlatformTaskType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSubsModal, setShowSubsModal] = useState(false);

  const [platformTaskList, setPlatformTaskList] = useState<
    PlatformTaskTableType[]
  >([]);
  const [organizations, setOrganizations] = useState<CompanyNamesType[]>([]);

  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [filterParams, setFilterParams] = useState({
    "taskType.name": {
      value: "",
      matchMode: FilterMatchMode.CONTAINS,
    },
    "company.name": {
      value: "",
      matchMode: FilterMatchMode.CONTAINS,
    },
    "integrationModule.name": {
      value: "",
      matchMode: FilterMatchMode.CONTAINS,
    },
    "source.sourceName": {
      value: "",
      matchMode: FilterMatchMode.CONTAINS,
    },
    "destination.destinationName": {
      value: "",
      matchMode: FilterMatchMode.CONTAINS,
    },
    "event.name": {
      value: "",
      matchMode: FilterMatchMode.CONTAINS,
    },
    isAllStore: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    executionDate: {
      value: "",
      matchMode: FilterMatchMode.CONTAINS,
    },
    active: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    "scheduleStatus.name": {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
  });
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [selectedPlatformTask, setSelectedPlatformTask] = useState<string>("");
  const [deleteAlertVisibility, setDeleteAlertVisibility] =
    useState<boolean>(false);

  //lazy loading
  const onPage = (event: any) => {
    setLazyParams(event);
  };




  //API Calls
  const getAllPlatformTasksList = () => {
    setLoading(true);
    var data: PlatformTaskTableParamTypes = {
      pageNumber: lazyParams.page + 1,
      dataLimit: lazyParams.rows,
      taskName: filterParams["taskType.name"].value,
      companyName: filterParams["company.name"].value,
      integrationModuleName: filterParams["integrationModule.name"].value,
      sourceName: filterParams["source.sourceName"].value,
      destinationName: filterParams["destination.destinationName"].value,
      eventName: filterParams["event.name"].value,
      isAllStore: filterParams.isAllStore.value,
      executionDate:
        filterParams.executionDate.value === null
          ? ""
          : filterParams.executionDate.value,
      active: filterParams.active.value,
      scheduleStatus: filterParams["scheduleStatus.name"].value,
    };
    getAllPlatformTasksTableFilter(data)
      .then((result) => {
        setPlatformTaskList(result.platformtasks);
        setTotalRecords(result.totalRecords);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const getOrganizations = () => {
    getAllOrganizationNames()
      .then((result) => {
        setOrganizations(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  useEffect(() => {
    getAllPlatformTasksList();
  }, [lazyParams]);

  useEffect(() => {
    getAllPlatformTasksList();
  }, [filterParams]);

  //filtering
  const onFilter = (value: any) => {
    if (value !== undefined) {
      setFilterParams({
        "taskType.name": {
          value: value.filters["taskType.name"].value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        "company.name": {
          value: value.filters["company.name"].value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        "integrationModule.name": {
          value: value.filters["integrationModule.name"].value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        "source.sourceName": {
          value: value.filters["source.sourceName"].value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        "destination.destinationName": {
          value: value.filters["destination.destinationName"].value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        "event.name": {
          value: value.filters["event.name"].value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        isAllStore: {
          value: value.filters.isAllStore.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        executionDate: {
          value: value.filters.executionDate.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        active: {
          value: value.filters.active.value,
          matchMode: FilterMatchMode.CONTAINS,
        },
        "scheduleStatus.name": {
          value: value.filters["scheduleStatus.name"].value,
          matchMode: FilterMatchMode.CONTAINS,
        },
      });
    } else {
      setFilterParams({
        "taskType.name": {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        "company.name": {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        "integrationModule.name": {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        "source.sourceName": {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        "destination.destinationName": {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        "event.name": {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        isAllStore: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        executionDate: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        active: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        "scheduleStatus.name": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      });
    }
  };

  //data formatting/ finding functions
  const findAllStoreStatus = (isAllstore: boolean) => {
    if (isAllstore) {
      return (
        <span className="badge badge-light-success fs-7 fw-bold">True</span>
      );
    } else {
      return (
        <span className="badge badge-light-danger fs-7 fw-bold">False</span>
      );
    }
  };

  const formatDate = (executionDate: string) => {
    return new Date(executionDate).toLocaleDateString("sv-SE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const findTaskStatus = (isActive: boolean) => {
    if (isActive) {
      return (
        <span className="badge badge-light-success fs-7 fw-bold">Active</span>
      );
    } else {
      return (
        <span className="badge badge-light-danger fs-7 fw-bold">Inactive</span>
      );
    }
  };

  const findScheduleStatus = (scheduleStatus: any) => {
    if (scheduleStatus === null) {
      return (
        <span
          className="badge badge-light-primary fs-7 fw-bold"
          style={{ WebkitTextFillColor: "#d2d0db" }}
        >
          N/A
        </span>
      );
    } else {
      if (scheduleStatus.id === 1) {
        return (
          <span className="badge badge-light-info fs-7 fw-bold">
            {scheduleStatus.name}
          </span>
        );
      } else if (scheduleStatus.id === 2) {
        return (
          <span className="badge badge-light-warning fs-7 fw-bold">
            {scheduleStatus.name}
          </span>
        );
      } else if (scheduleStatus.id === 3) {
        return (
          <span
            className="badge badge-light-primary fs-7 fw-bold"
            style={{ WebkitTextFillColor: "#d2d0db" }}
          >
            {scheduleStatus.name}
          </span>
        );
      } else
        return (
          <span className="badge badge-light-danger fs-7 fw-bold">
            Not defined
          </span>
        );
    }
  };

  //body setting functions
  const setTaskName = (rowData: any) => {
    var subStrCheck = rowData.taskType.name.indexOf("event trigerred");
    if (subStrCheck !== -1) {
      return "Event Trigger";
    } else {
      return rowData.taskType.name;
    }
  };

  const setOrgName = (rowData: any) => {
    return rowData.company.name;
  };

  const setInteModuleName = (rowData: any) => {
    return rowData.integrationModule.name;
  };

  const setSourceName = (rowData: any) => {
    return rowData.source.sourceName;
  };

  const setDestinationName = (rowData: any) => {
    return rowData.destination.destinationName;
  };

  const setEventName = (rowData: any) => {
    return rowData.event.name;
  };

  const setAllStoreStatus = (rowData: any) => {
    return findAllStoreStatus(rowData.isAllStore);
  };

  const setFormattedDate = (rowData: any) => {
    return formatDate(rowData.defaultStartDate);
  };

  const setDateParams = (startDate: any, endDate: any) => {
    var dateArray: any = [new Date(startDate.value), new Date(endDate.value)];
    let filterParamsCopy = { ...filterParams };
    filterParamsCopy["executionDate"].value = dateArray;
    setFilterParams(filterParamsCopy);
  };

  let startDate: any = "";
  let endDate: any = "";

  const setDateFilter = (e: any) => {
    if (startDate === "" && endDate === "") {
      startDate = e;
    } else if (startDate !== "" && endDate !== "") {
      startDate = e;
      endDate = "";
    } else if (startDate !== "" && endDate === "") {
      endDate = e;
    }
  };

  const applyDateFilterButton = () => {
    return (
      <div>
        <Button
          onClick={() => setDateParams(startDate, endDate)}
          hidden={startDate && endDate ? false : true}
        >
          Apply
        </Button>
      </div>
    );
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        onSelect={(e) => setDateFilter(e)}
        dateFormat="yy-mm-dd"
        placeholder="YYYY-MM-DD"
        selectionMode="range"
        footerTemplate={applyDateFilterButton}
        readOnlyInput={true}
        style={{ "min-width": "9rem", margin: "0px 0px 0px 0px" }}
      />
    );
  };

  const findisAllStoreFilter = (status: string) => {
    if (status === "True") {
      return (
        <span className="badge badge-light-success fs-7 fw-bold">True</span>
      );
    } else if (status === "False") {
      return (
        <span className="badge badge-light-danger fs-7 fw-bold">False</span>
      );
    } else {
      return (
        <span className="text-muted fw-bold text-muted d-block fs-7">
          Undefined
        </span>
      );
    }
  };

  const findtaskStatusFilter = (status: string) => {
    if (status === "Active") {
      return (
        <span className="badge badge-light-success fs-7 fw-bold">Active</span>
      );
    } else if (status === "Inactive") {
      return (
        <span className="badge badge-light-danger fs-7 fw-bold">Inactive</span>
      );
    } else {
      return (
        <span className="text-muted fw-bold text-muted d-block fs-7">
          Undefined
        </span>
      );
    }
  };

  const findtaskScheduleStatusFilter = (scheduleStatus: any) => {
    if (scheduleStatus === "Running") {
      return (
        <span className="badge badge-light-info fs-7 fw-bold">Running</span>
      );
    } else if (scheduleStatus === "Paused") {
      return (
        <span className="badge badge-light-warning fs-7 fw-bold">Paused</span>
      );
    } else if (scheduleStatus === "N/A") {
      return (
        <span
          className="badge badge-light-primary fs-7 fw-bold"
          style={{ WebkitTextFillColor: "#d2d0db" }}
        >
          N/A
        </span>
      );
    } else
      return (
        <span className="badge badge-light-danger fs-7 fw-bold">
          Not defined
        </span>
      );
  };

  const setFilterParamsisAllStoreDropDown = (e: any) => {
    let filterParamsCopy = { ...filterParams };
    filterParamsCopy["isAllStore"].value = e;
    setFilterParams(filterParamsCopy);
  };

  const isAllStoreStatuses = ["True", "False"];
  const taskStatusStatuses = ["Active", "Inactive"];
  const taskScheduleStatuses = ["Running", "Paused", "N/A"];

  const isAllStoreFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        options={isAllStoreStatuses}
        onChange={(e) => setFilterParamsisAllStoreDropDown(e.value)}
        itemTemplate={findisAllStoreFilter}
        className="p-column-filter"
        showClear
        style={{ "min-width": "9rem" }}
      />
    );
  };

  const setFilterParamsStatusDropDown = (e: any) => {
    let filterParamsCopy = { ...filterParams };
    filterParamsCopy["active"].value = e;
    setFilterParams(filterParamsCopy);
  };

  const taskStatusFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        options={taskStatusStatuses}
        onChange={(e) => setFilterParamsStatusDropDown(e.value)}
        itemTemplate={findtaskStatusFilter}
        className="p-column-filter"
        showClear
        style={{ "min-width": "9rem", "margin-left": "-1rem" }}
      />
    );
  };

  const setFilterParamsScheduleStatusDropDown = (e: any) => {
    let filterParamsCopy = { ...filterParams };
    filterParamsCopy["scheduleStatus.name"].value = e;
    setFilterParams(filterParamsCopy);
  };

  const taskScheduleFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        options={taskScheduleStatuses}
        onChange={(e) => setFilterParamsScheduleStatusDropDown(e.value)}
        itemTemplate={findtaskScheduleStatusFilter}
        className="p-column-filter"
        showClear
        style={{ "min-width": "9rem" }}
      />
    );
  };

  const setTaskStatus = (rowData: any) => {
    return findTaskStatus(rowData.active);
  };

  const setScheduleStatus = (rowData: any) => {
    return findScheduleStatus(rowData.scheduleStatus);
  };

  const setOrgActionButtons = (rowData: any) => {
    return (
      <div>
        {["left"].map((placement: any) => (
          <OverlayTrigger
            trigger="hover"
            key={placement}
            placement={placement}
            overlay={
              <Popover id={`popover-positioned-${placement}`}>
                <Popover.Header as="h3">
                  <strong>Manual Trigger</strong>
                </Popover.Header>
                <Popover.Body>
                  Trigger task to run sync for a given date range.
                  <br />
                  <br />
                  <code>From Date</code>: Sync Start Date
                  <br />
                  <br />
                  <code>To Date</code>: Sync End Date
                </Popover.Body>
              </Popover>
            }
          >
            <a
              href="#/"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 pe-auto"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_trigger_platformTask"
              onClick={() => triggerPlatformTask(rowData.id.toString())}
            >
              <KTSVG
                path="/media/icons/duotune/general/moving-thunder.svg"
                className="svg-icon-3"
              />
            </a>
          </OverlayTrigger>
        ))}
        <a
          href="#/"
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 pe-auto"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_update_platformTask"
          onClick={() => updatecomponent(rowData.id.toString())}
        >
          <KTSVG
            path="/media/icons/duotune/art/art005.svg"
            className="svg-icon-3"
          />
        </a>
        {/* Subscription Icon */}
        <a
          href="#/"
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 pe-auto"
          // data-bs-toggle="modal"
          // data-bs-target="#kt_modal_task_subscriptions"
          onClick={() => openSubsmodal(rowData.id.toString())}
        >
          <KTSVG
            path="/media/icons/duotune/communication/com011.svg"
            className="svg-icon-3"
          />
        </a>
        <a
          href="#/"
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm pe-auto"
          onClick={() => removePlatformTaskAlert(rowData.id.toString())}
        >
          <KTSVG
            path="/media/icons/duotune/general/gen027.svg"
            className="svg-icon-3"
          />
        </a>
      </div>
    );
  };

  const removePlatformTaskAlert = (platformTaskId: string) => {
    setSelectedPlatformTask(platformTaskId);
    setDeleteAlertVisibility(true);
  };

  const updateSelectedTaskId = (platformTaskId: string) => {
    setSelectedPlatformTask(platformTaskId);
  };

  const openSubsmodal = (platformTaskId: string) => {  
    setSelectedPlatformTask(platformTaskId);
    setShowSubsModal(true);
  };

  const showHideSubsmodal = (value: boolean) => {      
    setShowSubsModal(value);
  };

  //Table operations
  const removePlatformTask = async (platformtaskId: string) => {
    await deletePlatformTask(platformtaskId)
      .then((result) => {
        if (result === platformtaskId) {
          setPlatformTaskList(
            platformTaskList.filter((item) =>
              item.platformtasks.find(
                (task) => task.id !== parseInt(platformtaskId)
              )
            )
          );
        }
        getAllPlatformTasksList();
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
    setDeleteAlertVisibility(false);
  };

  const hideDialog = () => {
    setDeleteAlertVisibility(false);
  };

  const triggerPlatformTask = (id: string) => {
    editSelectedTaskContext.setSelectedItem?.(id);
  };

  const updatecomponent = (id: string) => {
    editSelectedTaskContext.setSelectedItem?.(id);
  };

  const createPlatformTask = (id: string) => {
    editSelectedTaskContext.setSelectedItem?.(id);
  };

  useEffect(() => {
    getAllPlatformTasksList();
    getOrganizations();
  }, [platformTask.length, editSelectedTaskContext.selectedItem]);

  //templates
  const platformTaskItemTemplate = (option: any) => {
    return (
      <div className="p-multiselect-representative-option">
        <span className="image-text">{option.name}</span>
      </div>
    );
  };


  return (
    <div className="card mb-5 mb-xl-8 platformTaskTable">
      <div className="card-header border-0 pt-5">
        <div className="col-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">
              Platform Tasks
            </span>
            <span className="text-muted mt-1 fw-bold fs-7">
              {totalRecords} Records
            </span>
          </h3>
        </div>
        <div className="col-8 d-flex flex-row-reverse">
          <h3 className="card-title align-items-start flex-column">
            <a
              href="#"
              className="btn btn-sm btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_create_platformTask"
              id="kt_toolbar_primary_button"
              onClick={() =>
                createPlatformTask(platformTaskList?.length.toString())
              }
            >
              Create
            </a>
          </h3>
        </div>
      </div>
      <div className="card-body py-3">
        {/* removebeforecommit - remove the style below */}
        <div className="table-responsive">
          <DataTable
            value={platformTaskList}
            paginator
            lazy
            totalRecords={totalRecords}
            onPage={onPage}
            first={lazyParams.first}
            rows={lazyParams.rows}
            rowsPerPageOptions={[10, 25, 50]}
            dataKey="id"
            rowHover
            filterDisplay="row"
            loading={loading}
            responsiveLayout="scroll"
            emptyMessage="No companies found."
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            filters={filterParams}
            onFilter={(e) => onFilter(e)}
            scrollable
          >
            {/* Task Name */}
            <Column
              field="taskName"
              header="Task Name"
              className="text-dark min-w-120px"
              body={setTaskName}
              filter={true}
              showFilterMenu={false}
              filterField="taskType.name"
              filterMenuClassName="w-100px"
              showClearButton={false}
            />

            {/* Organization Name */}
            <Column
              field="companyName"
              header="Org Name"
              className="text-dark min-w-200px"
              body={setOrgName}
              filter={true}
              showFilterMenu={false}
              filterField="company.name"
              filterMenuClassName="min-w-250px"
              showClearButton={false}
            />

            {/* Integration Module Name */}
            <Column
              field="integrationModuleName"
              header="Integration Module"
              className="text-dark min-w-200px"
              body={setInteModuleName}
              filter={true}
              showFilterMenu={false}
              filterField="integrationModule.name"
              showClearButton={false}
            />

            {/* Soruce Name */}
            <Column
              field="sourceName"
              header="Source"
              className="text-dark min-w-100px"
              body={setSourceName}
              filter={true}
              showFilterMenu={false}
              filterField="source.sourceName"
              showClearButton={false}
            />

            {/* Destination Name */}
            <Column
              field="destinationName"
              header="Destination"
              className="text-dark min-w-200px"
              body={setDestinationName}
              filter={true}
              showFilterMenu={false}
              filterField="destination.destinationName"
              showClearButton={false}
            />

            {/* Event Name */}
            <Column
              field="eventName"
              header="Event"
              className="text-dark min-w-200px"
              body={setEventName}
              filter={true}
              showFilterMenu={false}
              filterField="event.name"
              showClearButton={false}
            />

            {/* All Store Status */}
            <Column
              field="isAllStore"
              header="All Store"
              className="text-dark min-w-120px align-items-center"
              body={setAllStoreStatus}
              filter={true}
              showFilterMenu={false}
              filterField="isAllStore"
              filterElement={isAllStoreFilterTemplate}
              showClearButton={false}
            />

            {/* Start Date */}
            <Column
              field="executionDate"
              header="Start Date"
              dataType="date"
              className="text-dark min-w-125px"
              body={setFormattedDate}
              filter={true}
              showFilterMenu={false}
              filterElement={dateFilterTemplate}
            />

            {/* Task Status */}
            <Column
              field="active"
              header="Status"
              className="text-dark min-w-120px"
              body={setTaskStatus}
              filter={true}
              showFilterMenu={false}
              filterElement={taskStatusFilterTemplate}
              showClearButton={false}
            />

            {/* Schedule Status */}
            <Column
              field="scheduleStatus.name"
              header="Schedule Status"
              className="text-dark min-w-120px"
              body={setScheduleStatus}
              filter={true}
              showFilterMenu={false}
              filterElement={taskScheduleFilterTemplate}
              showClearButton={false}
            />

            {/* action btn */}
            <Column
              header="Actions"
              headerStyle={{ width: "200px", textAlign: "center" }}
              className="text-dark min-w-200px"
              body={setOrgActionButtons}
              frozen
              alignFrozen="right"
            />
          </DataTable>
          <Dialog
            visible={deleteAlertVisibility}
            style={{ width: "750px" }}
            modal
            className="p-fluid"
            onHide={hideDialog}
          >
            {selectedPlatformTask && selectedPlatformTask === null ? (
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="4" 
                animationDuration=".5s"
              />
            ) : (
              <div className="alert alert-dismissible d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">
                <span className="svg-icon svg-icon-5tx svg-icon-danger mb-5">
                  <KTSVG
                    path="/media/icons/duotune/general/gen044.svg"
                    className="svg-icon-1"
                  />
                </span>

                <div className="text-center">
                  <h5 className="fw-bolder fs-1 mb-5">
                    Do you want to remove the Platform Task?
                  </h5>

                  <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                  <div className="mb-9">
                    Removing the platform task will result in deletion of
                    realted platform task stores.
                  </div>
                  <div className="d-flex flex-center flex-wrap">
                    <a
                      href="#"
                      className="btn btn-outline btn-outline-danger btn-active-danger m-2"
                      onClick={hideDialog}
                    >
                      Cancel
                    </a>
                    <a
                      href="#"
                      className="btn btn-danger m-2"
                      onClick={() => removePlatformTask(selectedPlatformTask)}
                    >
                      Delete
                    </a>
                  </div>
                </div>
              </div>
            )}
          </Dialog>
        </div>
      </div>

      <TaskSubscriptionModal 
      id={selectedPlatformTask}
      isOpen={showSubsModal}
      toggleModal={showHideSubsmodal}/>
    </div>
  );
};

export { PlatformTaskTable };
