import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

export default function DateTimeRangePickerComponent(props: any) {
  const [isExecution] = useState<boolean>(props.execution);

  const handleApply = (event: any, picker: any) => {
    if (isExecution) {
      props.setDate(
        new Date(picker.startDate._d).toLocaleString("sv-SE") +
          "," +
          new Date(picker.endDate._d).toLocaleString("sv-SE")
      );
    } else {
      props.syncDate(
        new Date(picker.startDate._d).toLocaleString("sv-SE") +
          "," +
          new Date(picker.endDate._d).toLocaleString("sv-SE")
      );
    }
    picker.element.val(
      new Date(picker.startDate._d).toLocaleString("sv-SE") +
        "  ⇀  " +
        new Date(picker.endDate._d).toLocaleString("sv-SE")
    );
  };

  const handleClear = (event: any, picker: any) => {
    if (isExecution) {
      props.setDate("");
      picker.element.val("");
    } else {
      props.syncDate("");
      picker.element.val("");
    }
  };

  return (
    <>
      <DateRangePicker
        onApply={handleApply}
        onCancel={handleClear}
        initialSettings={{
          timePicker: true,
          timePicker24hours: true,
          autoUpdateInput: false,
          showDropdowns: true,
          locale: {
            cancelLabel: "Clear",
          },
        }}
      >
        <input
          type="text"
          className="form-control form-control-solid fw-normal"
          defaultValue=""
          placeholder="From ⇀ Date"
          autoComplete="off"
        />
      </DateRangePicker>
    </>
  );
}
