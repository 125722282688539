/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { KTSVG } from "../../../helpers";
import { Formik, Form, FormikValues, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { StepperComponent } from "../../../assets/ts/components";
import { TriggerPlatformTaskDTO } from "../../../../app/services/models/PlatformTaskModel";
import {
  getPlatformTaskById,
  triggerPlatformEvent,
} from "../../../../app/services/PlatformTaskService";
import { PlatformTaskType, PlatformtaskStore } from "../../../../app/pages/platformTask/PlatformTaskType";
import { SelectedItemContext } from "../../../layout/MasterLayout";
import { PlatformTaskTriggerTable } from "../../widgets";
import DateRangePickerComponent from "../date-picker/DateRangePickerComponent";
import { CompanyStore } from "../../../../app/pages/company/CompanyType";
import { getOrganizationStores } from "../../../../app/services/CompanyService";

interface ITriggerPlatformTask {
  startDate: any;
  endDate: any;
  storeCode: string;
  isAllStore: boolean;
}

const inits: ITriggerPlatformTask = {
  startDate: "",
  endDate: "",
  storeCode: "",
  isAllStore: false,
};

const triggerInits: TriggerPlatformTaskDTO = {
  eventType: 1,
  orgNo: "",
  isAllStore: false,
  storeCode: "",
  fromDate: "",
  toDate: "",
};

interface Store {
  storeCode: string;
  storeName: string;
  active: boolean;
}

const createAppSchema = [
  Yup.object({
    startDate: Yup.date().required().label("Start Date"),
    endDate: Yup.date().required().label("Start Date"),
    isAllStore: Yup.boolean(),
  }),
  Yup.object({
    storeCode: Yup.string().when("isAllStore", {
      is: (val: boolean) => val === false,
      then: Yup.string().required().label("Store Code"),
    }),
  }),
];

const TriggerPlatformTask: FC = () => {
  const selectedItemContextTrigger = useContext(SelectedItemContext);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(createAppSchema[0]);

  const [triggerPlatformTask, setTriggerPlatformTask] =
    useState<TriggerPlatformTaskDTO>(triggerInits);

  const [allStore, setAllStore] = useState<boolean>(true);

  const [selectedPlatformTask, setSelectedPlatformTask] =
    useState<PlatformTaskType>();

  const [valuesToUpdated, setValuesToUpdated] =
    useState<ITriggerPlatformTask>(inits);

  const [platformTaskStores, setPlatformTaskStores] = useState<Store[]>([]);

  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [startExecution, setStartExecution] = useState<boolean | null>(null);
  const [stores, setCompanyStores] = useState<CompanyStore[]>([]);

  const getSelectedPlatformTask = useCallback(async () => {
    let taskId = selectedItemContextTrigger.selectedItem!;
    if (taskId !== undefined) {
      if (typeof taskId === "number") {
        taskId = taskId.toString(taskId);
      }

      const ptTask = await getPlatformTaskById(taskId);

      if (ptTask?.company) {
        const storeList = await getOrganizationStores(ptTask?.company.id.toString()!);

        if (ptTask.platformTaskStores) {

          let updatedStores = ptTask.platformTaskStores.map((store: PlatformtaskStore) => {
            const matchStore = storeList.find((s: CompanyStore) => String(s.id) === store.storeCode);
            return matchStore ? { ...store, storeName: matchStore.name } : store;
          });

          ptTask.platformTaskStores = updatedStores;

          const filterIds = updatedStores.map((store: PlatformtaskStore) => store.storeCode);
          setCompanyStores(storeList.filter((item: CompanyStore) => filterIds.includes(item.id.toString())));
        }

      }

      setSelectedPlatformTask(ptTask);
    } else {
      return;
    }
  }, [selectedItemContextTrigger.selectedItem]);

  const setUpdateValues = useCallback(() => {
    let currentStateCopy: PlatformTaskType = { ...selectedPlatformTask! };
    if (Object.keys(currentStateCopy).length !== 0) {
      console.log(currentStateCopy);
      const triggerData: TriggerPlatformTaskDTO = {
        eventType: currentStateCopy.event.id,
        orgNo: currentStateCopy.company.orgNo,
        isAllStore: currentStateCopy.isAllStore,
        fromDate: "",
        toDate: "",
        storeCode: "",
      };
      setValuesToUpdated({
        ...valuesToUpdated,
        isAllStore: currentStateCopy.isAllStore,
      });
      setTriggerPlatformTask(triggerData);
      setAllStore(triggerData.isAllStore);
      setPlatformTaskStores(currentStateCopy.platformTaskStores);
    }
  }, [selectedPlatformTask]);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex - 1]);
  };

  const submitStep = (values: ITriggerPlatformTask, actions: FormikValues) => {
    console.log(values);
    if (!stepper.current) {
      return;
    }

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex]);

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext();
    } else {
      stepper.current.goto(1);
      onSubmit(values);
      actions.resetForm({
        values: {
          date: "",
          storeCode: "",
        },
      });
    }
  };

  const onSubmit = async (values: ITriggerPlatformTask) => {
    setTriggerPlatformTask({
      ...triggerPlatformTask,
      fromDate: values.startDate,
      toDate: values.endDate,
      storeCode: values.storeCode,
    });

    const triggerPlatformTaskDTO: TriggerPlatformTaskDTO = {
      eventType: triggerPlatformTask.eventType,
      orgNo: triggerPlatformTask.orgNo,
      isAllStore: triggerPlatformTask.isAllStore,
      fromDate: values.startDate,
      toDate: values.endDate,
      storeCode: values.storeCode,
    };
    console.log(triggerPlatformTaskDTO);
    triggerPlatformTaskAsync(triggerPlatformTaskDTO);
  };

  const triggerPlatformTaskAsync = async (
    platformTask: TriggerPlatformTaskDTO
  ) => {
    await triggerPlatformEvent(platformTask);
    setStartExecution(true);
    setTimeout(() => {
      setStartExecution(false);
    }, 3000);
  };

  const Message = () => {
    return <div>Execution Started...</div>;
  };

  const setDate = (values: ITriggerPlatformTask) => {
    values.startDate = startDate;
    values.endDate = endDate;
  };

  useEffect(() => {
    getSelectedPlatformTask();
    if (!stepperRef.current) {
      return;
    }
    loadStepper();
  }, [stepperRef, getSelectedPlatformTask]);

  useEffect(() => {
    setUpdateValues();
  }, [setUpdateValues]);

  return (
    <div
      className="modal fade"
      id="kt_modal_trigger_platformTask"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered mw-900px">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Trigger Platform Task</h2>
            <div
              className="fw-bold fs-3"
              hidden={!setStartExecution ? true : false}
            >
              {startExecution && Message()}
            </div>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </div>
          </div>

          <div className="modal-body py-lg-10 px-lg-10">
            <div
              ref={stepperRef}
              className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
              id="kt_modal_trigger_platformTask_stepper"
            >
              <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
                <div className="stepper-nav ps-lg-10">
                  <div
                    className="stepper-item current"
                    data-kt-stepper-element="nav"
                  >
                    <div className="stepper-line w-40px"></div>

                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">1</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Date</h3>

                      <div className="stepper-desc">Enter Date</div>
                    </div>
                  </div>

                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <div className="stepper-line w-40px"></div>

                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">2</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Store</h3>

                      <div className="stepper-desc">Select Store</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-row-fluid py-lg-5 px-lg-15">
                <Formik
                  enableReinitialize={true}
                  validationSchema={currentSchema}
                  initialValues={valuesToUpdated}
                  onSubmit={submitStep}
                >
                  {({ values }) => (
                    <Form
                      className="form"
                      noValidate
                      id="kt_modal_trigger_platformTask_form"
                    >
                      <div
                        className="current"
                        data-kt-stepper-element="content"
                      >
                        <div className="w-100">
                          <div className="fv-row mb-5">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">
                                Select Date Range
                              </span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Select Start Date and End Date for Sync"
                              ></i>
                            </label>
                            <div className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <Field
                                component={DateRangePickerComponent}
                                name="startDate"
                                startDate={setStartDate}
                                endDate={setEndDate}
                                date={startDate}
                                values={setDate(values)}
                                create={true}
                              />
                              <i
                                className="bi bi-calendar"
                                style={{
                                  marginLeft: "-2.5rem",
                                  fontSize: "1.5rem",
                                  pointerEvents: "none",
                                }}
                              ></i>
                            </div>
                            <div className="text-danger">
                              <ErrorMessage name="startDate" />
                            </div>
                          </div>
                          <div className="d-flex flex-stack">
                            <div className="me-5">
                              <label
                                className="fs-6 fw-bold form-label"
                                hidden={true}
                              >
                                Is All Store
                              </label>
                            </div>
                            <label className="form-check form-switch form-check-custom form-check-solid">
                              <Field
                                name="isAllStore"
                                className="form-check-input"
                                type="checkbox"
                                hidden={true}
                              />
                              <span className="form-check-label fw-bold text-gray-400" />
                            </label>
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element="content">
                        <div className="w-100">
                          <div className="fv-row">
                            <div className="fv-row mb-10">
                              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                <span className="required">Store</span>
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Enter Store Code"
                                ></i>
                              </label>

                              <Field as="select"
                                name="storeCode"
                                className="form-select form-select-solid"
                                disabled={values.isAllStore ? true : false}
                              >
                                <option value="" disabled selected>
                                  Select Store
                                </option>
                                {platformTaskStores.map((store) => (
                                  <option key={store.storeCode} value={store.storeCode}>
                                    {store.storeName}                               
                                  </option>
                                ))}
                              </Field>
                              <div className="text-danger">
                                <ErrorMessage name="storeCode" />
                              </div> 
                              <div className="fv-row mb-10 mt-sm-4">
                                <label className="d-flex align-items-center fs-5 fw-bold mb-2 mt-sm-4">
                                  Available Store List
                                </label>
                                <div className="fv-row mb-5 mt-sm-4">
                                  <PlatformTaskTriggerTable
                                    className="mb-5 mb-xl-8"
                                    platformTaskStoreList={platformTaskStores}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex flex-stack pt-10">
                        <div className="me-2">
                          <button
                            onClick={prevStep}
                            type="button"
                            className="btn btn-lg btn-light-primary me-3"
                            data-kt-stepper-action="previous"
                          >
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr063.svg"
                              className="svg-icon-4 me-1"
                            />
                            Back
                          </button>
                        </div>

                        <div>
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary me-3"
                          >
                            <span className="indicator-label">
                              {stepper.current?.currentStepIndex !==
                                stepper.current?.totatStepsNumber! - 1 &&
                                "Continue"}
                              {stepper.current?.currentStepIndex ===
                                stepper.current?.totatStepsNumber! - 1 &&
                                "Submit"}
                              <KTSVG
                                path="/media/icons/duotune/arrows/arr064.svg"
                                className="svg-icon-3 ms-2 me-0"
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TriggerPlatformTask };
