import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

export default function DateRangePickerComponent(props: any) {
  const [isCreate] = useState<boolean>(props.create);

  const handleApply = (event: any, picker: any) => {
    picker.element.val(
      new Date(picker.startDate._d).toLocaleDateString("sv-SE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }) +
        "  ⇀  " +
        new Date(picker.endDate._d).toLocaleDateString("sv-SE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
    );
    props.startDate(
      new Date(picker.startDate._d).toLocaleDateString("sv-SE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    );
    props.endDate(
      new Date(picker.endDate._d).toLocaleDateString("sv-SE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    );
  };

  return (
    <>
      {isCreate ? (
        <DateRangePicker
          onApply={handleApply}
          initialSettings={{
            autoUpdateInput: false,
            showDropdowns: true,
            locale: {
              cancelLabel: "Clear",
            },
          }}
        >
          <input
            type="text"
            className="form-control form-control-solid fw-normal"
            defaultValue=""
            placeholder="From Date ⇀ To Date"
            autoComplete="off"
            style={{ width: "75%" }}
          />
        </DateRangePicker>
      ) : (
        <DateRangePicker
          onApply={handleApply}
          initialSettings={{
            autoUpdateInput: true,
            showDropdowns: true,
            minYear: 1901,
            maxYear: parseInt(moment().format("YYYY"), 10),
            locale: {
              format: "YYYY-MM-DD",
            },
          }}
        >
          <input
            className="form-select form-select-solid bi bi-calendar"
            name="defaultStartDate"
            autoComplete="off"
          />
        </DateRangePicker>
      )}
    </>
  );
}
