import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

interface Props {
  children: JSX.Element;
}

const StatusTitlePopup = ({ children }: Props) => {
  return (
    <OverlayTrigger
      trigger="hover"
      key="bottom"
      placement="bottom"
      overlay={
        <Popover id={`popover-positioned-bottom`} style={{ maxWidth: "360px" }}>
          <Popover.Header as="h3">
            <strong>Tenant Database Status</strong>
          </Popover.Header>
          <Popover.Body>
            <div
              className="badge badge-light-primary d-inline-block"
              style={{ WebkitTextFillColor: "#d2d0db" }}
            >
              N/A
            </div>{" "}
            - Database not created
            <br />
            <br />
            <div className="badge badge-light-success d-inline-block">
              Success
            </div>{" "}
            - Database creation completed
            <br />
            <br />
            <div
              className="badge badge-light-info d-inline-block"
              style={{ color: "#00A3FF" }}
            >
              Pending
            </div>{" "}
            - Database creation in progress
            <br />
            <br />
            <div className="badge badge-light-danger d-inline-block">
              Failed
            </div>{" "}
            - Database creation failed
          </Popover.Body>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default StatusTitlePopup;
