import { CompanyTable } from "../../../_metronic/partials/widgets";

function Company() {
  return (
    <div>
      <CompanyTable className="mb-5 mb-xl-8" />
    </div>
  );
}

export default Company;
