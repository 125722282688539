/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { KTSVG } from "../../../helpers";
import { Formik, Form, FormikValues, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { StepperComponent } from "../../../assets/ts/components";
import IntegrationModule from "../../../../app/pages/commonTypes/IntegrationModuleType";
import { getAllIntegrationModules } from "../../../../app/services/IntegrationModuleService";
import {
  getOrganizationById,
  updateOrganization,
} from "../../../../app/services/CompanyService";
import {
  UpdateOrganizationDTO,
  updateModuleDTO,
} from "../../../../app/services/models/CompanyModel";
import { SelectedItemContext } from "../../../layout/MasterLayout";
import { CompanyType } from "../../../../app/pages/company/CompanyType";
import { IntegrationModuleDTO } from "../../../../app/services/models/IntegrationModuleModel";
import { CompanyIntegrationModulesTable } from "../../widgets/tables/CompanyIntegrationModulesTable";

interface IUpdateOrganizationData {
  id: number;
  orgName: string;
  orgNumber: string;
  orgAddress: string;
  orgStatus: boolean;
  integrationModuleId: string;
  integratonModuleStatus: boolean;
  integrationModules: IntegrationModules[];
}

interface IntegrationModules {
  integrationModuleId: number;
  active: boolean;
}

interface DisplayIntegrationModules {
  name: string;
  status: boolean;
}

const inits: IUpdateOrganizationData = {
  id: 0,
  orgName: "",
  orgNumber: "",
  orgAddress: "",
  orgStatus: true,
  integrationModuleId: "",
  integratonModuleStatus: false,
  integrationModules: [],
};

const createAppSchema = [
  Yup.object({
    orgName: Yup.string().required().label("Organization name"),
    orgNumber: Yup.string().required().label("Organization number"),
    orgAddress: Yup.string().required().label("Organization address"),
  }),
  Yup.object({
    integrationModuleId: Yup.string().test(
      "Integration Module",
      "Integration Module is a rquired field",
      function () {
        if (this.parent.integrationModules.length <= 0) {
          if (!this.parent.integrationModuleId) {
            return false;
          } else {
            return true;
          }
        } else return true;
      }
    ),
    integrationModules: Yup.array()
      .min(1)
      .required("")
      .label("Organization requires at least one Integration Module"),
  }),
];

const UpdateOrganization: FC = () => {
  const selectedItemContext = useContext(SelectedItemContext);
  const [selectedCompany, setSelectedCompany] = useState<CompanyType>();
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(createAppSchema[0]);
  const [valuesToUpdated, setValuesToUpdated] =
    useState<IUpdateOrganizationData>(inits);
  const ref = useRef<any>(null);
  const [integrationModules, setIntegrationModules] = useState<
    IntegrationModule[]
  >([]);

  const [selectedIntegrationModules, setSelectedIntegrationModules] = useState<
    IntegrationModuleDTO[]
  >([]);
  const [submitError, setSubmitError] = useState<boolean | null>(null);
  const [submitSuccess, setsubmitSuccess] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getSelectedCompany = useCallback(() => {
    let companyId = selectedItemContext.selectedItem;
    if (companyId !== undefined) {
      if (typeof companyId == "number") {
        companyId = companyId.toString(companyId);
      }
      getOrganizationById(companyId)
        .then((result) => {
          setSelectedCompany(result);
          setSelectedIntegrationModules(result.IntegrationModules);
        })
        .catch((error) => {
          console.log(error.message);
          throw error;
        });
    } else {
      return;
    }
  }, [selectedItemContext.selectedItem]);

  const setUpdatedValues = useCallback(() => {
    let currentStateCopy: CompanyType = { ...selectedCompany! };
    if (Object.keys(currentStateCopy).length !== 0) {
      const values: IUpdateOrganizationData = {
        id: currentStateCopy.id,
        orgNumber: currentStateCopy.orgNo,
        orgName: currentStateCopy.name,
        orgAddress: currentStateCopy.address,
        orgStatus: currentStateCopy.active,
        integrationModuleId: "",
        integratonModuleStatus: false,
        integrationModules: selectedIntegrationModules,
      };
      addIntegrationModules(currentStateCopy);
      setValuesToUpdated(values);
      setLoading(true);
    }
  }, [selectedCompany]);

  const addIntegrationModules = (modules: CompanyType) => {
    let moduleList: IntegrationModuleDTO[] = [];
    modules.integrationModules.forEach((e) => {
      integrationModules.map((module) => {
        if (module.id === e.integrationModuleId) {
          let m: IntegrationModuleDTO = {
            name: module.name,
            active: e.active,
            integrationModuleId: e.integrationModuleId,
          };
          moduleList.push(m);
        }
      });
    });
    setSelectedIntegrationModules(moduleList);
  };

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex - 1]);
  };

  const submitStep = (
    values: IUpdateOrganizationData,
    actions: FormikValues
  ) => {
    if (!stepper.current) {
      return;
    }

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex]);

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext();
    } else {
      stepper.current.goto(1);
      onSumbit(values, actions);
    }
  };

  const onSumbit = async (
    values: IUpdateOrganizationData,
    actions: FormikValues
  ) => {
    let modules: updateModuleDTO[] = [];
    values.integrationModules.map((module: any) => {
      let m: updateModuleDTO = {
        companyId: values.id,
        integrationModuleId: module.integrationModuleId,
        active: module.active,
      };
      modules.push(m);
    });
    const updateData: UpdateOrganizationDTO = {
      id: values.id,
      name: values.orgName,
      orgNo: values.orgNumber,
      address: values.orgAddress,
      active: values.orgStatus,
      integrationModules: modules,
    };
    updateCompany(updateData);
  };

  const integrationModuleName = (modules: IntegrationModules[]) => {
    let activeModuleNameList: string[] = [];
    modules?.forEach((e) => {
      integrationModules.map((module) => {
        if (module.id === e.integrationModuleId) {
          let m: DisplayIntegrationModules = {
            name: module.name,
            status: e.active,
          };
          if (m.status) {
            activeModuleNameList.push(m.name);
          }
        }
      });
    });
    return activeModuleNameList;
  };

  const inactiveIntegrationModuleName = (modules: IntegrationModules[]) => {
    let inactiveModuleNameList: string[] = [];
    modules?.forEach((e) => {
      integrationModules.map((module) => {
        if (module.id === e.integrationModuleId) {
          let m: DisplayIntegrationModules = {
            name: module.name,
            status: e.active,
          };
          if (!m.status) {
            inactiveModuleNameList.push(m.name);
          }
        }
      });
    });
    return inactiveModuleNameList;
  };

  const displaySummary = () => {
    if (
      stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber
    ) {
      return (
        <div>
          <ul>Name: {ref.current && ref.current.values.orgName}</ul>
          <ul>Number: {ref.current && ref.current.values.orgNumber}</ul>
          <ul>Address: {ref.current && ref.current.values.orgAddress}</ul>
          <ul>
            Status:{" "}
            {ref.current && ref.current.values.orgStatus
              ? "Active"
              : "Inactive"}
          </ul>
          <ul>
            Active Modules:{" "}
            {ref.current &&
            integrationModuleName(
              ref.current.values.integrationModules
            ).toString()
              ? integrationModuleName(
                  ref.current.values.integrationModules
                ).toString()
              : "N/A"}
          </ul>
          <ul>
            Inactive Modules:{" "}
            {ref.current &&
            inactiveIntegrationModuleName(
              ref.current.values.integrationModules
            ).toString()
              ? inactiveIntegrationModuleName(
                  ref.current.values.integrationModules
                ).toString()
              : "N/A"}
          </ul>
        </div>
      );
    }
  };

  const getIntegrationModules = () => {
    getAllIntegrationModules()
      .then((result) => {
        setIntegrationModules(result);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  };

  const updateCompany = async (organization: UpdateOrganizationDTO) => {
    const res = await updateOrganization(organization);
    if (typeof res !== "number") {
      setSubmitError(true);
      setTimeout(() => {
        setSubmitError(false);
      }, 3000);
    } else {
      setsubmitSuccess(true);
      setTimeout(() => {
        setsubmitSuccess(false);
      }, 3000);
    }
  };

  const errorMessage = () => {
    return <div>Error updating the organization!</div>;
  };

  const successMessage = () => {
    return <div>Successfully updated the organization!</div>;
  };

  const closeModal = () => {
    selectedItemContext.setSelectedItem?.("0");
  };

  const addStoreClickHandler = (resetForm: any, values: any) => {
    let imStateCopy: IntegrationModule[] = [...integrationModules];

    if (selectedIntegrationModules.length === 0) {
      const module = imStateCopy.find(
        (module) => module.id === parseInt(values.integrationModuleId)
      );
      if (module) {
        let newIntegrationModule: IntegrationModuleDTO = {
          name: module.name,
          active: values.integratonModuleStatus,
          integrationModuleId: parseInt(values.integrationModuleId),
        };
        setSelectedIntegrationModules((m) => [...m, newIntegrationModule]);
      }
    }

    imStateCopy.forEach((module) => {
      values.integrationModules.forEach((e: any) => {
        if (module.id === parseInt(values.integrationModuleId)) {
          let newIntegrationModule: IntegrationModuleDTO = {
            name: module.name,
            active: values.integratonModuleStatus,
            integrationModuleId: parseInt(values.integrationModuleId),
          };
          setSelectedIntegrationModules((modules) => {
            if (!modules) {
              return [newIntegrationModule];
            }

            if (
              !modules.some(
                (module) =>
                  module.integrationModuleId ===
                  newIntegrationModule.integrationModuleId
              )
            ) {
              return [...modules, newIntegrationModule];
            }
            return modules;
          });
          values.integrationModules = selectedIntegrationModules;
          resetForm({
            values: {
              ...values,
              integrationModuleId: "",
              integratonModuleStatus: false,
              integrationModules: selectedIntegrationModules,
            },
          });
        }
      });
    });
  };

  const removeIntegrationModuleClickHandler = (integrationModuleId: number) => {
    setSelectedIntegrationModules(
      selectedIntegrationModules.filter(
        (module) => module.integrationModuleId !== integrationModuleId
      )
    );
  };

  useEffect(() => {
    getIntegrationModules();
    getSelectedCompany();

    if (!stepperRef.current) {
      return;
    }
    loadStepper();
  }, [stepperRef, getSelectedCompany]);

  const setStores = (values: IUpdateOrganizationData) => {
    values.integrationModules = selectedIntegrationModules;
  };

  useEffect(() => {
    setUpdatedValues();
  }, [setUpdatedValues]);

  return (
    <div
      className="modal fade"
      id="kt_modal_update_organization"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered mw-900px">
        <div className="modal-content">
          <div className="modal-header">
            <span>
              <h2>Update Organization</h2>
              <div
                className="fw-bold fs-3 text-danger"
                hidden={!submitError ? true : false}
              >
                {submitError && errorMessage()}
              </div>
              <div
                className="fw-bold fs-3 text-success"
                hidden={!submitSuccess ? true : false}
              >
                {submitSuccess && successMessage()}
              </div>
            </span>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              onClick={() => closeModal()}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </div>
          </div>

          <div className="modal-body overlay overlay-block pe-none">
            <div className="modal-body py-lg-10 px-lg-10">
              <div
                ref={stepperRef}
                className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
                id="kt_modal_update_organization_stepper"
              >
                <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
                  <div className="stepper-nav ps-lg-10">
                    <div
                      className="stepper-item current"
                      data-kt-stepper-element="nav"
                    >
                      <div className="stepper-line w-40px"></div>

                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check"></i>
                        <span className="stepper-number">1</span>
                      </div>

                      <div className="stepper-label">
                        <h3 className="stepper-title">Details</h3>

                        <div className="stepper-desc">
                          Enter Organization Details
                        </div>
                      </div>
                    </div>

                    <div className="stepper-item" data-kt-stepper-element="nav">
                      <div className="stepper-line w-40px"></div>

                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check"></i>
                        <span className="stepper-number">2</span>
                      </div>

                      <div className="stepper-label">
                        <h3 className="stepper-title">Module</h3>

                        <div className="stepper-desc">
                          Select Integration Modules
                        </div>
                      </div>
                    </div>

                    <div className="stepper-item" data-kt-stepper-element="nav">
                      <div className="stepper-line w-40px"></div>

                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check"></i>
                        <span className="stepper-number">3</span>
                      </div>

                      <div className="stepper-label">
                        <h3 className="stepper-title">Summary</h3>

                        <div className="stepper-desc">Review and Update</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex-row-fluid py-lg-5 px-lg-15">
                  <Formik
                    innerRef={ref}
                    validationSchema={currentSchema}
                    initialValues={valuesToUpdated}
                    onSubmit={submitStep}
                    enableReinitialize={true}
                  >
                    {({ resetForm, values, setFieldValue }) => (
                      <Form
                        className="form"
                        noValidate
                        id="kt_modal_create_organization_form"
                      >
                        <div
                          className="current"
                          data-kt-stepper-element="content"
                        >
                          <div className="w-100">
                            <div className="fv-row mb-10">
                              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                <span className="required">Name</span>
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7 pe-auto"
                                  data-bs-toggle="tooltip"
                                  title="Enter organization name"
                                ></i>
                              </label>

                              <Field
                                type="text"
                                className="form-control form-control-lg form-control-solid pe-auto"
                                name="orgName"
                                placeholder="Enter organization name"
                              />
                              <div className="text-danger">
                                <ErrorMessage name="orgName" />
                              </div>
                            </div>
                            <div className="fv-row mb-10">
                              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                <span className="required">Number</span>
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7 pe-auto"
                                  data-bs-toggle="tooltip"
                                  title="Enter organization number"
                                ></i>
                              </label>

                              <Field
                                type="text"
                                className="form-control form-control-lg form-control-solid pe-auto"
                                name="orgNumber"
                                placeholder="Enter organization number"
                              />
                              <div className="text-danger">
                                <ErrorMessage name="orgNumber" />
                              </div>
                            </div>
                            <div className="fv-row mb-10">
                              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                <span className="required">Address</span>
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7 pe-auto"
                                  data-bs-toggle="tooltip"
                                  title="Enter organization address"
                                ></i>
                              </label>

                              <Field
                                type="text"
                                className="form-control form-control-lg form-control-solid pe-auto"
                                name="orgAddress"
                                placeholder="Enter organization address"
                              />
                              <div className="text-danger">
                                <ErrorMessage name="orgAddress" />
                              </div>
                            </div>
                            <div className="d-flex flex-stack">
                              <div className="me-5">
                                <label className="fs-6 fw-bold form-label">
                                  Status
                                </label>
                              </div>
                              <label className="form-check form-switch form-check-custom form-check-solid">
                                <Field
                                  name="orgStatus"
                                  className="form-check-input pe-auto"
                                  type="checkbox"
                                />
                                <span className="form-check-label fw-bold text-gray-400" />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div data-kt-stepper-element="content">
                          <div className="w-100">
                            <div className="fv-row">
                              <label className="d-flex align-items-center fs-5 fw-bold mb-4">
                                <span className="required">
                                  Select Integration Module
                                </span>
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Specify Integration Modules"
                                ></i>
                              </label>

                              <div className="d-flex flex-column mb-7 fv-row">
                                <div className="mb-9">
                                  <Field
                                    as="select"
                                    name="integrationModuleId"
                                    className="form-select form-select-solid pe-auto"
                                  >
                                    <option value="" disabled selected>
                                      Select Integration Module
                                    </option>
                                    {integrationModules.map((module) => (
                                      <option key={module.id} value={module.id}>
                                        {module.name}
                                      </option>
                                    ))}
                                  </Field>
                                  <div className="text-danger">
                                    <ErrorMessage name="integrationModuleId" />
                                  </div>
                                </div>
                                <div className="d-flex flex-stack mb-6">
                                  <div className="me-5">
                                    <label className="fs-6 fw-bold form-label">
                                      Status
                                    </label>
                                  </div>
                                  <label className="form-check form-switch form-check-custom form-check-solid">
                                    <Field
                                      name="integratonModuleStatus"
                                      className="form-check-input pe-auto"
                                      type="checkbox"
                                    />
                                    <span className="form-check-label fw-bold text-gray-400" />
                                  </label>
                                </div>
                                <div className="d-flex flex-stack align-items-center flex-row-reverse mt-sm-4 mb-8">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary me-3 pe-auto"
                                    onClick={() =>
                                      addStoreClickHandler(resetForm, values)
                                    }
                                  >
                                    Add
                                  </button>
                                </div>
                                <div className="text-danger pe-none">
                                  <ErrorMessage name="integrationModules" />
                                </div>
                                <div className="fv-row h-250px w-450px overflow-auto">
                                  <CompanyIntegrationModulesTable
                                    className="mb-5 mb-xl-8 pe-auto"
                                    integrationModuleList={
                                      selectedIntegrationModules
                                    }
                                    removeIntegrationModule={
                                      removeIntegrationModuleClickHandler
                                    }
                                    values={values}
                                    setIntegrationModuleValue={setStores(
                                      values
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="text-danger">
                              <ErrorMessage name="framework" />
                            </div>
                          </div>
                        </div>

                        <div data-kt-stepper-element="content">
                          <div className="w-100 text-left">
                            <h1 className="fw-bolder text-dark mb-3">
                              Organization Summary
                            </h1>
                            <div className="text-muted text-left fw-bold fs-3">
                              {displaySummary()}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex flex-stack pt-10">
                          <div className="me-2">
                            <button
                              onClick={prevStep}
                              type="button"
                              className="btn btn-lg btn-light-primary me-3 pe-auto"
                              data-kt-stepper-action="previous"
                            >
                              <KTSVG
                                path="/media/icons/duotune/arrows/arr063.svg"
                                className="svg-icon-4 me-1"
                              />
                              Back
                            </button>
                          </div>

                          <div>
                            <button
                              type="submit"
                              className="btn btn-lg btn-primary me-3 pe-auto"
                            >
                              <span className="indicator-label">
                                {stepper.current?.currentStepIndex !==
                                  stepper.current?.totatStepsNumber! - 1 &&
                                  "Continue"}
                                {stepper.current?.currentStepIndex ===
                                  stepper.current?.totatStepsNumber! - 1 &&
                                  "Update"}
                                <KTSVG
                                  path="/media/icons/duotune/arrows/arr064.svg"
                                  className="svg-icon-3 ms-2 me-0"
                                />
                              </span>
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div
              className="overlay-layer bg-dark bg-opacity-5"
              hidden={loading}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { UpdateOrganization };
