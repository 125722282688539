export type OrganizationType = {
  totalRecords: number;
  companies: CompanyType[];
};

export type CompanyType = {
  id: number;
  name: string;
  orgNo: string;
  address: string;
  active: boolean;
  integrationModules: IntegrationModule[];
  keyConfigurations: KeyConfiguration[];
};

export type CompanyStore = {
  id: number;
  name: string;
};

export type IntegrationModule = {
  id: number;
  name: string;
  companyId: number;
  integrationModuleId: number;
  active: boolean;
};

export type KeyConfiguration = {
  id: number;
  companyId: number;
  key: string;
  value: string;
};

export type ViewKeyConfiguration = {
  id: number;
  companyId: number;
  key: string;
  value: Dimensions;
};

export type Dimensions = {
  dimensions: Dimension[];
};

export type Dimension = {
  AccountNumber: string;
  Dimension: string;
  Dimension2: string;
  Dimension3: string;
};

export interface IConfigDetails {
  shopifyToken: string;
  shopifyGraphQLEndpoint: string;
  xledgerToken: string;
  vismaEACallbackURL: string;
  vismaEAClientID: string;
  vismaEAClientSecret: string;
  vismaEARefreshToken: string;
  vismaEAClientScopes: string;
  vismaEEndpoint: string;
  vismaEUsername: string;
  vismaEPassword: string;
  vismaECompany: string;
  vismaEVoucherType: string;
  vismaEUpdateVoucher: string;
  vismaEDimensions: string;
  vismaBusinessEndpoint: string;
  vismaBusinessUsername: string;
  vismaBusinessPassword: string;
  marketingCloudEndpoint: string;
  marketingCloudUsername: string;
  marketingCloudPassword: string;
  marketingCloudIsTestModeEnabled: string;
  matricsEndpoint: string;
  matricsApiUser: string;
  matricsApiKey: string;
  matricsRegionId: string;
  matricsBatchSize: string;
  matricsBrandTextFieldNo: string;
  matricsTypeTextFieldNo: string;
}

export const configDetailsInitValues: IConfigDetails = {
  shopifyToken: "",
  shopifyGraphQLEndpoint: "",
  xledgerToken: "",
  vismaEACallbackURL: "",
  vismaEAClientID: "",
  vismaEAClientSecret: "",
  vismaEARefreshToken: "",
  vismaEAClientScopes: "",
  vismaEEndpoint: "",
  vismaEUsername: "",
  vismaEPassword: "",
  vismaECompany: "",
  vismaEVoucherType: "",
  vismaEUpdateVoucher: "",
  vismaEDimensions: "",
  vismaBusinessEndpoint: "",
  vismaBusinessUsername: "",
  vismaBusinessPassword: "",
  marketingCloudEndpoint: "",
  marketingCloudUsername: "",
  marketingCloudPassword: "",
  marketingCloudIsTestModeEnabled: "",
  matricsEndpoint: "",
  matricsApiUser: "",
  matricsApiKey: "",
  matricsRegionId: "",
  matricsBatchSize: "",
  matricsBrandTextFieldNo: "",
  matricsTypeTextFieldNo: "",
};
