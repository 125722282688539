import {
  PlatformTaskHistoryParamTypes,
  PlatformTaskHistoryParamTypesFilters,
} from "./types/PlatformTaskHistoryParamTypes";
import { getUrl, request } from "../util/Request";

export async function getAllPlatformTaskHistory(
  data: PlatformTaskHistoryParamTypes
) {
  var response = null;
  if (data.orgNo !== null) {
    response = await request(
      `/api/TaskHistory/GetAllTaskHistory?pageNumber=${data.pageNumber}&dataLimit=${data.dataLimit}&orgNo=${data.orgNo}`
    );
  } else {
    response = await request(
      `/api/TaskHistory/GetAllTaskHistory?pageNumber=${data.pageNumber}&dataLimit=${data.dataLimit}`
    );
  }

  return await response.json();
}

export async function getAllPlatformTaskHistoryFilters(
  data: PlatformTaskHistoryParamTypesFilters
) {
  var response = null;
  response = await request(getUrl('/api/TaskHistory/GetAllTaskHistoryFilters', data)); 
  return await response.json();
}
