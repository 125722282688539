import { FC, useEffect, useState } from "react";
import { KTSVG } from "../../../helpers";
import { PlatformTaskModalType } from "../../../../app/pages/platformTaskHistory/PlatformTaskHistoryType";
import '../styles/ExecutionHistoryDetail.scss'

interface HistoryProps {
  executionHistoryData: PlatformTaskModalType;
}

const ExecutionHistoryModal: FC<HistoryProps> = ({ executionHistoryData }) => {

  const setHeader = (statusId: number) => {
    if (statusId === 1 || statusId === 2) {
      return "Data";
    } else {
      return "Error";
    }
  };

  const setTaskStatus = (statusId: number) => {
    if (statusId === 1) {
      return (
        <span className="badge badge-light-primary fs-5 fw-bold">Pending</span>
      );
    } else if (statusId === 2) {
      return (
        <span className="badge badge-light-success fs-5 fw-bold">Success</span>
      );
    } else if (statusId === 3) {
      return (
        <span className="badge badge-light-danger fs-5 fw-bold">Failed</span>
      );
    } else if (statusId === 4) {
      return (
        <span className="badge badge-light-warning fs-5 fw-bold">Empty</span>
      );
    } else {
      return (
        <span className="text-muted fw-bold text-muted d-block fs-5">
          Undefined
        </span>
      );
    }
  };

  const getModalHeaderClass = (statusId: number) => {
    if (statusId === 1) {
      return (
        "modal-header badge-light-primary"
      );
    } else if (statusId === 2) {
      return (
        "modal-header badge-light-success"
      );
    } else if (statusId === 3) {
      return (
        "modal-header badge-light-danger"
      );
    } else {
      return (
        "modal-header"
      );
    }
  };

  function trimByChar(string: string, character: string) {
    const arr = Array.from(string);
    const first = arr.findIndex(char => char !== character);
    const last = arr.reverse().findIndex(char => char !== character);
    return (first === -1 && last === -1) ? '' : string.substring(first, string.length - last);
  }

  const setResult = (result: string) => {
    if (result !== undefined) {
      let pretty = result;
      if (result != null) {
        result = trimByChar(result, "\"");
        try {
          const obj = JSON.parse(result.replace(/\\/g, ''));
          if(obj === null){
            pretty = ""
          }else{
            pretty = JSON.stringify(obj, (key, value) => (value === null ? undefined : value), 2);
          }          
        } catch (e) {
          pretty = result;
        }
      } else {
        pretty = "";
      }
      return (
        <div>
          <textarea
            className="form-control h-300px w-800px"
            readOnly
            placeholder=""
            id="floatingTextarea2"
            value={pretty}
          />
        </div>
      );
    } else {
      return "test";
    }
  };

  const setParameters = (result: string) => {
    if (result !== undefined) {
      let pretty = result;
      if (result != null) {
        result = trimByChar(result, "\"");
        try {
          const obj = JSON.parse(result.replace(/\\/g, ''));

          let para = [];
          
          for (let [key, value] of Object.entries(obj)) {
            para.push( key + ' : ' + value);          
          }
          pretty = para.join('\n');
        } catch (e) {
          pretty = result;
        }
      } else {
        pretty = "";
      }
      return (
        <div>
          <textarea
            className="form-control h-80px w-300px"
            readOnly
            placeholder="NA"
            id="floatingTextarea3"
            value={pretty}

          />
        </div>
      );
    } else {
      return "test";
    }
  };

  return (
    <>
      <div className="modal fade" tabIndex={-1} id="kt_modal_history_details">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className={getModalHeaderClass(executionHistoryData?.taskStatus as number)}>
              <label className="col-lg-3 fs-5 fw-bold text-muted">
                <span className="fw-bolder fs-5 text-dark">
                  {setTaskStatus(executionHistoryData?.taskStatus as number)}
                </span>
              </label>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <div className="row gx-xs-0 platformTaskHistoryOverview">
                <div className="col-md-6">
                  <div className="card card-xxl-stretch">
                    <div className="card-header min-h-30px">
                      <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Task Details</h3>
                      </div>
                    </div>

                    <div className="card-body py-3">
                      <div className="row py-2">
                        <label className="col-lg-4 fs-5 fw-bolder text-muted">
                          Organization
                        </label>

                        <div className="col-lg-8">
                          <span className="fw-bolder fs-5 text-dark">
                            {executionHistoryData?.orgName}
                          </span>
                        </div>
                      </div>

                      <div className="row py-2">
                        <label className="col-lg-4 fs-5 fw-bolder text-muted">
                          Event
                        </label>

                        <div className="col-lg-8">
                          <span className="fw-bolder fs-5 text-dark">
                            {executionHistoryData?.event}
                          </span>
                        </div>
                      </div>

                      <div className="row py-2">
                        <label className="col-lg-4 fs-5 fw-bolder text-muted">
                          Trigger Type
                        </label>

                        <div className="col-lg-8 fv-row">
                          <span className="fw-bolder fs-5 text-dark">
                            {executionHistoryData && executionHistoryData?.taskName}
                          </span>
                        </div>
                      </div>

                      <div className="row py-2">
                        <label className="col-lg-4 fs-5 fw-bolder text-muted">
                          Source
                        </label>

                        <div className="col-lg-8 fv-row">
                          <span className="fw-bolder fs-5 text-dark">
                            {executionHistoryData?.sourceName}
                          </span>
                        </div>
                      </div>

                      <div className="row py-2">
                        <label className="col-lg-4 fs-5 fw-bolder text-muted">
                          Destination
                        </label>

                        <div className="col-lg-8 fv-row">
                          <span className="fw-bolder fs-5 text-dark">
                            {executionHistoryData?.destinationName}
                          </span>
                        </div>
                      </div>

                      <div className="row py-2">
                        <label className="col-lg-4 fs-5 fw-bolder text-muted">
                          Integration Module
                        </label>

                        <div className="col-lg-8 fv-row">
                          <span className="fw-bolder fs-5 text-dark">
                            {executionHistoryData?.integrationModule}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card card-xxl-stretch">
                    <div className="card-header min-h-30px">
                      <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Execution Details</h3>
                      </div>
                    </div>

                    <div className="card-body py-3">
                      
                      <div className="row py-2">
                        <label className="col-lg-4 fs-5 fw-bolder text-muted">
                          Trigger
                        </label>

                        <div className="col-lg-8">
                          <span className="fw-bolder fs-5 text-dark">
                            {executionHistoryData?.isManual ? "Manual": "Auto"}
                          </span>
                        </div>
                      </div>

                      <div className="row py-2">
                        <label className="col-lg-4 fs-5 fw-bolder text-muted">
                          Executed At
                        </label>

                        <div className="col-lg-8">
                          <span className="fw-bolder fs-5 text-dark">
                            {executionHistoryData?.syncedDate}
                          </span>
                        </div>
                      </div>

                      <div className="row py-2">
                        <label className="col-lg-4 fs-5 fw-bolder text-muted">
                          Retry Count
                        </label>

                        <div className="col-lg-8">
                          <span className="fw-bolder fs-5 text-dark">
                            {executionHistoryData?.retryCount}
                          </span>
                        </div>
                      </div>

                      <div className="row py-2">
                        <label className="col-lg-4 fs-5 fw-bolder text-muted">
                          Store Code
                        </label>

                        <div className="col-lg-8">
                          <span className="fw-bolder fs-5 text-dark">
                            {executionHistoryData?.storeCode}
                          </span>
                        </div>
                      </div>

                      <div className="row py-2">
                        <label className="col-lg-4 fs-5 fw-bolder text-muted">
                          Parameters
                        </label>

                        <div className="col-lg-8">
                          <span className="fw-bolder fs-5 text-dark">
                            {setParameters(executionHistoryData?.parameters!)}
                          </span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-xs-6">
                  <div className="card">
                    <div className="card-header cursor-pointer">
                      <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Result</h3>
                      </div>
                    </div>
                    <div className="card-body p-9">                    
                      <div className="row m-0">
                        <div className="col-lg-11">
                          <label className="fs-5 fw-bold text-muted">
                            {setHeader(executionHistoryData?.taskStatus as number)}
                          </label>
                          <span className="fw-bolder fs-5 text-dark">
                            {setResult(executionHistoryData?.destinationData!)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>           
          </div>
        </div>
      </div>
    </>
  );
}

export default ExecutionHistoryModal;
