import {
  eaAuthTokenBodyDTO,
  KeyConfigurationDTO,
} from "./models/KeyConfigurationModel";
import { request } from "../util/Request";
import { KeyConfiguration } from "../pages/company/CompanyType";

export async function getKeyConfigurationByCompanyId(id: number) {
  const response = await request(`/api/KeyConfiguration/GetById?id=${id}`);
  console.log(response);
  return await response.json();
}

export async function createKeyConfigurations(data: KeyConfigurationDTO) {
  const response = await request(`/api/KeyConfiguration/Create`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ keyConfiguration: data }),
  });
  return await response.json();
}

export async function createKeyConfiguration(data: KeyConfiguration) {
  const response = await request(`/api/KeyConfiguration/CreateConfig`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ keyConfiguration: data }),
  });
  return await response.json();
}

export async function updateKeyConfigurations(data: KeyConfigurationDTO) {
  const response = await request(`/api/KeyConfiguration/Update`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ keyConfiguration: data }),
  });
  return await response.json();
}

export async function getEaAuthToken(data: eaAuthTokenBodyDTO) {
  const response = await request(`/api/EAccoutingAuth/Token`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ eATokenData: data }),
  });
  return await response.json();
}
