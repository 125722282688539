import { request } from "../util/Request";

export async function getAllDestionations() {
  const response = await request("/api/Destination/GetAll");
  console.log(response);
  return await response.json();
}

export async function getAllDestionationNames() {
  const response = await request("/api/Destination/GetAllNames");
  console.log(response);
  return await response.json();
}
