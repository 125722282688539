/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { KTSVG } from "../../../helpers";
import { IntegrationModuleDTO } from "../../../../app/services/models/IntegrationModuleModel";

type Props = {
  className: string;
  integrationModuleList: IntegrationModuleDTO[];
  removeIntegrationModule: any;
  values: any;
  setIntegrationModuleValue: any;
};

const CompanyIntegrationModulesTable: React.FC<Props> = ({
  className,
  integrationModuleList,
  removeIntegrationModule: removeStore,
  values,
  setIntegrationModuleValue,
}) => {
  useEffect(() => {
    console.log(integrationModuleList);
  }, []);

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div
        className="card-body py-3"
        style={{ paddingRight: "75px", marginLeft: "-25px" }}
      >
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted pe-none">
                <th className="min-w-150px">Name</th>
                <th className="min-w-150px">Status</th>
                <th className="mw-100px text-end">Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {integrationModuleList &&
                integrationModuleList.map((store) => (
                  <tr key={store.integrationModuleId}>
                    <td className="fw-bolder ">{store.name}</td>
                    <td>
                      {store.active ? (
                        <span className="badge badge-light-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge badge-light-danger">
                          Inactive
                        </span>
                      )}
                    </td>
                    <td className="text-end pe-auto">
                      <a
                        href="#"
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                        onClick={() => removeStore(store.integrationModuleId)}
                        onChange={() => setIntegrationModuleValue(values)}
                      >
                        <KTSVG
                          path="/media/icons/duotune/general/gen027.svg"
                          className="svg-icon-3"
                        />
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { CompanyIntegrationModulesTable };
